import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const MembershipReminderLogsApi = (ID, data) => {
    return API.get(`${URL.GET_ALL_MEMBERSHIP_REMINDER_LOG}/${ID}`, data)
}
export const MembershipReminderLogsUserListApi = (ID) => {
    return API.get(`${URL.GET_ALL_MEMBERSHIP_REMINDER_USER_LIST}/${ID}`)
}
export const MembershipReminderListLogsApi = (data) =>
    API.get(`${URL.GET_ALL_MEMBERSHIP_REMINDER_LIST_LOG}`, data)

//

export const MembershipReminderTemplateListApi = (data) =>
    API.get(`${URL.MEMBERSHIP_REMINDER_TEMPLATE_LIST}`, data)
export const MembershipReminderTemplateCreateApi = (data) =>
    API.post(`${URL.MEMBERSHIP_REMINDER_TEMPLATE_CREATE}`, data)
export const MembershipReminderTemplateUpdateApi = (ID, data) =>
    API.patch(`${URL.MEMBERSHIP_REMINDER_TEMPLATE_UPDATE}/${ID}`, data)
export const MembershipReminderTemplateDeleteApi = (ID) =>
    API.del(`${URL.MEMBERSHIP_REMINDER_TEMPLATE_DELETE}/${ID}`)

export const MembershipReminderSetupListApi = (data) =>
    API.get(`${URL.MEMBERSHIP_REMINDER_SETUP_LIST}`, data)
export const MembershipReminderSetupCreateApi = (data) =>
    API.post(`${URL.MEMBERSHIP_REMINDER_SETUP_CREATE}`, data)
export const MembershipReminderSetupUpdateApi = (ID, data) =>
    API.patch(`${URL.MEMBERSHIP_REMINDER_SETUP_UPDATE}/${ID}`, data)
export const MembershipReminderSetupDeleteApi = (ID) =>
    API.del(`${URL.MEMBERSHIP_REMINDER_SETUP_DELETE}/${ID}`)
export const MembershipReminderSetupOneApi = (ID) =>
    API.get(`${URL.MEMBERSHIP_REMINDER_SETUP_ONE}/${ID}`)
