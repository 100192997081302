import { createSlice } from '@reduxjs/toolkit'
import { LetterTemplateCreate, LetterTemplateList, LetterTemplateUpdate } from './thunk'
import _ from 'lodash'

export const LetterTemplateForm = {
    document_file:'',
    membership_letter_template_id:'',
    info_1: '',
    logo_file: '',
    sign_data: '',
    logo: ''
}
export const initialState = {
    form: LetterTemplateForm,
    loading: true,
    pageLoading: true
}

const letterTemplate = createSlice({
    name: 'LetterTemplate',
    initialState,
    reducers: {
        setPageLoader: (state) => {
            state.pageLoading = !state.pageLoading
        }
    },
    extraReducers: (builder) => {
        // Listing
        builder.addCase(LetterTemplateList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(LetterTemplateList.fulfilled, (state, action) => {
            const { data } = action.payload
            const formData = _.first(data)

            const finalData = _.pick(formData, Object.keys(LetterTemplateForm))

            state.form = formData ? finalData : LetterTemplateForm
            state.loading = false
        })
        builder.addCase(LetterTemplateList.rejected, (state) => {
            state.loading = false
        })

        // Create
        builder.addCase(LetterTemplateCreate.pending, (state) => {
            state.loading = false
            // state.pageLoading = true
        })
        builder.addCase(LetterTemplateCreate.fulfilled, (state) => {
            state.pageLoading = !state.pageLoading
        })
        builder.addCase(LetterTemplateCreate.rejected, (state) => {
            state.loading = false
        })

        // Update
        builder.addCase(LetterTemplateUpdate.pending, (state) => {
            state.loading = false
        })
        builder.addCase(LetterTemplateUpdate.fulfilled, (state) => {
            state.pageLoading = !state.pageLoading
        })
        builder.addCase(LetterTemplateUpdate.rejected, (state) => {
            state.loading = false
        })
    }
})

export const { setPageLoader } = letterTemplate.actions
export default letterTemplate.reducer
