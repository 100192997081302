import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'
//

export const getAllBreedCategoryApi = (data) => API.get(`${URL.GET_ALL_BREED_CATEGORY}`, data)
export const saveClubTypeApi = (data) => API.post(`${URL.SAVE_CLUB_TYPE_URL}`, data)
export const getClubTypeApi = (data) => API.get(`${URL.GET_CLUB_TYPE_URL}`, data)

export const getAllBreedApi = (data) => API.get(`${URL.GET_ALL_BREED}`, data)

export const createClubApi = (data) => API.post(`${URL.SETTING_CREATE_CLUB}`, data)

export const getOneClubApi = (data) => API.get(`${URL.SETTING_GET_ALL_CLUB}`, data)

export const deleteClubApi = (ID) => API.del(`${URL.DELETE_SWEEPSTAKE_POINT_URL}/${ID}`)
