import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const MemberShipPlanListApi = (data) => API.get(`${URL.CLUB_PLANS_LIST}`, data)

export const AgeClassificationListApi = (data) => API.get(`${URL.AGE_CLASSIFICATION_LIST_URL}`, data)

export const AgeClassificationByIdApi = (ID) => API.get(`${URL.AGE_CLASSIFICATION_ONE_URL}/${ID}`)

export const AgeClassificationCreateApi = (data) => API.post(`${URL.AGE_CLASSIFICATION_CREATE_URL}`,data)

export const AgeClassificationUpdateApi = (ID,data) => API.put(`${URL.AGE_CLASSIFICATION_UPDATE_URL}/${ID}`,data)

export const AgeClassificationDeleteApi = (ID) =>API.del(`${URL.AGE_CLASSIFICATION_DELETE_URL}/${ID}`)