import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    MemberEmailAlreadyExitApi,
    clubLoadApi,
    memberEmailResentApi,
    userChangePasswordApi,
    userLoginApi,
    userLogoutApi,
    userProfileUpdateApi,
    userRefreshTokenApi
} from './Auth.services'

export const userLogin = createAsyncThunk('user/login', async (data, { rejectWithValue }) => {
    try {
        const res = await userLoginApi(data)

        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

//

export const clubLoad = createAsyncThunk('club/load', async (data, { rejectWithValue }) => {
    try {
        const res = await clubLoadApi()

        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

//
export const userLogout = createAsyncThunk('user/logout', async (data, { rejectWithValue }) => {
    try {
        const res = await userLogoutApi(data)
        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

//
export const userProfileUpdate = createAsyncThunk(
    'user/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await userProfileUpdateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const userChangePassword = createAsyncThunk(
    'user/changePassword',
    async (data, { rejectWithValue }) => {
        try {
            const res = await userChangePasswordApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const userRefreshToken = createAsyncThunk(
    'user/forgot-password',
    async (data, { rejectWithValue }) => {
        try {
            const res = await userRefreshTokenApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const ClubProfile = createAsyncThunk('club/profile', async (data, { rejectWithValue }) => {
    try {
        const res = await clubLoadApi()

        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

export const MemberEmailAlreadyExit = createAsyncThunk(
    'Member/alreadyEmail',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MemberEmailAlreadyExitApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const memberEmailResent = createAsyncThunk(
    'member/resentEmail',
    async (data, { rejectWithValue }) => {
        try {
            const res = await memberEmailResentApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
