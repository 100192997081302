import moment from 'moment'

export function getDateWithFormat(date) {
    let date1 = date
    if (date) {
        date1 = date.replace('T', ' ')
        date1 = date1.replace('Z', '')
    }

    return date1
}

export const getConvertTime = (date, time) => {
    const cDate = moment(date).format('YYYY-MM-DD')

    const ctime = moment(time).format('HH:mm:ss')

    return `${cDate} ${ctime}`
}
