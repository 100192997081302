/* eslint-disable react/jsx-no-useless-fragment */
import PropTypes from 'prop-types'

import { Route, Routes } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'

// Import Routes all
import { PublicRoute, clubRoutes, membershipRoutes, sanctionRoutes } from './routes/allRoutes'

// Import all middleware
import AuthGuard from './routes/middleware/AuthGuard'

// layouts Format
import VerticalLayout from './components/VerticalLayout'
import ClerkLayout from './components/VerticalLayout/ClerkLayout'
import NonAuthLayout from './components/NonAuthLayout'

//
import { withOneTabEnforcer } from 'react-one-tab-enforcer'
import DifferentWarningComponent from './pages/DifferentWarningComponent'

// Import scss
import './assets/scss/theme.scss'
import { Suspense, lazy, useEffect } from 'react'
import { Loader } from './Atoms'
import { clubLoad } from './store/actions'
import StripAccess from './routes/middleware/StripAccess'

const Token = lazy(() => import('src/pages/Token/Token'))

const App = (props) => {
    const dispatch = useDispatch()
    // const router = ClerkRoutes()
    const { loading, admin, isAuth } = useSelector((state) => state.Login)

    useEffect(() => {
        dispatch(clubLoad())
    }, [])
    function getLayout() {
        let layoutCls = VerticalLayout

        switch (props.layout.layoutType) {
            default:
                layoutCls = VerticalLayout
                break
        }
        return layoutCls
    }

    const Layout = getLayout()
    // if (loading) {
    //     return (
    //         <div
    //             style={{ height: '100vh' }}
    //             className='d-flex  justify-content-center align-items-center'
    //         >
    //             <Loader color='primary' />
    //         </div>
    //     )
    // }

    return (
        <Routes>
            <Route
                path='/token'
                element={
                    <Suspense
                        fallback={
                            <div
                                style={{ height: '100vh' }}
                                className='d-flex  justify-content-center align-items-center'
                            >
                                <Loader color='primary' />
                            </div>
                        }
                    >
                        <Token />
                    </Suspense>
                }
                isAuthProtected={false}
            />

            {/*  */}
            {PublicRoute.map((route, index) => (
                <Route
                    path={route.path}
                    element={
                        <Suspense
                            fallback={
                                <div
                                    style={{ height: '100vh' }}
                                    className='d-flex  justify-content-center align-items-center'
                                >
                                    <Loader color='primary' />
                                </div>
                            }
                        >
                            <NonAuthLayout>{route.component}</NonAuthLayout>
                        </Suspense>
                    }
                    key={index}
                    isAuthProtected={false}
                />
            ))}

            {!loading && isAuth && admin?.role === 'club'
                ? clubRoutes.map((route, index) => (
                      <Route
                          path={route.path}
                          element={
                              <AuthGuard>
                                  <Layout>
                                      <Suspense
                                          fallback={
                                              <div
                                                  style={{ height: '100vh' }}
                                                  className='d-flex  justify-content-center align-items-center'
                                              >
                                                  <Loader color='primary' />
                                              </div>
                                          }
                                      >
                                          {route.component}
                                      </Suspense>
                                  </Layout>
                              </AuthGuard>
                          }
                          key={index}
                          isAuthProtected
                          exact
                      />
                  ))
                : null}

            {!loading && isAuth && admin?.role === 'club'
                ? membershipRoutes.map((route, index) => (
                      <Route
                          path={route.path}
                          element={
                              <AuthGuard>
                                  <StripAccess>
                                      <Layout>
                                          <Suspense
                                              fallback={
                                                  <div
                                                      style={{ height: '100vh' }}
                                                      className='d-flex  justify-content-center align-items-center'
                                                  >
                                                      <Loader color='primary' />
                                                  </div>
                                              }
                                          >
                                              {route.component}
                                          </Suspense>
                                      </Layout>
                                  </StripAccess>
                              </AuthGuard>
                          }
                          key={index}
                          isAuthProtected
                          exact
                      />
                  ))
                : null}
            {!loading && isAuth && admin?.role === 'club'
                ? sanctionRoutes.map((route, index) => (
                      <Route
                          path={route.path}
                          element={
                              <AuthGuard>
                                  <StripAccess>
                                      <Layout>
                                          <Suspense
                                              fallback={
                                                  <div
                                                      style={{ height: '100vh' }}
                                                      className='d-flex  justify-content-center align-items-center'
                                                  >
                                                      <Loader color='primary' />
                                                  </div>
                                              }
                                          >
                                              {route.component}
                                          </Suspense>
                                      </Layout>
                                  </StripAccess>
                              </AuthGuard>
                          }
                          key={index}
                          isAuthProtected
                          exact
                      />
                  ))
                : null}
            {!loading && isAuth && admin?.role === 'club_clerk'
                ? clubRoutes.map((route, index) => (
                      <Route
                          path={route.path}
                          element={
                              <AuthGuard>
                                  <ClerkLayout>
                                      <Suspense
                                          fallback={
                                              <div
                                                  style={{ height: '100vh' }}
                                                  className='d-flex  justify-content-center align-items-center'
                                              >
                                                  <Loader color='primary' />
                                              </div>
                                          }
                                      >
                                          {route.component}
                                      </Suspense>
                                  </ClerkLayout>
                              </AuthGuard>
                          }
                          key={index}
                          isAuthProtected
                          exact
                      />
                  ))
                : null}

            {!loading && isAuth && admin?.role === 'club_clerk'
                ? membershipRoutes.map((route, index) => (
                      <Route
                          path={route.path}
                          element={
                              <AuthGuard>
                                  <ClerkLayout>
                                      <Suspense
                                          fallback={
                                              <div
                                                  style={{ height: '100vh' }}
                                                  className='d-flex  justify-content-center align-items-center'
                                              >
                                                  <Loader color='primary' />
                                              </div>
                                          }
                                      >
                                          {route.component}
                                      </Suspense>
                                  </ClerkLayout>
                              </AuthGuard>
                          }
                          key={index}
                          isAuthProtected
                          exact
                      />
                  ))
                : null}
            {!loading && isAuth && admin?.role === 'club_clerk'
                ? sanctionRoutes.map((route, index) => (
                      <Route
                          path={route.path}
                          element={
                              <AuthGuard>
                                  <ClerkLayout>
                                      <Suspense
                                          fallback={
                                              <div
                                                  style={{ height: '100vh' }}
                                                  className='d-flex  justify-content-center align-items-center'
                                              >
                                                  <Loader color='primary' />
                                              </div>
                                          }
                                      >
                                          {route.component}
                                      </Suspense>
                                  </ClerkLayout>
                              </AuthGuard>
                          }
                          key={index}
                          isAuthProtected
                          exact
                      />
                  ))
                : null}
        </Routes>
    )
}

App.propTypes = {
    layout: PropTypes.any
}

const mapStateToProps = (state) => ({
    layout: state.Layout
})

export default withOneTabEnforcer({
    appName: 'Admin-Club-Panel-Club-software',
    localStorageResetInterval: 10 * 1000,
    localStorageTimeout: 10 * 1000,
    OnlyOneTabComponent: DifferentWarningComponent
})(connect(mapStateToProps, null)(App))
