/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit'
import { getAllBreed, getClubType, getOneClub, getOneClubForShowSetup } from './thunk'
import _ from 'lodash'

export const Form = {
    setting_club_id: '',
    breed_list: []
}
export const ClubTypeForm = {
    setting_club_type_id: '',
    club_type: ''
}

export const initialState = {
    form: Form,
    ClubTypeForm,
    breedCategories: [],
    breedCategoryLoading: true,
    breeds: [],
    breedLoading: true,
    loading: true
}

const Setting = createSlice({
    name: '@Setting',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllBreed.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAllBreed.fulfilled, (state, action) => {
            const { payload } = action

            state.form.breed_list = _.map(
                _.groupBy(
                    _.filter(payload?.data, (o) => o.breed_category_abbre !== 'commercial') || [],
                    'breed_category_abbre'
                ),

                (breed, breed_category_abbre) => {
                    const findCatData = breed.find(
                        (b) => b.breed_category_abbre === breed_category_abbre
                    )
                    return {
                        breed_category_abbre,
                        breed_category_id: findCatData.breed_category_id,
                        category_name: findCatData.category_name,
                        isSelected: false,
                        breed: _.map(breed, (b) => {
                            return _.extend({}, b, { isSelected: false })
                        })
                    }
                }
            )

            state.loading = false

            // }
        })
        builder.addCase(getAllBreed.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(getClubType.pending, (state) => {
            state.loading = false
        })
        builder.addCase(getClubType.fulfilled, (state, action) => {
            const { payload } = action
            const first = _.first(payload?.data)
            state.ClubTypeForm = _.pick(first, Object.keys(ClubTypeForm))
            state.loading = false
        })
        builder.addCase(getClubType.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(getOneClub.pending, (state) => {
            state.loading = false
        })
        builder.addCase(getOneClub.fulfilled, (state, action) => {
            const { payload } = action
            const first = _.first(payload)

            const finalData = _.map(state.form.breed_list, (b) => {
                const breedCat = _.find(
                    payload,
                    (_cat) => _cat.breed_category === b.breed_category_abbre
                )
                if (breedCat) {
                    return {
                        ...b,
                        setting_club_id: breedCat.setting_club_id,
                        breed_category: breedCat.breed_category,
                        club_type: breedCat.club_type,
                        club_id: breedCat.club_id,
                        is_deactivated: breedCat.is_deactivated,
                        isSelected: true,
                        breed: _.map(b.breed, (_breed) => {
                            const findBreed = _.find(
                                breedCat?.breed_list || [],
                                (_b) => _b.breed_id === _breed.breed_id
                            )
                            if (findBreed) {
                                return {
                                    ..._breed,
                                    ...findBreed,
                                    isSelected: true
                                }
                            }
                            return { ..._breed }
                        })
                    }
                }

                return { ...b }
            })
            state.form.setting_club_id = first?.setting_club_id || ''
            state.form.breed_list = finalData
            state.loading = false
        })
        builder.addCase(getOneClub.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(getOneClubForShowSetup.pending, (state) => {
            state.loading = false
        })
        builder.addCase(getOneClubForShowSetup.fulfilled, (state, action) => {
            const { payload } = action
            const first = _.first(payload)

            // const finalData = _.map(state.form.breed_list, (b) => {
            //     const breedCat = _.find(
            //         payload,
            //         (_cat) => _cat.breed_category === b.breed_category_abbre
            //     )
            //     if (breedCat) {
            //         return {
            //             ...b,
            //             setting_club_id: breedCat.setting_club_id,
            //             breed_category: breedCat.breed_category,
            //             club_type: breedCat.club_type,
            //             club_id: breedCat.club_id,
            //             is_deactivated: breedCat.is_deactivated,
            //             isSelected: true,
            //             breed: _.map(b.breed, (_breed) => {
            //                 const findBreed = _.find(
            //                     breedCat?.breed_list || [],
            //                     (_b) => _b.breed_id === _breed.breed_id
            //                 )
            //                 if (findBreed) {
            //                     return {
            //                         ..._breed,
            //                         ...findBreed,
            //                         isSelected: true
            //                     }
            //                 }
            //                 return { ..._breed }
            //             })
            //         }
            //     }

            //     return { ...b }
            // })
            state.form.setting_club_id = first?.setting_club_id || ''
            state.form.breed_list = payload
            state.loading = false
        })
        builder.addCase(getOneClubForShowSetup.rejected, (state) => {
            state.loading = false
        })
    }
})

export default Setting.reducer
