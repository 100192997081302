import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'
//

export const contestDetailForNationalApi = (data) =>
    API.post(`${URL.GET_ALL_BREED_CONTROL_SHEET}`, data)
export const ContestBreedDetailApi = (data) => API.get(`${URL.GET_CONTEST_BREED_DETAIL}`, data)

export const saveContestApi = (data) => API.post(`${URL.SAVE_CONTEST_URL}`, data)

export const getAllContestApi = (data) => API.get(`${URL.GET_ALL_CONTEST_URL}`, data)

export const deleteContestApi = (ID) => API.del(`${URL.DELETE_CONTEST_URL}/${ID}`)

export const getOverallReportListApi = (data) => API.get(`${URL.GET_OVERALL_REPORT_URL}`, data)
export const SweepstakePlacingListApi = (type, data) =>
    API.get(`${URL.GET_ALL_SWEEPSTAKE_PACING_FOR_CONTROL_SHEET_URL}`, data)
export const getOneContestApi = (ID) => API.get(`${URL.GET_CONTEST_ONE_URL}/${ID}`)
