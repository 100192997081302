import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const SanctionLetterTemplateListApi = (data) =>
    API.get(`${URL.SANCTION_LETTER_TEMPLATE_LIST}`, data)
export const SanctionLetterTemplateCreateApi = (data) =>
    API.post(`${URL.SANCTION_LETTER_TEMPLATE_CREATE}`, data)
export const SanctionLetterTemplateUpdateApi = (ID, data) =>
    API.patch(`${URL.SANCTION_LETTER_TEMPLATE_UPDATE}/${ID}`, data)
export const SanctionLetterTemplateDeleteApi = (ID) =>
    API.del(`${URL.SANCTION_LETTER_TEMPLATE_DELETE}/${ID}`)
