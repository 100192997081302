import { createAsyncThunk } from '@reduxjs/toolkit'
import * as service from './SanctionReminder.services'
import { paginationData } from '../Pagination/reducers'
import _ from 'lodash'

export const SanctionReminderListLogs = createAsyncThunk(
    '@Sanction/Reminder/allList/log/list',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await service.SanctionReminderListLogsApi(data)

            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data?._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SanctionReminderLogs = createAsyncThunk(
    '@Sanction/Reminder/log/list/username',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await service.SanctionReminderLogsApi(data.ID, data.params)

            if (Number(data?.params?._limit || 0) > 1) {
                // Pagination Section
                const limit = data?.params?._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?.params?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (Number(data?.params?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SanctionReminderTemplateList = createAsyncThunk(
    '@Sanction/Reminder/Template/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await service.SanctionReminderTemplateListApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SanctionReminderTemplateCreate = createAsyncThunk(
    '@Sanction/Reminder/Template/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await service.SanctionReminderTemplateCreateApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SanctionReminderTemplateUpdate = createAsyncThunk(
    '@Sanction/Reminder/Template/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await service.SanctionReminderTemplateUpdateApi(
                data?.sanction_reminder_template_id,
                data.formData
            )

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SanctionReminderTemplateDelete = createAsyncThunk(
    '@Sanction/Reminder/Template/delete',
    async (data, { rejectWithValue }) => {
        try {
            const res = await service.SanctionReminderTemplateDeleteApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SanctionReminderSetupList = createAsyncThunk(
    '@Sanction/Reminder/setup/list',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await service.SanctionReminderSetupListApi(data)

            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data?._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SanctionReminderSetupCreate = createAsyncThunk(
    '@Sanction/Reminder/Setup/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await service.SanctionReminderSetupCreateApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SanctionReminderSetupUpdate = createAsyncThunk(
    '@Sanction/Reminder/Setup/update',
    async (data, { rejectWithValue }) => {
        try {
            const finalData = _.omit(data, ['sanction_reminder_id'])
            const res = await service.SanctionReminderSetupUpdateApi(
                data?.sanction_reminder_id,
                finalData
            )

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SanctionReminderSetupDelete = createAsyncThunk(
    '@Sanction/Reminder/Setup/delete',
    async (data, { rejectWithValue }) => {
        try {
            const res = await service.SanctionReminderSetupDeleteApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SanctionReminderSetupOne = createAsyncThunk(
    '@Sanction/Reminder/Setup/One',
    async (data, { rejectWithValue }) => {
        try {
            const res = await service.SanctionReminderSetupOneApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const SanctionReminderLogsUserList = createAsyncThunk(
    '@Sanction/Reminder/Setup/User/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await service.SanctionReminderLogsUserListApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
