import { createSlice } from '@reduxjs/toolkit'
import {
    GetCompleteExhListWithPoint,
    GetControlSheetExhByPointsPlacing,
    GetControlSheetExhListByPoints,
    GetControlSheetFurResult,
    GetControlSheetPlacing,
    GetControlSheetPlacingForQualityPoints,
    NationalReportExhibitorList,
    SweepstakeAllPointList,
    controlSheetBreedDetailForNational,
    settingBreedListForNational,
    settingDetailForNational,
    sweepStakePlacingListForNational,
    GetDetailByBreedCompleteExhListWithPoint,
    GetNewsLetterControlSheetPlacing,
    getNewsLetterPlacing
} from './thunk'
import _ from 'lodash'

export const Form = {
    show_id: '',
    breed_detail: {}
}

export const initialState = {
    form: Form,
    breed_list: [],
    breedListLoading: true,
    breedDetail: {},
    breedDetailForOpt: {
        group: [],
        variety: [],
        class: []
    },
    breedDetailLoading: false,
    placingLoading: true,
    placingData: {},
    sweepStakeAllPlacingPoints: {},
    sweepStakeAllPlacingPointsLoading: true,
    exhibitorList: [],
    savedPlacing: [],
    savedPlacingLoading: true,
    newsLetterTopAwards: [],
    newsLetterTopAwardsLoading: true,
    detailByBreedExhBreedList: [],
    exhibitorLoading: false,
    exhibitorListByPointList: [],
    exhibitorListByPointListLoading: true,
    newsLetterAllBreed: [],
    controlSheetType: '',
    loading: true
}
const NationalControlSheet = createSlice({
    name: '@Sweepstake/ShowSetup/NationalControlSheet',
    initialState,
    reducers: {
        changeActiveBreed: (state, action) => {
            const { payload } = action
            state.breed_list = state.breed_list.map((b) => {
                if (b.uniqueId === payload.uniqueId) {
                    return {
                        ...b,
                        isActive: true
                    }
                }
                return {
                    ...b,
                    isActive: false
                }
            })
            // state.breedDetail = {}
            // state.breedDetailForOpt = {
            //     group: [],
            //     variety: [],
            //     class: []
            // }
        },
        setControlSheetType: (state, action) => {
            const { payload } = action
            state.controlSheetType = payload?.controlSheetType
        },
        setExhibitorLoadingForNational: (state, action) => {
            const { payload } = action
            state.loading = payload.loading
        }
    },
    extraReducers: (builder) => {
        builder.addCase(controlSheetBreedDetailForNational.pending, (state) => {
            state.breedDetailLoading = true
            state.breedDetail = {}
            state.breedDetailForOpt = {
                group: [],
                variety: [],
                class: []
            }
        })
        builder.addCase(controlSheetBreedDetailForNational.fulfilled, (state, action) => {
            state.sweepStakeAllPlacingPointsLoading = true
            const { payload } = action
            state.breedDetail = _.first(payload?.data) || {}
            state.breedDetailForOpt.group = _.unionBy(
                _.map(_.get(_.first(payload?.data), 'groupList') || [], (_g) => ({
                    label: _g?.group_name,
                    value: _g?.group_id
                })),
                'value'
            )
            state.breedDetailForOpt.variety = _.map(
                _.get(_.first(payload?.data), 'varietyList') || [],
                (_iv) => ({
                    label: _iv?.variety_name,
                    value: _iv?.variety_id,
                    group_id: _iv?.group_id || null
                })
            )
            state.breedDetailForOpt.class = _.map(
                _.get(_.first(payload?.data), 'classList') || [],
                (_iv) => ({ label: _iv?.class_name, value: _iv?.class_id, ..._iv })
            )
            state.breedDetailLoading = false

            // }
        })
        builder.addCase(controlSheetBreedDetailForNational.rejected, (state) => {
            state.breedDetailLoading = false
            state.breedDetail = {}
        })
        builder.addCase(settingDetailForNational.pending, (state) => {
            state.breedListLoading = true
            state.breed_list = []
        })
        builder.addCase(settingDetailForNational.fulfilled, (state, action) => {
            const { payload } = action
            state.breed_list = payload
            state.breedListLoading = false

            // }
        })
        builder.addCase(settingDetailForNational.rejected, (state) => {
            state.breedListLoading = false
            state.breed_list = []
        })
        builder.addCase(settingBreedListForNational.pending, (state) => {
            state.breedListLoading = true
            state.breed_list = []
        })
        builder.addCase(settingBreedListForNational.fulfilled, (state, action) => {
            const { payload } = action
            state.breed_list = payload
            state.breedListLoading = false

            // }
        })
        builder.addCase(settingBreedListForNational.rejected, (state) => {
            state.breedListLoading = false
            state.breed_list = []
        })
        builder.addCase(sweepStakePlacingListForNational.pending, (state) => {
            state.placingData = {}
            state.placingLoading = true
        })
        builder.addCase(sweepStakePlacingListForNational.fulfilled, (state, action) => {
            const { payload } = action
            state.placingData = payload
            state.placingLoading = false

            // }
        })
        builder.addCase(sweepStakePlacingListForNational.rejected, (state) => {
            state.placingData = {}
            state.placingLoading = false
        })

        builder.addCase(NationalReportExhibitorList.pending, (state) => {
            state.loading = true
            state.exhibitorLoading = true
            state.exhibitorList = []
        })
        builder.addCase(NationalReportExhibitorList.fulfilled, (state, action) => {
            state.exhibitorList = action?.payload || []
            state.loading = false
            state.exhibitorLoading = false

            // }
        })
        builder.addCase(NationalReportExhibitorList.rejected, (state) => {
            state.loading = false
            state.exhibitorList = []
            state.exhibitorLoading = false
        })

        builder.addCase(SweepstakeAllPointList.pending, (state) => {
            state.loading = false
            state.sweepStakeAllPlacingPointsLoading = true
            state.sweepStakeAllPlacingPoints = {}
        })
        builder.addCase(SweepstakeAllPointList.fulfilled, (state, action) => {
            state.sweepStakeAllPlacingPointsLoading = false
            state.sweepStakeAllPlacingPoints = action?.payload || {}
            state.loading = false

            // }
        })
        builder.addCase(SweepstakeAllPointList.rejected, (state) => {
            state.sweepStakeAllPlacingPointsLoading = false
            state.loading = false
            state.sweepStakeAllPlacingPoints = {}
        })
        builder.addCase(GetControlSheetPlacing.pending, (state) => {
            state.hasDetailByBreedEntry = true
            state.detailByBreedExhBreedList = []
            state.savedPlacingLoading = state.savedPlacingLoading ? true : state.savedPlacingLoading
        })
        builder.addCase(GetControlSheetPlacing.fulfilled, (state, action) => {
            const { payload } = action
            if (payload?.with_exh_count_data) {
                const savedData = _.first(payload.data)

                state.savedPlacing = _.get(savedData, 'details_by_breed_points') || []
                state.detailByBreedExhBreedList =
                    _.get(savedData, 'details_by_breed_exh_count') || []
            } else {
                state.savedPlacing = payload?.data || []
            }

            state.savedPlacingLoading = false

            // }
        })
        builder.addCase(GetControlSheetPlacing.rejected, (state) => {
            state.savedPlacingLoading = false
        })
        builder.addCase(GetControlSheetPlacingForQualityPoints.pending, (state) => {
            state.hasDetailByBreedEntry = true
            state.savedPlacingLoading = state.savedPlacingLoading ? true : state.savedPlacingLoading
        })
        builder.addCase(GetControlSheetPlacingForQualityPoints.fulfilled, (state, action) => {
            state.savedPlacing = action?.payload?.data || []
            state.savedPlacingLoading = false

            // }
        })
        builder.addCase(GetControlSheetPlacingForQualityPoints.rejected, (state) => {
            state.savedPlacingLoading = false
        })
        builder.addCase(GetDetailByBreedCompleteExhListWithPoint.pending, (state) => {
            state.hasDetailByBreedEntry = true
            state.savedPlacingLoading = state.savedPlacingLoading ? true : state.savedPlacingLoading
        })
        builder.addCase(GetDetailByBreedCompleteExhListWithPoint.fulfilled, (state, action) => {
            state.savedPlacing = action?.payload?.data || []
            state.savedPlacingLoading = false

            // }
        })
        builder.addCase(GetDetailByBreedCompleteExhListWithPoint.rejected, (state) => {
            state.savedPlacingLoading = false
        })
        builder.addCase(GetControlSheetExhListByPoints.pending, (state) => {
            state.exhibitorListByPointListLoading = true
        })
        builder.addCase(GetControlSheetExhListByPoints.fulfilled, (state, action) => {
            state.exhibitorListByPointList = action?.payload?.data || []
            state.exhibitorListByPointListLoading = false

            // }
        })
        builder.addCase(GetControlSheetExhListByPoints.rejected, (state) => {
            state.exhibitorListByPointListLoading = false
        })

        builder.addCase(GetControlSheetFurResult.pending, (state) => {
            state.hasDetailByBreedEntry = true
            state.savedPlacingLoading = state.savedPlacingLoading ? true : state.savedPlacingLoading
        })
        builder.addCase(GetControlSheetFurResult.fulfilled, (state, action) => {
            state.savedPlacing = action?.payload?.data || []
            state.savedPlacingLoading = false

            // }
        })
        builder.addCase(GetControlSheetFurResult.rejected, (state) => {
            state.savedPlacingLoading = false
        })
        builder.addCase(GetCompleteExhListWithPoint.pending, (state) => {
            state.savedPlacingLoading = state.savedPlacingLoading ? true : state.savedPlacingLoading
        })
        builder.addCase(GetCompleteExhListWithPoint.fulfilled, (state, action) => {
            state.savedPlacing = action?.payload?.data || []
            state.savedPlacingLoading = false

            // }
        })
        builder.addCase(GetCompleteExhListWithPoint.rejected, (state) => {
            state.savedPlacingLoading = false
        })
        builder.addCase(GetControlSheetExhByPointsPlacing.pending, (state) => {
            state.exhibitorListByPointListLoading = true
        })
        builder.addCase(GetControlSheetExhByPointsPlacing.fulfilled, (state, action) => {
            state.exhibitorListByPointList = action?.payload?.data || []
            state.exhibitorListByPointListLoading = false

            // }
        })
        builder.addCase(GetControlSheetExhByPointsPlacing.rejected, (state) => {
            state.exhibitorListByPointListLoading = false
        })

        //
        builder.addCase(GetNewsLetterControlSheetPlacing.pending, (state) => {
            state.newsLetterTopAwardsLoading = true
        })
        builder.addCase(GetNewsLetterControlSheetPlacing.fulfilled, (state, action) => {
            state.newsLetterTopAwards = action?.payload?.data || []
            state.newsLetterTopAwardsLoading = false
        })
        builder.addCase(GetNewsLetterControlSheetPlacing.rejected, (state) => {
            state.newsLetterTopAwardsLoading = false
        })

        ///
        builder.addCase(getNewsLetterPlacing.pending, (state) => {
            state.newsLetterTopAwardsLoading = true
            state.savedPlacingLoading = true
        })
        builder.addCase(getNewsLetterPlacing.fulfilled, (state, action) => {
            const { payload } = action

            const newsletterPoints = _.get(_.first(payload?.data), 'newsletter_points')
            const newsletterExhCount = _.get(_.first(payload?.data), 'newsletter_exh_count')

            state.savedPlacing = newsletterPoints || []
            state.newsLetterAllBreed = newsletterExhCount || []
            state.newsLetterTopAwardsLoading = false
            state.savedPlacingLoading = false
        })
        builder.addCase(getNewsLetterPlacing.rejected, (state) => {
            state.newsLetterTopAwardsLoading = false
            state.savedPlacingLoading = false
        })
    }
})

export const {
    changeActiveBreed,
    setExhibitorLoadingForNational,
    resetBreedDetail,
    setControlSheetType
} = NationalControlSheet.actions

export default NationalControlSheet.reducer
