import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

export const SanctionCardTemplateCreateApi = (data) =>
    API.post(`${URL.SANCTION_CARD_TEMPLATE_CREATE_URL}`, data)
export const SanctionCardTemplateUpdateApi = (ID, data) =>
    API.patch(`${URL.SANCTION_CARD_TEMPLATE_UPDATE_URL}/${ID}`, data)

//
export const SanctionCardTemplateListApi = (data) =>
    API.get(`${URL.SANCTION_CARD_TEMPLATE_LIST_URL}`, data)
//
export const SanctionCardTemplateDeleteApi = (ID) =>
    API.del(`${URL.SANCTION_CARD_TEMPLATE_DELETE_URL}/${ID}`)
