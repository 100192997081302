import { createSlice } from '@reduxjs/toolkit'
import {
    SanctionLetterTemplateList,
    SanctionLetterTemplateCreate,
    SanctionLetterTemplateUpdate
} from './thunk'
import _ from 'lodash'

export const LetterTemplateForm = {
    document_file: '',
    sanction_letter_template_id: '',
    info_1: '',
    logo_file: '',
    sign_data: '',
    logo: ''
}
export const initialState = {
    form: LetterTemplateForm,
    loading: true,
    pageLoading: true
}

const letterTemplate = createSlice({
    name: 'LetterTemplate',
    initialState,
    reducers: {
        setPageLoader: (state) => {
            state.pageLoading = !state.pageLoading
        }
    },
    extraReducers: (builder) => {
        // Listing
        builder.addCase(SanctionLetterTemplateList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(SanctionLetterTemplateList.fulfilled, (state, action) => {
            const { data } = action.payload
            const formData = _.first(data)

            const finalData = _.pick(formData, Object.keys(LetterTemplateForm))

            state.form = finalData ? finalData : LetterTemplateForm
            state.loading = false
        })
        builder.addCase(SanctionLetterTemplateList.rejected, (state) => {
            state.loading = false
        })

        // Create
        builder.addCase(SanctionLetterTemplateCreate.pending, (state) => {
            state.loading = false
            // state.pageLoading = true
        })
        builder.addCase(SanctionLetterTemplateCreate.fulfilled, (state) => {
            state.pageLoading = !state.pageLoading
        })
        builder.addCase(SanctionLetterTemplateCreate.rejected, (state) => {
            state.loading = false
        })

        // Update
        builder.addCase(SanctionLetterTemplateUpdate.pending, (state) => {
            state.loading = false
        })
        builder.addCase(SanctionLetterTemplateUpdate.fulfilled, (state) => {
            state.pageLoading = !state.pageLoading
        })
        builder.addCase(SanctionLetterTemplateUpdate.rejected, (state) => {
            state.loading = false
        })
    }
})

export const { setPageLoader } = letterTemplate.actions
export default letterTemplate.reducer
