import { createSlice } from '@reduxjs/toolkit'
import {
   SanctionReminderLogs,
   SanctionReminderSetupList,
   SanctionReminderSetupOne,
   SanctionReminderTemplateList,
   SanctionReminderListLogs,
   SanctionReminderLogsUserList
} from './thunk'
import _ from 'lodash'
import { toast } from 'react-toastify'

export const SanctionReminderSetupForm = {
    title: '',
    prior_day: ''
}

export const LetterTemplateForm = {
    logo_file: '',
    info: '',
    signature: '',
    sanction_reminder_template_id: '',
    document_file: ''
}
export const initialState = {
    SanctionReminderSetup: {
        form: SanctionReminderSetupForm,
        list: [],
        loading: true
    },
    SanctionReminderTemplate: {
        form: LetterTemplateForm,
        loading: true
    },
    SanctionReminderLogs: {
        ReminderList: [],
        list: [],
        UserList: [],
        loading: false
    }
}
const MemberShipReminder = createSlice({
    name: '@sanction/Reminder',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(SanctionReminderTemplateList.pending, (state) => {
            state.SanctionReminderTemplate.loading = true
        })
        builder.addCase(SanctionReminderTemplateList.fulfilled, (state, action) => {
            const { data } = action.payload
            const formData = _.first(data)

            const finalData = _.pick(formData, Object.keys(LetterTemplateForm))

            state.SanctionReminderTemplate.form = formData ? finalData : LetterTemplateForm
            state.SanctionReminderTemplate.loading = false
        })
        builder.addCase(SanctionReminderTemplateList.rejected, (state) => {
            state.SanctionReminderTemplate.loading = false
        })
        builder.addCase(SanctionReminderLogs.pending, (state) => {
            state.SanctionReminderLogs.loading = true
        })
        builder.addCase(SanctionReminderLogs.fulfilled, (state, action) => {
            const { data } = action.payload
            state.SanctionReminderLogs.list = data
            state.SanctionReminderLogs.loading = false
        })
        builder.addCase(SanctionReminderLogs.rejected, (state) => {
            state.SanctionReminderLogs.loading = false
        })
        builder.addCase(SanctionReminderSetupList.pending, (state) => {
            state.SanctionReminderSetup.form = SanctionReminderSetupForm
            state.SanctionReminderSetup.loading = true
        })
        builder.addCase(SanctionReminderSetupList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.SanctionReminderSetup.list = data || []
            state.SanctionReminderSetup.loading = false
        })
        builder.addCase(SanctionReminderSetupList.rejected, (state) => {
            state.SanctionReminderSetup.loading = false
        })
        builder.addCase(SanctionReminderSetupOne.pending, (state) => {
            state.SanctionReminderSetup.loading = true
        })
        builder.addCase(SanctionReminderSetupOne.fulfilled, (state, action) => {
            const { data } = action.payload
            const finalData = _.pick(data, Object.keys(SanctionReminderSetupForm))
            state.SanctionReminderSetup.form = finalData
            state.SanctionReminderSetup.loading = false
        })
        builder.addCase(SanctionReminderSetupOne.rejected, (state) => {
            state.SanctionReminderSetup.loading = false
        })
        // Reminder List Logs
        builder.addCase(SanctionReminderListLogs.pending, (state) => {
            state.SanctionReminderLogs.loading = true
        })
        builder.addCase(SanctionReminderListLogs.fulfilled, (state, action) => {
            const { data } = action.payload
            state.SanctionReminderLogs.ReminderList = data
            state.SanctionReminderLogs.loading = false
        })
        builder.addCase(SanctionReminderListLogs.rejected, (state) => {
            state.SanctionReminderLogs.loading = false
        })
        // Reminder List Logs

        builder.addCase(SanctionReminderLogsUserList.fulfilled, (state, action) => {
            const { data } = action.payload

            state.SanctionReminderLogs.UserList = Array.isArray(data)
                ? _.map(data, (v) => ({
                      label: `${v?.last_name || ''}  ${v?.first_name || ''} - (${v.email})`,
                      value: v?.user_id
                  }))
                : []
        })
        builder.addCase(SanctionReminderLogsUserList.rejected, (state, action) => {
            const { data } = action.payload
            toast.error(data)
            state.SanctionReminderLogs.loading = false
        })
    }
})

// export const {} = SetupFee.actions
export default MemberShipReminder.reducer
