import { createSlice } from '@reduxjs/toolkit'
import {
    MembershipReminderLogs,
    MembershipReminderSetupList,
    MembershipReminderSetupOne,
    MembershipReminderTemplateList,
    MembershipReminderListLogs,
    MembershipReminderLogsUserList
} from './thunk'
import _ from 'lodash'
import { toast } from 'react-toastify'

export const MembershipReminderSetupForm = {
    title: '',
    prior_day: ''
}

export const LetterTemplateForm = {
    logo_file: '',
    info: '',
    signature: '',
    membership_reminder_template_id: '',
    document_file: ''
}
export const initialState = {
    MembershipReminderSetup: {
        form: MembershipReminderSetupForm,
        list: [],
        loading: true
    },
    MembershipReminderTemplate: {
        form: LetterTemplateForm,
        loading: true
    },
    MembershipReminderLogs: {
        ReminderList: [],
        list: [],
        UserList: [],
        loading: false
    }
}
const MemberShipReminder = createSlice({
    name: '@membership/Reminder',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(MembershipReminderTemplateList.pending, (state) => {
            state.MembershipReminderTemplate.loading = true
        })
        builder.addCase(MembershipReminderTemplateList.fulfilled, (state, action) => {
            const { data } = action.payload
            const formData = _.first(data)

            const finalData = _.pick(formData, Object.keys(LetterTemplateForm))

            state.MembershipReminderTemplate.form = formData ? finalData : LetterTemplateForm
            state.MembershipReminderTemplate.loading = false
        })
        builder.addCase(MembershipReminderTemplateList.rejected, (state) => {
            state.MembershipReminderTemplate.loading = false
        })
        builder.addCase(MembershipReminderLogs.pending, (state) => {
            state.MembershipReminderLogs.loading = true
        })
        builder.addCase(MembershipReminderLogs.fulfilled, (state, action) => {
            const { data } = action.payload
            state.MembershipReminderLogs.list = data
            state.MembershipReminderLogs.loading = false
        })
        builder.addCase(MembershipReminderLogs.rejected, (state) => {
            state.MembershipReminderLogs.loading = false
        })
        builder.addCase(MembershipReminderSetupList.pending, (state) => {
            state.MembershipReminderSetup.form = MembershipReminderSetupForm
            state.MembershipReminderSetup.loading = true
        })
        builder.addCase(MembershipReminderSetupList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.MembershipReminderSetup.list = data
            state.MembershipReminderSetup.loading = false
        })
        builder.addCase(MembershipReminderSetupList.rejected, (state) => {
            state.MembershipReminderSetup.loading = false
        })
        builder.addCase(MembershipReminderSetupOne.pending, (state) => {
            state.MembershipReminderSetup.loading = true
        })
        builder.addCase(MembershipReminderSetupOne.fulfilled, (state, action) => {
            const { data } = action.payload
            const finalData = _.pick(data, Object.keys(MembershipReminderSetupForm))
            state.MembershipReminderSetup.form = finalData
            state.MembershipReminderSetup.loading = false
        })
        builder.addCase(MembershipReminderSetupOne.rejected, (state) => {
            state.MembershipReminderSetup.loading = false
        })
        // Reminder List Logs
        builder.addCase(MembershipReminderListLogs.pending, (state) => {
            state.MembershipReminderLogs.loading = true
        })
        builder.addCase(MembershipReminderListLogs.fulfilled, (state, action) => {
            const { data } = action.payload
            state.MembershipReminderLogs.ReminderList = data
            state.MembershipReminderLogs.loading = false
        })
        builder.addCase(MembershipReminderListLogs.rejected, (state) => {
            state.MembershipReminderLogs.loading = false
        })
        // Reminder List Logs

        builder.addCase(MembershipReminderLogsUserList.fulfilled, (state, action) => {
            const { data } = action.payload

            state.MembershipReminderLogs.UserList = Array.isArray(data)
                ? _.map(data, (v) => ({
                      label: `${v?.last_name || ''}  ${v?.first_name || ''} - (${v.email})`,
                      value: v?.user_id
                  }))
                : []
        })
        builder.addCase(MembershipReminderLogsUserList.rejected, (state, action) => {
            const { data } = action.payload
            toast.error(data)
            state.MembershipReminderLogs.loading = false
        })
    }
})

// export const {} = SetupFee.actions
export default MemberShipReminder.reducer
