import { createSlice } from '@reduxjs/toolkit'
import { ClubUserById, ClubUserCreate, ClubUserList, ClubUserListForMemberCreate } from './thunk'
import _ from 'lodash'

export const clubUserForm = {
    id: '',
    user_role: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
    address_1: '',
    address_2: '',
    country_id: '',
    state_id: '',
    city: '',
    zipcode: '',
    is_no_email: false,
    user_address_id: '',
    arba_number: '',
    active: '',
    is_minor: false,
    date_of_birth: ''
}
export const aliasUserForm = {
    alias_name: '',
    country_id: '',
    state_id: ''
}
export const initialState = {
    data: [],
    form: clubUserForm,
    aliasForm: aliasUserForm,
    loading: true
}
const ClubUser = createSlice({
    name: 'clubUser',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(ClubUserList.pending, (state) => {
            state.data = []
            state.loading = true
        })
        builder.addCase(ClubUserList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.form = clubUserForm
            state.data = data
            state.loading = false
        })
        builder.addCase(ClubUserList.rejected, (state) => {
            state.data = []
            state.loading = false
        })
        builder.addCase(ClubUserListForMemberCreate.pending, (state) => {
            state.data = []
            state.loading = true
        })
        builder.addCase(ClubUserListForMemberCreate.fulfilled, (state, action) => {
            const { data } = action.payload
            const finalData = Array.isArray(data)
                ? data.map((q) => ({
                      ...q,
                      value: q?.id,
                      label: `${q?.last_name || ''} ${q?.first_name || ''} - (${
                          q.email || 'No Email'
                      })`
                  }))
                : []

            state.data = finalData
            state.loading = false
        })
        builder.addCase(ClubUserListForMemberCreate.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(ClubUserCreate.pending, (state) => {
            state.loading = false
        })
        builder.addCase(ClubUserCreate.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(ClubUserCreate.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(ClubUserById.pending, (state) => {
            state.loading = false
        })
        builder.addCase(ClubUserById.fulfilled, (state, action) => {
            let { payload } = action

            payload = {
                data: {
                    ...payload.data,
                    is_minor: payload.data.is_minor === 'Y' ? true : false,
                    is_no_email: payload.data.email ? false : true,
                    email: payload.data.email ? payload.data.email : '',
                    password: payload.data.email ? payload.data.password : ''
                }
            }

            const data = _.pick(payload?.data, Object.keys(clubUserForm))
            state.form = data
            state.loading = false
        })
        builder.addCase(ClubUserById.rejected, (state) => {
            state.loading = false
        })
    }
})

// export const {} = SetupFee.actions
export default ClubUser.reducer
