import { createAsyncThunk } from '@reduxjs/toolkit'
import { ConnectedAccountApi, ConnectedAccountDashboardApi } from './ConnectAccount.services'

export const ConnectedAccount = createAsyncThunk(
    '/Connected/Account',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ConnectedAccountApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const ConnectedAccountDashboard = createAsyncThunk(
    '/Connected/Account/dashboard',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ConnectedAccountDashboardApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
