import { createSlice } from '@reduxjs/toolkit'
import { MembershipRenewTemplateList } from './thunk'
import _ from 'lodash'
// import _ from 'lodash'

export const form = {
    logo_file: '',
    logo: '',
    info_1: '',
    sign_data: '',
    logo_file_name:'',
}

export const initialState = {
    loading: true,
    form
}
const MemberShipPlan = createSlice({
    name: 'RenewMembership',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(MembershipRenewTemplateList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(MembershipRenewTemplateList.fulfilled, (state, action) => {
            const { data } = action.payload
            let formData = _.first(data)
            if (formData) {
                formData = {
                    membership_letter_template_renew_id:
                        formData?.membership_letter_template_renew_id || '',
                    club_id: formData?.club_id || '',
                    info_1: formData?.info_1 || '',
                    sign_data: formData?.sign_data || '',
                    logo_file: formData?.document_file || '',
                    logo_file_name: formData?.logo_file || '',
                    logo: ''
                    // ...formData
                }
            }

            state.form = formData ? formData : form
            state.loading = false
        })
        builder.addCase(MembershipRenewTemplateList.rejected, (state) => {
            state.loading = false
        })
    }
})

// export const {} = SetupFee.actions
export default MemberShipPlan.reducer
