import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

const AuthGuard = (props) => {
    const { isAuth, loading } = useSelector((state) => state.Login)

    // useEffect(() => {
    //     if (!isAuth && !loading) {
    //         navigate('/login')
    //     }
    // }, [isAuth, loading, navigate])
    if (!isAuth && !loading) {
        return <Navigate to={{ pathname: '/login' }} />
    }

    const userData = localStorage.getItem('user')
    if (!userData) {
        window.location.replace(`${process.env.REACT_APP_FRONT_URL}/club-membership?logout=true`)
    }
    return props.children
}

export default AuthGuard
