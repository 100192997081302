import { createSlice } from '@reduxjs/toolkit'
import { ShowClassificationById, ShowClassificationCreate, ShowClassificationList } from './thunk'
import _ from 'lodash'
import { toast } from 'react-toastify'

export const showClassificationForm = {
    show_type_name: ''
}

export const initialState = {
    list: [],
    loading: false,
    form: showClassificationForm
}
const SanctionShowClassification = createSlice({
    name: '@Sanction/ShowClassification',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(ShowClassificationList.pending, (state) => {
            state.form = showClassificationForm
            state.loading = true
        })
        builder.addCase(ShowClassificationList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.list = data
            state.loading = false
        })
        builder.addCase(ShowClassificationList.rejected, (state, action) => {
            const { data } = action.payload
            if (data) {
                toast.error(data)
            }
            state.loading = false
        })
        builder.addCase(ShowClassificationCreate.pending, (state) => {
            state.loading = false
        })
        builder.addCase(ShowClassificationCreate.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(ShowClassificationCreate.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(ShowClassificationById.pending, (state) => {
            state.loading = true
        })
        builder.addCase(ShowClassificationById.fulfilled, (state, action) => {
            const { payload } = action

            const data = _.pick(payload?.data, Object.keys(showClassificationForm))
            const finalData = {
                ...data
            }

            state.form = finalData
            state.loading = false
        })
        builder.addCase(ShowClassificationById.rejected, (state) => {
            state.loading = false
        })
    }
})

export default SanctionShowClassification.reducer
