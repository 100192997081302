import { createSlice } from '@reduxjs/toolkit'
import {
    MemberShipAgeClassificationList,
    MemberShipPlanListForMemberCreate,
    MemberShipPlansList,
    MembershipPlansById
} from './thunk'
import _ from 'lodash'

export const ageClassificationForm = {
    club_membership_plan_age_id: '',
    membership_plan_id: '',
    age_id: null,
    age_type_name: null,
    no_of_people: '',
    selected: false
    // age_id: null,
    // no_of_people: null
}
export const membershipForm = {
    plan_name: '',
    mem_status: '',
    mem_region_id: null,
    mem_state_id: null,
    mem_term: '',
    amount: '',
    additional_char: '',
    display_order: null,
    publish: true,
    lbl_txt: null,
    lbl_description: null,
    limited_period: '0',
    start_date: '',
    end_date: '',
    grace_period_days: '',
    age_classification_data: [ageClassificationForm]
}

export const initialState = {
    data: [],
    loading: true,
    ageClassificationLoading: true,
    form: membershipForm
}
const MemberShipPlan = createSlice({
    name: 'memberShipPlans',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(MemberShipPlansList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(MemberShipPlansList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.form = membershipForm
            state.data = data
            state.loading = false
        })
        builder.addCase(MemberShipPlansList.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(MemberShipPlanListForMemberCreate.pending, (state) => {
            state.data = []
            state.loading = true
        })
        builder.addCase(MemberShipPlanListForMemberCreate.fulfilled, (state, action) => {
            const { data } = action.payload
            const finalData = Array.isArray(data)
                ? data
                      .filter((q) => q?.publish === 'Y')
                      .map((q) => ({
                          value: q?.club_membership_plans_id,
                          label: q?.plan_name
                      }))
                : []
            state.data = finalData
            state.loading = false
        })
        builder.addCase(MemberShipPlanListForMemberCreate.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(MembershipPlansById.pending, (state) => {
            state.loading = false
        })
        builder.addCase(MembershipPlansById.fulfilled, (state, action) => {
            let { payload } = action
            const ageClassification = _.map(payload?.data.age_classification, (q) =>
                _.pick(q, Object.keys(ageClassificationForm))
            )

            payload = {
                data: {
                    ...payload.data,
                    limited_period: payload?.data?.limited_period
                    ? String(payload?.data?.limited_period)
                    : '0',
                    age_classification_data: state.form.age_classification_data.map((q) => {
                        const hasAge = ageClassification.find((f) => f.age_id === q.age_id)
                        if (hasAge) {
                            return {
                                ...q,
                                ...hasAge,
                                selected: true
                            }
                        }
                        return q
                    }),
                    publish: payload?.data?.publish === 'Y' ? true : false
                }
            }

            const data = _.pick(payload?.data, Object.keys(membershipForm))
            state.form = data
            state.loading = false
        })
        builder.addCase(MembershipPlansById.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(MemberShipAgeClassificationList.pending, (state) => {
            state.ageClassificationLoading = true
        })
        builder.addCase(MemberShipAgeClassificationList.fulfilled, (state, action) => {
            let ageList = []
            if (Array.isArray(action.payload.data)) {
                ageList = action.payload.data.map((age) => {
                    return {
                        club_membership_plan_age_id: '',
                        age_id: age?.age_classification_id,
                        age_type_name: age?.age_type_name,
                        no_of_people: '',
                        selected: false
                    }
                })
            }
            state.form.age_classification_data = ageList
            state.ageClassificationLoading = false
        })
        builder.addCase(MemberShipAgeClassificationList.rejected, (state) => {
            state.ageClassificationLoading = false
        })
    }
})

// export const {} = SetupFee.actions
export default MemberShipPlan.reducer
