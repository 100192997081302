import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

export const MembershipProcessFeesListAdminApi = (data) =>
    API.get(`${URL.MEMBERSHIP_PROCESSING_FEES_LIST_ADMIN_URL}`, data)
export const MembershipPurchasePaymentIntentApi = (data) =>
    API.post(`${URL.MEMBERSHIP_PURCHASE_PAYMENT_INTENT}`, data)
export const SuccessMembershipPaymentApi = (data) =>
    API.post(`${URL.MEMBERSHIP_PURCHASE_PAYMENT_SUCCESS}`, data)
export const MembershipAllStatusApi = (data) => API.get(`${URL.MEMBERSHIP_ALL_STATUS}`, data)
