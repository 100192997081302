import { createSlice } from '@reduxjs/toolkit'
import {
    MemberShipIDConfigurationCreate,
    MemberShipIDConfigurationList,
    MemberShipIDConfigurationUpdate
} from './thunk'
import _ from 'lodash'

export const IDConfigurationForm = {
    membership_id_config_id: '',
    no_of_chars: 1,
    step: 'up',
    is_add_char: false,
    char_position: 'front',
    generation_type: 'alphanumeric', //
    start_no: ['0'],
    end_no: ['0']
}
export const initialState = {
    form: IDConfigurationForm,
    loading: true,
    pageLoading: true
}

const Modal = createSlice({
    name: 'Membership/IDConfiguration',
    initialState,
    reducers: {
        setPageLoader: (state) => {
            state.pageLoading = !state.pageLoading
        }
    },
    extraReducers: (builder) => {
        // Listing
        builder.addCase(MemberShipIDConfigurationList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(MemberShipIDConfigurationList.fulfilled, (state, action) => {
            const { data } = action.payload
            let formData = _.first(data)
            if (formData) {
                formData = {
                    ...formData,
                    is_add_char: formData.is_add_char === 'Y' ? true : false,
                    end_no: formData.end_no.split('-'),
                    start_no: formData.start_no.split('-'),
                    char_position: formData?.char_position,
                    step: formData?.step || '',
                    generation_type: formData?.generation_type || ''
                }
            }

            const finalData = _.pick(formData, Object.keys(IDConfigurationForm))

            state.form = formData ? finalData : IDConfigurationForm
            state.loading = false
        })
        builder.addCase(MemberShipIDConfigurationList.rejected, (state) => {
            state.loading = false
        })

        // Create
        builder.addCase(MemberShipIDConfigurationCreate.pending, (state) => {
            state.loading = false
            // state.pageLoading = true
        })
        builder.addCase(MemberShipIDConfigurationCreate.fulfilled, (state) => {
            state.pageLoading = !state.pageLoading
        })
        builder.addCase(MemberShipIDConfigurationCreate.rejected, (state) => {
            state.loading = false
        })

        // Update
        builder.addCase(MemberShipIDConfigurationUpdate.pending, (state) => {
            state.loading = false
        })
        builder.addCase(MemberShipIDConfigurationUpdate.fulfilled, (state) => {
            state.pageLoading = !state.pageLoading
        })
        builder.addCase(MemberShipIDConfigurationUpdate.rejected, (state) => {
            state.loading = false
        })
    }
})

export const { setPageLoader } = Modal.actions
export default Modal.reducer
