import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

export const MembershipCardTemplateCreateApi = (data) =>
    API.post(`${URL.MEMBERSHIP_CARD_TEMPLATE_CREATE_URL}`, data)
export const MembershipCardTemplateUpdateApi = (ID, data) =>
    API.patch(`${URL.MEMBERSHIP_CARD_TEMPLATE_UPDATE_URL}/${ID}`, data)

//
export const MembershipCardTemplateListApi = (data) =>
    API.get(`${URL.MEMBERSHIP_CARD_TEMPLATE_LIST_URL}`, data)
//
export const MembershipCardTemplateDeleteApi = (ID) =>
    API.del(`${URL.MEMBERSHIP_CARD_TEMPLATE_DELETE_URL}/${ID}`)
