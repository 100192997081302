import { createSlice } from '@reduxjs/toolkit'
import { ClubSanctionPaymentIntent } from './thunk'

const form = {
    open_youth_required: '',
    user_id: '',
    sanction_plan_id: '',
    club_id: '',
    planType: '',
    discount_type: '',
    no_of_open: 0,
    no_of_youth: 0,
    youth_price: 0,
    open_price: 0,
    host_name: '',
    show_name: '',
    show_date: '',
    show_address: '',
    city: '',
    state_id: '',
    country_id: '',
    zipcode: '',
    sanction_data: [],
    discount_on: '',
    hasPayment: false
}
export const initialState = {
    form,
    shoppingCart: {
        hasPayment: false
    },
    planDetailForm: {
        hasPayment: false
    },
    paymentData: {},
    loading: false
}
const ClubSanctionShoppingCart = createSlice({
    name: 'ClubSanctionShoppingCart',
    initialState,
    reducers: {
        resetSanctionShoppingCart: (state) => {
            state.form = form
            state.shoppingCart = {}
            state.planDetailForm = {}
            state.paymentData = {}
            state.loading = false
        },
        addToShoppingCart: (state, action) => {
            const { payload } = action
            state.shoppingCart = payload
            state.paymentData = {}
            state.loading = false
        },
        addToPlanDetail: (state, action) => {
            const { payload } = action
            state.planDetailForm = payload
            state.form = payload
            state.paymentData = {}
            state.loading = false
        }
    },
    extraReducers: (builder) => {
        // Listing
        builder.addCase(ClubSanctionPaymentIntent.pending, (state) => {
            state.loading = true
        })
        builder.addCase(ClubSanctionPaymentIntent.fulfilled, (state, action) => {
            state.paymentData = action.payload
            state.loading = false
        })
        builder.addCase(ClubSanctionPaymentIntent.rejected, (state) => {
            state.loading = false
        })
    }
})

export const { addToShoppingCart, addToPlanDetail, resetSanctionShoppingCart } =
    ClubSanctionShoppingCart.actions
export default ClubSanctionShoppingCart.reducer
