import { createAsyncThunk } from '@reduxjs/toolkit'
import { paginationData } from '../Pagination/reducers'
import {
    SanctionPlanByIdApi,
    SanctionPlanCreateApi,
    SanctionPlanDeleteApi,
    SanctionPlanListApi,
    SanctionPlanStatusApi,
    SanctionPlanUpdateApi
} from './SanctionPlans.services'

//services
export const SanctionPlanList = createAsyncThunk(
    'Sanction/Plans/List',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await SanctionPlanListApi(data)

            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const SanctionPlanListForOption = createAsyncThunk(
    'Sanction/Plans/option/List',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionPlanListApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const SanctionPlanCreate = createAsyncThunk(
    'Sanction/Plans/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionPlanCreateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const SanctionPlanUpdate = createAsyncThunk(
    'Sanction/Plans/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionPlanUpdateApi(data.sanction_plan_id, data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const SanctionPlanById = createAsyncThunk(
    'Sanction/Plans/one',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await SanctionPlanByIdApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const SanctionPlanDelete = createAsyncThunk(
    'Sanction/Plans/delete',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await SanctionPlanDeleteApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SanctionPlanStatus = createAsyncThunk(
    '/Sanction/changes/status',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionPlanStatusApi(data.sanction_plan_id, data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
