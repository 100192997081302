import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { Loader, Toast } from 'src/Atoms'
import Header from './VerticalLayout/Header'
import Footer from './VerticalLayout/Footer'

const NonAuthLayout = ({ children }) => {
    const { isAuth, loading } = useSelector((state) => state.Login)
    if (loading) {
        return (
            <div
                style={{ height: '100vh' }}
                className='d-flex  justify-content-center align-items-center'
            >
                <Loader color='primary' />
            </div>
        )
    }
    if (isAuth && !loading) {
        return <Navigate to={{ pathname: '/dashboard' }} />
    }
    const userData = localStorage.getItem('user')
    if (!userData) {
        window.location.replace(`${process.env.REACT_APP_FRONT_URL}/club-membership?logout=true`)
    }
    return (
        <>
            <Header unAuth />
            <Toast />
            {children}
            <Footer unAuth />
        </>
    )
}
NonAuthLayout.propTypes = {
    children: PropTypes.node
}

export default NonAuthLayout
