import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'
//
export const SweepstakeListApi = (data) => API.get(`${URL.GET_ALL_SWEEPSTAKE_POINTS_URL}`, data)
export const SweepstakePlacingListApi = (type, data) =>
    API.get(`${URL.GET_ALL_SWEEPSTAKE_PACING_URL}/${type}`, data)
export const createSweepStakeApi = (data) => API.post(`${URL.CREATE_SWEEPSTAKE_POINT_URL}`, data)
export const updateSweepStakeApi = (ID, data) =>
    API.put(`${URL.UPDATE_SWEEPSTAKE_POINT_URL}/${ID}`, data)
export const getOneSweepStakeApi = (ID) => API.get(`${URL.GET_SWEEPSTAKE_POINT_SINGLE_URL}/${ID}`)

export const deleteSweepStakeApi = (ID) => API.del(`${URL.DELETE_SWEEPSTAKE_POINT_URL}/${ID}`)
