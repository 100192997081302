import { createSlice } from '@reduxjs/toolkit'
import { AdminSanctionProcessFeeList } from './thunk'

export const form = []

export const initialState = {
    loading: true,
    form
}
const AdminSanctionProcessingFees = createSlice({
    name: 'Sanction-Processing/Admin',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(AdminSanctionProcessFeeList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(AdminSanctionProcessFeeList.fulfilled, (state, action) => {
            const { data } = action.payload
            // let formData = _.first(data)
            // if (formData) {
            //     formData = {
            //         processing_percentage: formData?.processing_percentage || '',
            //         sanction_profit_percentage_id: formData?.sanction_profit_percentage_id || ''
            //     }
            // }

            state.form = data || []
            state.loading = false
        })
        builder.addCase(AdminSanctionProcessFeeList.rejected, (state) => {
            state.loading = false
        })
    }
})

export default AdminSanctionProcessingFees.reducer
