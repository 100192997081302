import { useEffect } from 'react'

// import withRouter from "../Common/withRouter"

import { useSelector } from 'react-redux'
import { Container } from 'reactstrap'
import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'
// import { ToastContainer } from 'react-toastify';
import { Loader, Toast } from '../../Atoms'

import LoadingBar from 'react-redux-loading-bar'
import _ from 'lodash'
import { Navigate, useLocation, useParams } from 'react-router-dom'
// redux

const Layout = (props) => {
    const param = useParams()
    const newParam = _.invert(param)
    const path = useLocation()
        ?.pathname?.split('/')
        ?.map((p) => {
            if (newParam[p]) {
                return `:${newParam[p]}`
            }
            return p
        })
        .join('/')
    const { loading, admin } = useSelector((state) => state.Login)
    const { leftSideBarTheme } = useSelector((state) => ({
        isPreloader: state.Layout.isPreloader,
        leftSideBarType: state.Layout.leftSideBarType,
        layoutModeType: state.Layout.layoutModeType,
        layoutWidth: state.Layout.layoutWidth,
        topbarTheme: state.Layout.topbarTheme,
        sidebarSizeType: state.Layout.sidebarSizeType,
        showRightSidebar: state.Layout.showRightSidebar,
        leftSideBarTheme: state.Layout.leftSideBarTheme
    }))

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (loading) {
        return (
            <div
                style={{ height: '100vh' }}
                className='d-flex  justify-content-center align-items-center'
            >
                <Loader color='primary' />
            </div>
        )
    }

    if (admin.role === 'club_clerk' && _.size(admin.assessMenuList)) {
        if (!admin.assessMenuList.includes(path)) {
            return <Navigate to='/dashboard' />
        }
    }
    return (
        <div>
            <LoadingBar
                style={{ height: '3px', zIndex: 1999, position: 'fixed' }}
                className='progress-bar-striped progress-bar-animated bg-info'
                role='progressbar'
            />
            <Header />
            <Toast />

            {/* <ToastContainer /> */}
            <Sidebar
                theme={leftSideBarTheme}
                // type={leftSideBarType}
                isMobile={isMobile}
            />
            <div className='main-content'>
                <div className='page-content'>
                    <Container fluid>{props.children}</Container>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Layout
