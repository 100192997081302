import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    AddPlanPaymentApi,
    ClubPlansListApi,
    PlansDetailApi,
    SuccessPlanPaymentApi
} from './ClubPlans.services'
import { paginationData } from '../Pagination/reducers'

//services
export const ClubPlansList = createAsyncThunk(
    'club/Plans/List',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await ClubPlansListApi(data)

            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const PlanDetail = createAsyncThunk('/Plans/detail', async (data, { rejectWithValue }) => {
    try {
        const res = await PlansDetailApi(data)

        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})
export const AddPlanPayment = createAsyncThunk(
    '/Add/Plans/Payment',
    async (data, { rejectWithValue }) => {
        try {
            const res = await AddPlanPaymentApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SuccessPlanPayment = createAsyncThunk(
    '/Plans/Payment/Success',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SuccessPlanPaymentApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
