import { createAsyncThunk } from '@reduxjs/toolkit'
import * as MemberShipIDConfig from './SanctionIDConfiguration.services'

export const SanctionIDConfigurationList = createAsyncThunk(
    '/@sanction/id/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MemberShipIDConfig.SanctionIDConfigurationListApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SanctionIDConfigurationCreate = createAsyncThunk(
    '/@sanction/id/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MemberShipIDConfig.SanctionIDConfigurationCreateApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SanctionIDConfigurationUpdate = createAsyncThunk(
    '/@sanction/id/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MemberShipIDConfig.SanctionIDConfigurationUpdateApi(
                data?.sanction_id_config_id,
                data
            )

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
