import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const SanctionReminderLogsApi = (ID, data) => {
    return API.get(`${URL.GET_ALL_SANCTION_REMINDER_LOG}/${ID}`, data)
}
export const SanctionReminderLogsUserListApi = (ID) => {
    return API.get(`${URL.GET_ALL_SANCTION_REMINDER_USER_LIST}/${ID}`)
}
export const SanctionReminderListLogsApi = (data) =>
    API.get(`${URL.GET_ALL_SANCTION_REMINDER_LIST_LOG}`, data)

//

export const SanctionReminderTemplateListApi = (data) =>
    API.get(`${URL.SANCTION_REMINDER_TEMPLATE_LIST}`, data)
export const SanctionReminderTemplateCreateApi = (data) =>
    API.post(`${URL.SANCTION_REMINDER_TEMPLATE_CREATE}`, data)
export const SanctionReminderTemplateUpdateApi = (ID, data) =>
    API.patch(`${URL.SANCTION_REMINDER_TEMPLATE_UPDATE}/${ID}`, data)
export const SanctionReminderTemplateDeleteApi = (ID) =>
    API.del(`${URL.SANCTION_REMINDER_TEMPLATE_DELETE}/${ID}`)

export const SanctionReminderSetupListApi = (data) =>
    API.get(`${URL.SANCTION_REMINDER_SETUP_LIST}`, data)
export const SanctionReminderSetupCreateApi = (data) =>
    API.post(`${URL.SANCTION_REMINDER_SETUP_CREATE}`, data)
export const SanctionReminderSetupUpdateApi = (ID, data) =>
    API.patch(`${URL.SANCTION_REMINDER_SETUP_UPDATE}/${ID}`, data)
export const SanctionReminderSetupDeleteApi = (ID) =>
    API.del(`${URL.SANCTION_REMINDER_SETUP_DELETE}/${ID}`)
export const SanctionReminderSetupOneApi = (ID) =>
    API.get(`${URL.SANCTION_REMINDER_SETUP_ONE}/${ID}`)
