/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit'
import {
    ContestBreedDetail,
    ContestBreedList,
    ContestBreedListContestDetail,
    GetOverallReportList,
    SweepStakePlacingListForContest,
    contestDetailForNational,
    getAllContest,
    getOneContest
} from './thunk'
import _ from 'lodash'
// import _ from 'lodash'

export const BREED_CATEGORY_TYPE = [
    {
        breed_category_id: 1,
        breed_category_name: 'Class',
        breed_category_abbr: 'class',
        isSelected: false,
        placing_list: []
    },
    {
        breed_category_id: 2,
        breed_category_name: 'Variety',
        breed_category_abbr: 'variety',
        isSelected: false,
        placing_list: []
    },
    {
        breed_category_id: 3,
        breed_category_name: 'Group',
        breed_category_abbr: 'group',
        isSelected: false,
        placing_list: []
    },
    {
        breed_category_id: 4,
        breed_category_name: 'Breed',
        breed_category_abbr: 'breed',
        isSelected: false,
        placing_list: []
    },
    {
        breed_category_id: 5,
        breed_category_name: 'Other',
        breed_category_abbr: 'other',
        isSelected: false,
        placing_list: []
    },
    {
        breed_category_id: 6,
        breed_category_name: 'Fur/Wool',
        breed_category_abbr: 'fur',
        isSelected: false,
        placing_list: []
    }
]

export const Form = {
    contest_name: '',
    start_date: null,
    end_date: null,
    seprate_variety_group: '1',
    points_breakdown: '1',
    contest_type: 'open',
    breed_list: [],
    gv_range_till: '',
    pb_range_till: '',
    breed_category_type: BREED_CATEGORY_TYPE,
    variety_error: '',
    group_error: '',
    breed_error: '',
    other_error: '',
    fur_error: '',
    rabbit_error: '',
    cavy_error: '',
    exhibition_error: '',
    commercial_error: '',
    variety_placing_category_type_error: '',
    breed_placing_category_type_error: '',
    group_placing_category_type_error: ''
}

export const initialState = {
    breed_list: [],
    breedListLoading: true,
    breedDetailLoading: false,
    breedDetail: {},
    form: Form,
    contestDetail: {},
    contestLoading: false,
    breedCategories: [],
    breedCategoryLoading: true,
    breeds: [],
    breedLoading: true,
    loading: true,
    list: [],
    overallReport: []
}

const Contest = createSlice({
    name: '@Contest',
    initialState,
    reducers: {
        ChangeBreed: (state, action) => {
            const { payload } = action
            state.breed_list = state.breed_list.map((b) => {
                if (b.breed_id === payload.breedId) {
                    return {
                        ...b,
                        isActive: true
                    }
                }
                return {
                    ...b,
                    isActive: false
                }
            })
        },
        resetContestBreedDetail: (state) => {
            state.breedDetail = {}
        }
    },
    extraReducers: (builder) => {
        builder.addCase(contestDetailForNational.pending, (state) => {
            state.loading = true
        })
        builder.addCase(contestDetailForNational.fulfilled, (state, action) => {
            const { payload } = action

            state.form.breed_list = payload
            state.loading = false

            // }
        })
        builder.addCase(contestDetailForNational.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(ContestBreedList.pending, (state) => {
            state.breedCategoryLoading = true
            state.breedListLoading = true
        })
        builder.addCase(ContestBreedList.fulfilled, (state, action) => {
            const { payload } = action
            state.breedListLoading = false
            state.breedCategories = payload
            state.breed_list = payload
            state.breedCategoryLoading = false

            // }
        })
        builder.addCase(ContestBreedList.rejected, (state) => {
            state.breedCategoryLoading = false
            state.breedListLoading = false
        })
        builder.addCase(ContestBreedDetail.pending, (state) => {
            state.breedDetailLoading = true
            state.breedDetail = {}
        })
        builder.addCase(ContestBreedDetail.fulfilled, (state, action) => {
            state.sweepStakeAllPlacingPointsLoading = true
            const { payload } = action
            state.breedDetail = _.first(payload?.data) || {}
            state.breedDetailLoading = false

            // }
        })
        builder.addCase(ContestBreedListContestDetail.pending, (state) => {
            state.breedCategoryLoading = true
        })
        builder.addCase(ContestBreedListContestDetail.fulfilled, (state, action) => {
            const { payload } = action
            state.breedCategories = payload
            state.breedCategoryLoading = false

            // }
        })
        builder.addCase(ContestBreedListContestDetail.rejected, (state) => {
            state.breedCategoryLoading = false
        })
        builder.addCase(ContestBreedDetail.rejected, (state) => {
            state.breedDetailLoading = false
            state.breedDetail = {}
        })
        builder.addCase(getAllContest.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAllContest.fulfilled, (state, action) => {
            const { data } = action.payload
            state.list = data
            state.loading = false
        })
        builder.addCase(getAllContest.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(GetOverallReportList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(GetOverallReportList.fulfilled, (state, action) => {
            const { payload } = action
            state.overallReport = payload
            state.loading = false
        })
        builder.addCase(GetOverallReportList.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(SweepStakePlacingListForContest.pending, (state) => {
            state.placingData = {}
            state.placingLoading = true
        })
        builder.addCase(SweepStakePlacingListForContest.fulfilled, (state, action) => {
            const { payload } = action
            let {
                best_class_type: BEST_CLASS_TYPE,
                show: SHOW,
                show_cavy: SHOW_CAVY
            } = _.groupBy(
                _.filter(
                    payload?.other,
                    (f) => !(f.step === 'best_group' || f.step === 'show_cavy_runner_up')
                ),
                'step'
            )
            BEST_CLASS_TYPE = _.map(
                [
                    _.join(
                        _.map(BEST_CLASS_TYPE, (t) => t.placing),
                        '/'
                    )
                ],
                (t) => ({
                    placing:
                        payload?.reportType === 'exhibitor_list_by_point' ? `Best Class (${t})` : t,
                    isSelected: false
                })
            )
            SHOW = _.map(
                [
                    _.join(
                        _.map(SHOW, (t) => t.placing),
                        '/'
                    )
                ],
                (t) => ({
                    placing:
                        payload?.reportType === 'exhibitor_list_by_point' ? `Best Show (${t})` : t,
                    isSelected: false
                })
            )
            SHOW_CAVY = _.map(
                [
                    _.join(
                        _.map(SHOW_CAVY, (t) => t.placing),
                        '/'
                    )
                ],
                (t) => ({
                    placing:
                        payload?.reportType === 'exhibitor_list_by_point' ? `Best Cavy (${t})` : t,
                    isSelected: false
                })
            )
            const other = _.concat(BEST_CLASS_TYPE, SHOW, SHOW_CAVY)
            state.form =
                payload?.reportType === 'detail_by_breed'
                    ? {
                          contest_name: '',
                          start_date: null,
                          end_date: null,
                          seprate_variety_group: '1',
                          points_breakdown: '1',
                          contest_type: 'open',
                          breed_list: state.form.breed_list,
                          gv_range_till: '',
                          pb_range_till: '',
                          breed_category_type: [
                              {
                                  breed_category_id: 1,
                                  breed_category_name: 'Class',
                                  breed_category_abbr: 'class',
                                  isSelected: false,

                                  placing_list:
                                      payload?.class?.map((p) => ({
                                          placing: p,
                                          isSelected: false
                                      })) || []
                              },
                              {
                                  breed_category_id: 2,
                                  breed_category_name: 'Variety',
                                  breed_category_abbr: 'variety',
                                  isSelected: false,
                                  placingCategoryType: '',
                                  placing_list:
                                      payload?.reportType === 'exhibitor_list_by_point'
                                          ? _.map([_.join(payload?.variety, '/')], (v) => ({
                                                placing: v,
                                                isSelected: false
                                            }))
                                          : payload?.variety?.map((p) => ({
                                                placing: p,
                                                isSelected: false
                                            }))
                              },
                              {
                                  breed_category_id: 3,
                                  breed_category_name: 'Group',
                                  breed_category_abbr: 'group',
                                  isSelected: false,
                                  placingCategoryType: '',
                                  placing_list:
                                      payload?.reportType === 'exhibitor_list_by_point'
                                          ? _.map([_.join(payload?.group, '/')], (v) => ({
                                                placing: v,
                                                isSelected: false
                                            }))
                                          : payload?.group?.map((p) => ({
                                                placing: p,
                                                isSelected: false
                                            }))
                              },
                              {
                                  breed_category_id: 4,
                                  breed_category_name: 'Breed',
                                  breed_category_abbr: 'breed',
                                  isSelected: false,
                                  placingCategoryType: '',
                                  placing_list:
                                      payload?.reportType === 'exhibitor_list_by_point'
                                          ? _.map([_.join(payload?.breed, '/')], (v) => ({
                                                placing: v,
                                                isSelected: false
                                            }))
                                          : payload?.breed?.map((p) => ({
                                                placing: p,
                                                isSelected: false
                                            }))
                              },
                              {
                                  breed_category_id: 5,
                                  breed_category_name: 'Other',
                                  breed_category_abbr: 'other',
                                  isSelected: false,
                                  placing_list:
                                      payload?.reportType === 'exhibitor_list_by_point'
                                          ? other
                                          : payload?.other
                              },
                              {
                                  breed_category_id: 6,
                                  breed_category_name: 'Fur/Wool',
                                  breed_category_abbr: 'fur',
                                  isSelected: false,
                                  placing_list:
                                      payload?.reportType === 'exhibitor_list_by_point'
                                          ? _.map(
                                                [
                                                    _.join(
                                                        _.filter(
                                                            payload?.best_fur,
                                                            (f) => !['BFOB'].includes(f)
                                                        ),
                                                        '/'
                                                    )
                                                ],
                                                (v) => ({ placing: v, isSelected: false })
                                            )
                                          : _.map(
                                                _.filter(
                                                    payload?.best_fur,
                                                    (f) => !['BFOB'].includes(f)
                                                ),
                                                (fur) => ({ placing: fur, isSelected: false })
                                            )
                                  //     payload?.best_fur?.map((p)=>
                                  //     ({placing: p,
                                  //     isSelected:false})
                                  // )
                              }
                          ],
                          variety_error: '',
                          group_error: '',
                          breed_error: '',
                          other_error: '',
                          fur_error: '',
                          rabbit_error: '',
                          cavy_error: '',
                          exhibition_error: '',
                          commercial_error: '',
                          variety_placing_category_type_error: '',
                          breed_placing_category_type_error: '',
                          group_placing_category_type_error: ''
                      }
                    : {
                          contest_name: '',
                          start_date: null,
                          end_date: null,
                          seprate_variety_group: '1',
                          points_breakdown: '1',
                          contest_type: 'open',
                          breed_list: state.form.breed_list,
                          gv_range_till: '',
                          pb_range_till: '',
                          breed_category_type: [
                              {
                                  breed_category_id: 1,
                                  breed_category_name: 'Class',
                                  breed_category_abbr: 'class',
                                  isSelected: false,

                                  placing_list:
                                      payload?.reportType === 'exhibitor_list_by_point'
                                          ? [
                                                {
                                                    value: 'class',
                                                    placing: 'Class',
                                                    isSelected: false
                                                }
                                            ]
                                          : payload?.class?.map((p) => ({
                                                placing: p,
                                                isSelected: false
                                            })) || []
                              },
                              {
                                  breed_category_id: 2,
                                  breed_category_name: 'Variety',
                                  breed_category_abbr: 'variety',
                                  isSelected: false,
                                  placingCategoryType: '',
                                  placing_list:
                                      payload?.reportType === 'exhibitor_list_by_point'
                                          ? _.map([_.join(payload?.variety, '/')], (v) => ({
                                                value: 'variety',
                                                placing: `Variety (${v})`,
                                                isSelected: false
                                            }))
                                          : payload?.variety?.map((p) => ({
                                                placing: p,
                                                isSelected: false
                                            }))
                              },
                              {
                                  breed_category_id: 3,
                                  breed_category_name: 'Group',
                                  breed_category_abbr: 'group',
                                  isSelected: false,
                                  placingCategoryType: '',
                                  placing_list:
                                      payload?.reportType === 'exhibitor_list_by_point'
                                          ? _.map([_.join(payload?.group, '/')], (v) => ({
                                                value: 'group',
                                                placing: `Group (${v})`,
                                                isSelected: false
                                            }))
                                          : payload?.group?.map((p) => ({
                                                placing: p,
                                                isSelected: false
                                            }))
                              },
                              {
                                  breed_category_id: 4,
                                  breed_category_name: 'Breed',
                                  breed_category_abbr: 'breed',
                                  isSelected: false,
                                  placingCategoryType: '',
                                  placing_list:
                                      payload?.reportType === 'exhibitor_list_by_point'
                                          ? _.map([_.join(payload?.breed, '/')], (v) => ({
                                                value: 'breed',
                                                placing: `Breed (${v})`,
                                                isSelected: false
                                            }))
                                          : payload?.breed?.map((p) => ({
                                                placing: p,
                                                isSelected: false
                                            }))
                              },
                              {
                                  breed_category_id: 5,
                                  breed_category_name: 'Best Class',
                                  breed_category_abbr: 'best_class_type',
                                  isSelected: false,
                                  placing_list: BEST_CLASS_TYPE
                              },
                              {
                                  breed_category_id: 6,
                                  breed_category_name: 'Best Show',
                                  breed_category_abbr: 'show',
                                  isSelected: false,
                                  placing_list: SHOW
                              },
                              {
                                  breed_category_id: 7,
                                  breed_category_name: 'Best Cavy',
                                  breed_category_abbr: 'show_cavy',
                                  isSelected: false,
                                  placing_list: SHOW_CAVY
                              },
                              {
                                  breed_category_id: 8,
                                  breed_category_name: 'Fur/Wool',
                                  breed_category_abbr: 'fur',
                                  isSelected: false,
                                  placing_list:
                                      payload?.reportType === 'exhibitor_list_by_point'
                                          ? _.map(
                                                [
                                                    _.join(
                                                        _.filter(
                                                            payload?.best_fur,
                                                            (f) => !['BFOB'].includes(f)
                                                        ),
                                                        '/'
                                                    )
                                                ],
                                                (v) => ({
                                                    value: 'fur',
                                                    placing: `Fur/Wool (${v})`,
                                                    isSelected: false
                                                })
                                            )
                                          : _.map(
                                                _.filter(
                                                    payload?.best_fur,
                                                    (f) => !['BFOB'].includes(f)
                                                ),
                                                (fur) => ({ placing: fur, isSelected: false })
                                            )
                              }
                          ],
                          variety_error: '',
                          group_error: '',
                          breed_error: '',
                          other_error: '',
                          fur_error: '',
                          rabbit_error: '',
                          cavy_error: '',
                          exhibition_error: '',
                          commercial_error: '',
                          variety_placing_category_type_error: '',
                          breed_placing_category_type_error: '',
                          group_placing_category_type_error: ''
                      }
            state.placingData = payload
            state.placingLoading = false

            // }
        })
        builder.addCase(SweepStakePlacingListForContest.rejected, (state) => {
            state.placingData = {}
            state.placingLoading = false
        })
        builder.addCase(getOneContest.pending, (state) => {
            state.contestDetail = {}
            state.contestLoading = true
        })
        builder.addCase(getOneContest.fulfilled, (state, action) => {
            const { payload } = action
            state.contestDetail = _.first(payload?.data) || {}
            state.contestLoading = false
        })
        builder.addCase(getOneContest.rejected, (state) => {
            state.contestDetail = {}
            state.contestLoading = false
        })
    }
})

export const { ChangeBreed, resetContestBreedDetail } = Contest.actions

export default Contest.reducer
