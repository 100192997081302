import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    MembershipProcessFeesTemplateCreateApi,
    MembershipProcessFeesTemplateListApi,
    MembershipProcessFeesTemplateUpdateApi
} from './MembershipProcessingFees.services'

export const MemberShipProcessFeeCreate = createAsyncThunk(
    '/membership/process/fees/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipProcessFeesTemplateCreateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MemberShipProcessFeeUpdate = createAsyncThunk(
    '/membership/process/fees/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipProcessFeesTemplateUpdateApi(
                data.membership_percentage_id,
                data
            )
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MemberShipProcessFeeList = createAsyncThunk(
    '/membership/process/fees/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipProcessFeesTemplateListApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
