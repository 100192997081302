import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

// Auth
const Profile = lazy(() => import('../pages/Profile/Profile'))
const ChangePassword = lazy(() => import('../pages/ChangePassword/ChangePassword'))

const Dashboard = lazy(() => import('../pages/Dashboard/index'))

// Plan Purchase
const ClubPlanPurchased = lazy(() => import('src/pages/PlanPurchased/Index'))
const PlanPurchaseCheckout = lazy(() => import('src/pages/Plans/PlanPurchaseCheckout'))
const PlanPurchaseSummary = lazy(() => import('src/pages/Plans/PaymentSummary'))

// Club Plans
const ClubPlan = lazy(() => import('src/pages/Plans/Plans'))

// Membership ID Configuration
const MemberShipIDConfiguration = lazy(() =>
    import('src/pages/MemberShip/IDConfiguration/IDConfiguration')
)

// Age Classification
const AgeClassification = lazy(() => import('src/pages/MemberShip/AgeClassification'))
const AgeClassificationForm = lazy(() => import('src/pages/MemberShip/AgeClassification/Form'))

// MemberShip Plans
const MemberShipPlan = lazy(() => import('src/pages/MemberShip/MembershipPlans'))
const MemberShipPlanForm = lazy(() => import('src/pages/MemberShip/MembershipPlans/Form'))

// Membership List
const MembershipList = lazy(() => import('src/pages/MemberShip/MembershipList/MembershipList'))
const MembershipFrom = lazy(() => import('src/pages/MemberShip/MembershipList/Form'))
// const MemberShipPlanForm = lazy(() => import('src/pages/MemberShip/MembershipPlans/Form'))

// Letter Template
const LetterTemplate = lazy(() => import('src/pages/MemberShip/LetterTemplate'))
// Renew Letter Template
const RenewLetterTemplate = lazy(() => import('src/pages/MemberShip/RenewLetterTemplate'))

// Membership Card Template
const MembershipCardTemplate = lazy(() => import('src/pages/MemberShip/MembershipCardTemplate'))

/// MemberShip Reminder
const MemberShipReminderListLog = lazy(() =>
    import('src/pages/MemberShip/Reminder/Log/ReminderList')
)
const MemberShipReminderForUserLog = lazy(() => import('src/pages/MemberShip/Reminder/Log/Log'))
const MemberShipReminderSetupForm = lazy(() => import('src/pages/MemberShip/Reminder/Setup/Form'))
const MemberShipReminderSetup = lazy(() => import('src/pages/MemberShip/Reminder/Setup/Setup'))
const MemberShipReminderTemplate = lazy(() =>
    import('src/pages/MemberShip/Reminder/Template/Template')
)

const ProcessingFees = lazy(() => import('src/pages/MemberShip/ProcessingFess'))

// Users
const UserList = lazy(() => import('src/pages/MemberShip/UserLIst/index'))

const SubUserList = lazy(() => import('src/pages/MemberShip/SubUserList'))

const UserForm = lazy(() => import('src/pages/MemberShip/UserLIst/Form'))
const AliasUserForm = lazy(() => import('src/pages/MemberShip/UserLIst/Alias/Form'))

const SubUserForm = lazy(() => import('src/pages/MemberShip/SubUserList/Form'))

const MemberShipCheckout = lazy(() => import('src/pages/MemberShip/MembershipList/Checkout'))
const MemberShipPaymentProcess = lazy(() =>
    import('src/pages/MemberShip/MembershipList/PaymentProcess')
)
const MembershipReport = lazy(() =>
    import('src/pages/MemberShip/MembershipReport/MembershipReport')
)
// const ClassSweepStack = lazy(() => import('src/pages/Sweepstake/Class/ClassSweepStack'))

//Connect Account
const Account = lazy(() => import('src/pages/Account/Account'))

// Sweepstake
const ClassSweepStakeNational = lazy(() =>
    import('src/pages/Sweepstake/SweepstakePoint/Class/National/ClassSweepStack')
)
const ClassSweepStakeNationalFrom = lazy(() =>
    import('src/pages/Sweepstake/SweepstakePoint/Class/National/Form')
)
const ClassSweepStakeNonNational = lazy(() =>
    import('src/pages/Sweepstake/SweepstakePoint/Class/Non-National/ClassSweepStack')
)
const ClassSweepStakeNonNationalFrom = lazy(() =>
    import('src/pages/Sweepstake/SweepstakePoint/Class/Non-National/Form')
)

const FurSweepStakeNational = lazy(() =>
    import('src/pages/Sweepstake/SweepstakePoint/Fur/National/ClassSweepStack')
)
const FurSweepStakeNationalFrom = lazy(() =>
    import('src/pages/Sweepstake/SweepstakePoint/Fur/National/Form')
)
const FurSweepStakeNonNational = lazy(() =>
    import('src/pages/Sweepstake/SweepstakePoint/Fur/Non-National/FurSweepStack')
)
const FurSweepStakeNonNationalFrom = lazy(() =>
    import('src/pages/Sweepstake/SweepstakePoint/Fur/Non-National/Form')
)

// Nation
const NationVariety = lazy(() => import('src/pages/Sweepstake/SweepstakePoint/Variety/Nation'))
const NationVarietyForm = lazy(() =>
    import('src/pages/Sweepstake/SweepstakePoint/Variety/Nation/Form')
)

const NationGroup = lazy(() => import('src/pages/Sweepstake/SweepstakePoint/Group/Nation'))
const NationGroupForm = lazy(() => import('src/pages/Sweepstake/SweepstakePoint/Group/Nation/Form'))

const NationBreed = lazy(() => import('src/pages/Sweepstake/SweepstakePoint/Breed/Nation'))
const NationBreedForm = lazy(() => import('src/pages/Sweepstake/SweepstakePoint/Breed/Nation/Form'))

const NationOther = lazy(() => import('src/pages/Sweepstake/SweepstakePoint/Other/Nation'))
const NationOtherForm = lazy(() => import('src/pages/Sweepstake/SweepstakePoint/Other/Nation/Form'))

// Non-Nation

const NonNationVariety = lazy(() =>
    import('src/pages/Sweepstake/SweepstakePoint/Variety/NonNation')
)
const NonNationVarietyForm = lazy(() =>
    import('src/pages/Sweepstake/SweepstakePoint/Variety/NonNation/Form')
)

const NonNationGroup = lazy(() => import('src/pages/Sweepstake/SweepstakePoint/Group/NonNation'))
const NonNationGroupForm = lazy(() =>
    import('src/pages/Sweepstake/SweepstakePoint/Group/NonNation/Form')
)

const NonNationBreed = lazy(() => import('src/pages/Sweepstake/SweepstakePoint/Breed/NonNation'))
const NonNationBreedForm = lazy(() =>
    import('src/pages/Sweepstake/SweepstakePoint/Breed/NonNation/Form')
)

const NonNationOther = lazy(() => import('src/pages/Sweepstake/SweepstakePoint/Other/NonNation'))
const NonNationOtherForm = lazy(() =>
    import('src/pages/Sweepstake/SweepstakePoint/Other/NonNation/Form')
)

// Show Setup
const ShowSetup = lazy(() => import('src/pages/Sweepstake/ShowSetup/ShowSetup'))
const ShowSetupForm = lazy(() => import('src/pages/Sweepstake/ShowSetup/Form'))
// Judge
const Judge = lazy(() => import('src/pages/Sweepstake/ShowSetup/Judge/Judge'))
const JudgeForm = lazy(() => import('src/pages/Sweepstake/ShowSetup/Judge/Form'))

// Members

const Members = lazy(() => import('src/pages/Sweepstake/ShowSetup/Exhibitor'))
const MembersForm = lazy(() => import('src/pages/Sweepstake/ShowSetup/Exhibitor/Form'))
// Control Sheet
const ControlSheet = lazy(() => import('src/pages/Sweepstake/ShowSetup/ControlSheet/Form'))
const NationalReportList = lazy(() =>
    import('src/pages/Sweepstake/ShowSetup/ControlSheet/NationalReport')
)
const StateControlSheet = lazy(() => import('src/pages/Sweepstake/ShowSetup/StateControlSheet'))
const StateControlSheetForm = lazy(() =>
    import('src/pages/Sweepstake/ShowSetup/StateControlSheet/Form')
)

// Setting Club
// const NationalClub = lazy(() => import('src/pages/Setting/NationalClub'))
// const StateClub = lazy(() => import('src/pages/Setting/StateClub'))
const Setting = lazy(() => import('src/pages/Setting/Setting'))
const ContestForm = lazy(() => import('src/pages/Contest/Form'))
const Contest = lazy(() => import('src/pages/Contest/Contest'))
const ContestReport = lazy(() => import('src/pages/Contest/ContestReport/index'))
const ContestReportList = lazy(() => import('src/pages/Contest/ContestReport/ContestReportList'))
const ContestVarietyReportPDF = lazy(() =>
    import('src/pages/Contest/Pdf/VarietyReport/VarietyReport')
)

// Report Type
const ReportType = lazy(() => import('src/pages/Setting/ReportType/ReportType'))

// const

// Sanction ID Configuration
const SanctionIdConfiguration = lazy(() =>
    import('src/pages/Sanction/IDConfiguration/IDConfiguration')
)
const ShowClassificationList = lazy(() => import('src/pages/Sanction/ShowClassification/index'))
const ShowClassificationForm = lazy(() => import('src/pages/Sanction/ShowClassification/Form'))
const SanctionLetterTemplate = lazy(() => import('src/pages/Sanction/LetterTemplate/index'))
const SanctionCardTemplate = lazy(() => import('src/pages/Sanction/SanctionCardTemplate/index'))
const SanctionProcessingFess = lazy(() => import('src/pages/Sanction/ProcessingFess/index'))

const SanctionReminderForUserLog = lazy(() => import('src/pages/Sanction/Reminder/Log/Log'))
const SanctionReminderSetupForm = lazy(() => import('src/pages/Sanction/Reminder/Setup/Form'))
const SanctionReminderSetup = lazy(() => import('src/pages/Sanction/Reminder/Setup/Setup'))
const SanctionReminderTemplate = lazy(() => import('src/pages/Sanction/Reminder/Template/Template'))
const SanctionReminderListLog = lazy(() => import('src/pages/Sanction/Reminder/Log/ReminderList'))

const SanctionPlans = lazy(() => import('src/pages/Sanction/SanctionPlans/SanctionPlans'))
const SanctionPlanForm = lazy(() => import('src/pages/Sanction/SanctionPlans/Form'))

// Sanction List
const SanctionList = lazy(() => import('src/pages/Sanction/SanctionList/SanctionList'))
const SanctionFrom = lazy(() => import('src/pages/Sanction/SanctionList/Form'))
const SanctionPlan = lazy(() => import('src/pages/Sanction/SanctionList/Plan/Plan'))
const SanctionCheckout = lazy(() =>
    import('src/pages/Sanction/SanctionList/SanctionCheckout/SanctionCheckout')
)
const SanctionPaymentProcess = lazy(() =>
    import('src/pages/Sanction/SanctionList/PaymentProcess/PaymentProcess')
)

const Clerk = lazy(() => import('src/pages/Clerk'))
const ClerkForm = lazy(() => import('src/pages/Clerk/Form'))

const NewsLetter = lazy(() => import('src/pages/MemberShip/NewsLetter'))
const NewsLetterForm = lazy(() => import('src/pages/MemberShip/NewsLetter/Form'))

const clubRoutes = [
    // { path: '/dashboard', component: <Dashboard />, name: 'Dashboard' },
    { path: '/dashboard/plans', component: <ClubPlan />, name: 'Purchase Plan' },
    {
        path: '/dashboard/plans/:ID/checkout',
        component: <PlanPurchaseSummary />,
        name: 'Payment Detail'
    },
    {
        path: '/dashboard/plans/:ID/checkout/payment',
        component: <PlanPurchaseCheckout />,
        name: 'Checkout'
    },
    {
        path: '/dashboard/club_plan_purchased',
        component: <ClubPlanPurchased />,
        name: 'Club Plan Purchased'
    },
    {
        path: '/dashboard/contest',
        component: <Contest />,
        name: `Contest's`
    },
    {
        path: '/dashboard/contest/create',
        component: <ContestForm />,
        name: `Contest's From`
    },
    {
        path: '/dashboard/contest/update/:ID',
        component: <ContestForm />,
        name: `Contest's From`
    },
    {
        path: '/dashboard/contest/:ID/reports/:abbr',
        component: <ContestReport />,
        name: 'Report'
    },
    {
        path: '/dashboard/contest/:ID/reports',
        component: <ContestReportList />,
        name: 'Report'
    },
    {
        path: '/dashboard/contest/:ID/reports/pdf/variety-report',
        component: <ContestVarietyReportPDF />,
        name: 'Variety Report'
    },

    {
        path: '/dashboard/account_setup',
        component: <Account />,
        name: 'Stripe'
    },
    // {
    //     path: '/dashboard/sweepstake/class/:type',
    //     component: <ClassSweepStack />,
    //     name: 'Stripe'
    // },

    {
        path: '/dashboard/change-password',
        component: <ChangePassword />,
        id: '120',
        name: 'Change Password'
    },

    // Sweepstake
    {
        path: '/dashboard/sweepstake-class-national',
        component: <ClassSweepStakeNational />,
        name: 'Class National List'
    },
    {
        path: '/dashboard/sweepstake-class-national/create',
        component: <ClassSweepStakeNationalFrom />,
        name: 'Create'
    },
    {
        path: '/dashboard/sweepstake-class-national/update/:ID',
        component: <ClassSweepStakeNationalFrom />,
        name: 'Update'
    },
    {
        path: '/dashboard/sweepstake-class-non-national',
        component: <ClassSweepStakeNonNational />,
        name: 'Class Non-National List'
    },
    {
        path: '/dashboard/sweepstake-class-non-national/create',
        component: <ClassSweepStakeNonNationalFrom />,
        name: 'Create'
    },
    {
        path: '/dashboard/sweepstake-class-non-national/update/:ID',
        component: <ClassSweepStakeNonNationalFrom />,
        name: 'Update'
    },
    //

    {
        path: '/dashboard/sweepstake-fur-national',
        component: <FurSweepStakeNational />,
        name: 'Fur National List'
    },
    {
        path: '/dashboard/sweepstake-fur-national/create',
        component: <FurSweepStakeNationalFrom />,
        name: 'Create'
    },
    {
        path: '/dashboard/sweepstake-fur-national/update/:ID',
        component: <FurSweepStakeNationalFrom />,
        name: 'Update'
    },
    {
        path: '/dashboard/sweepstake-fur-non-national',
        component: <FurSweepStakeNonNational />,
        name: 'Fur Non-National List'
    },
    {
        path: '/dashboard/sweepstake-fur-non-national/create',
        component: <FurSweepStakeNonNationalFrom />,
        name: 'Create'
    },
    {
        path: '/dashboard/sweepstake-fur-non-national/update/:ID',
        component: <FurSweepStakeNonNationalFrom />,
        name: 'Update'
    },

    // Nation Sweeptakes

    // Variety

    {
        path: '/dashboard/sweepstake-variety-national',
        component: <NationVariety />,
        name: 'Variety National List'
    },
    {
        path: '/dashboard/sweepstake-variety-national/create',
        component: <NationVarietyForm />,
        name: 'Create'
    },
    {
        path: '/dashboard/sweepstake-variety-national/update/:ID',
        component: <NationVarietyForm />,
        name: 'Update'
    },
    // Group

    {
        path: '/dashboard/sweepstake-group-national',
        component: <NationGroup />,
        name: 'Group National List'
    },
    {
        path: '/dashboard/sweepstake-group-national/create',
        component: <NationGroupForm />,
        name: 'Create'
    },
    {
        path: '/dashboard/sweepstake-group-national/update/:ID',
        component: <NationGroupForm />,
        name: 'Update'
    },
    // Breed
    {
        path: '/dashboard/sweepstake-breed-national',
        component: <NationBreed />,
        name: 'Breed National List'
    },
    {
        path: '/dashboard/sweepstake-breed-national/create',
        component: <NationBreedForm />,
        name: 'Create'
    },
    {
        path: '/dashboard/sweepstake-breed-national/update/:ID',
        component: <NationBreedForm />,
        name: 'Update'
    },

    // Other

    {
        path: '/dashboard/sweepstake-other-national',
        component: <NationOther />,
        name: 'Other National List'
    },
    {
        path: '/dashboard/sweepstake-other-national/create',
        component: <NationOtherForm />,
        name: 'Create'
    },
    {
        path: '/dashboard/sweepstake-other-national/update/:ID',
        component: <NationOtherForm />,
        name: 'Update'
    },

    // Non-Nation Sweeptakes

    // Variety

    {
        path: '/dashboard/sweepstake-variety-non-national',
        component: <NonNationVariety />,
        name: 'Variety Non-National List'
    },
    {
        path: '/dashboard/sweepstake-variety-non-national/create',
        component: <NonNationVarietyForm />,
        name: 'Create'
    },
    {
        path: '/dashboard/sweepstake-variety-non-national/update/:ID',
        component: <NonNationVarietyForm />,
        name: 'Update'
    },
    // Group

    {
        path: '/dashboard/sweepstake-group-non-national',
        component: <NonNationGroup />,
        name: 'Group Non-National List'
    },
    {
        path: '/dashboard/sweepstake-group-non-national/create',
        component: <NonNationGroupForm />,
        name: 'Create'
    },
    {
        path: '/dashboard/sweepstake-group-non-national/update/:ID',
        component: <NonNationGroupForm />,
        name: 'Update'
    },
    // Breed
    {
        path: '/dashboard/sweepstake-breed-non-national',
        component: <NonNationBreed />,
        name: 'Breed Non-National List'
    },
    {
        path: '/dashboard/sweepstake-breed-non-national/create',
        component: <NonNationBreedForm />,
        name: 'Create'
    },
    {
        path: '/dashboard/sweepstake-breed-non-national/update/:ID',
        component: <NonNationBreedForm />,
        name: 'Update'
    },

    // Other

    {
        path: '/dashboard/sweepstake-other-non-national',
        component: <NonNationOther />,
        name: 'Other Non-National List'
    },
    {
        path: '/dashboard/sweepstake-other-non-national/create',
        component: <NonNationOtherForm />,
        name: 'Create'
    },
    {
        path: '/dashboard/sweepstake-other-non-national/update/:ID',
        component: <NonNationOtherForm />,
        name: 'Update'
    },

    // Show Setup
    // /dashboard/show-setup
    {
        path: '/dashboard/show-setup',
        component: <ShowSetup />,
        name: 'Show Setup'
    },
    {
        path: '/dashboard/show-setup/create',
        component: <ShowSetupForm />,
        name: 'Create'
    },
    {
        path: '/dashboard/show-setup/update/:ID',
        component: <ShowSetupForm />,
        name: 'Update'
    },

    // Show setup Judge
    {
        path: '/dashboard/show-setup/:SID/judge',
        component: <Judge />,
        name: 'Judge'
    },
    {
        path: '/dashboard/show-setup/:SID/judge/create',
        component: <JudgeForm />,
        name: 'Create'
    },
    {
        path: '/dashboard/show-setup/:SID/judge/update/:JID',
        component: <JudgeForm />,
        name: 'Update'
    },

    // Members

    {
        path: '/dashboard/show-setup/exhibitor/:showID',
        component: <Members />,
        name: 'Exhibitor'
    },
    {
        path: '/dashboard/show-setup/exhibitor/:showID/create',
        component: <MembersForm />,
        name: 'Create'
    },
    {
        path: '/dashboard/show-setup/exhibitor/:showID/update/:ID',
        component: <MembersForm />,
        name: 'Update'
    },

    // Setting
    // {
    //     path: '/dashboard/national-club',
    //     component: <NationalClub />,
    //     name: 'National Club'
    // },
    // {
    //     path: '/dashboard/state-club',
    //     component: <StateClub />,
    //     name: 'State Club'
    // },
    {
        path: '/dashboard/setting',
        component: <Setting />,
        name: 'Setting'
    },
    {
        path: '/dashboard/report-type',
        component: <ReportType />,
        name: 'Report Type'
    },

    // Control Sheet

    {
        path: '/dashboard/show-setup/:SID/national_controlsheet',
        component: <NationalReportList />,
        name: 'National Control Sheet'
    },
    {
        path: '/dashboard/show-setup/:SID/national_controlsheet/:reportType',
        component: <ControlSheet />,
        name: 'Report'
    },
    {
        path: '/dashboard/show-setup/:SID/state_controlsheet',
        component: <StateControlSheet />,
        name: 'State Control Sheet'
    },
    {
        path: '/dashboard/show-setup/:SID/state_controlsheet/:abbr',
        component: <StateControlSheetForm />,
        name: 'Report'
    },

    { path: '/dashboard/profile', component: <Profile />, name: 'Profile' }
]

const membershipRoutes = [
    // Club ID Membership Configuration
    { path: '/dashboard', component: <Dashboard />, name: 'Dashboard' },
    {
        path: '/dashboard/membership-id-configuration',
        component: <MemberShipIDConfiguration />,
        name: ' ID Configuration'
    },

    // Age Classification
    {
        path: '/dashboard/age_classification',
        component: <AgeClassification />,
        name: 'Age Classification'
    },
    {
        path: '/dashboard/age_classification/:ACID',
        component: <AgeClassificationForm />,
        name: 'Update'
    },
    {
        path: '/dashboard/age_classification/create',
        component: <AgeClassificationForm />,
        name: 'Create'
    },

    // Letter Template
    {
        path: '/dashboard/letter_template',
        component: <LetterTemplate />,
        name: 'Letter Template'
    },
    {
        path: '/dashboard/renew-letter-template',
        component: <RenewLetterTemplate />,
        name: 'Renew Letter Template'
    },
    // Membership Card Template
    {
        path: '/dashboard/membership_card_template',
        component: <MembershipCardTemplate />,
        // MEMBERSHIP CARD TEMPLATE

        name: 'Membership Card Template '
    },
    // Processing Fees
    {
        path: '/dashboard/processing_fees',
        component: <ProcessingFees />,
        name: 'Processing Fees'
    },

    // Membership Plans
    {
        path: '/dashboard/membership_plans',
        component: <MemberShipPlan />,
        name: 'Membership Plans'
    },

    {
        path: '/dashboard/membership_plans/create',
        component: <MemberShipPlanForm />,
        name: 'Create'
    },
    {
        path: '/dashboard/membership_plans/:ID',
        component: <MemberShipPlanForm />,
        name: 'Update'

        // Membership List
    },
    {
        path: '/dashboard/membership-list',
        component: <MembershipList />,
        name: 'Membership List'
    },
    {
        path: '/dashboard/membership-list/create',
        component: <MembershipFrom />,
        name: 'Create'
    },
    {
        path: '/dashboard/member_list',
        component: <UserList />,
        name: 'Member / Alias List'
    },
    {
        path: '/dashboard/member_list/create',
        component: <UserForm />,
        name: 'Form'
    },
    {
        path: '/dashboard/member_list/alias/create',
        component: <AliasUserForm />,
        name: 'Create'
    },
    {
        path: '/dashboard/member_list/:ID',
        component: <UserForm />,
        name: 'Form'
    },
    {
        path: '/dashboard/member_list/:UID/sub_member_list',
        component: <SubUserList />,
        name: 'Sub Members'
    },
    {
        path: '/dashboard/member_list/:UID/sub_member_list/create',
        component: <SubUserForm />,
        name: 'Form'
    },
    {
        path: '/dashboard/member_list/:UID/sub_member_list/update/:ID',
        component: <SubUserForm />,
        name: 'Form'
    },
    {
        path: '/dashboard/membership-list/checkout/',
        component: <MemberShipCheckout />,
        name: 'Checkout'
    },
    {
        path: '/dashboard/membership-list/checkout/process-payment',
        component: <MemberShipPaymentProcess />,
        name: 'Checkout'
    },

    {
        path: '/dashboard/membership-report',
        component: <MembershipReport />,
        name: 'Membership Report'
    },

    // Membership Reminder

    {
        path: '/dashboard/membership-reminder-setup',
        component: <MemberShipReminderSetup />,
        name: 'Reminder Setup'
    },
    {
        path: '/dashboard/membership-reminder-setup/create',
        component: <MemberShipReminderSetupForm />,
        name: 'Reminder Setup Form'
    },
    {
        path: '/dashboard/membership-reminder-setup/update/:ID',
        component: <MemberShipReminderSetupForm />,
        name: 'Reminder Setup Form'
    },
    {
        path: '/dashboard/membership-reminder-template',
        component: <MemberShipReminderTemplate />,
        name: 'Reminder Template'
    },
    {
        path: '/dashboard/membership-reminder-log',
        component: <MemberShipReminderListLog />,
        name: 'Reminder Log'
    },
    {
        path: '/dashboard/membership-reminder-log/:ID',
        component: <MemberShipReminderForUserLog />,
        name: 'Reminder Log'
    },

    //
    {
        path: '/dashboard/change-password',
        component: <ChangePassword />,
        id: '120',
        name: 'Change Password'
    },
    {
        path: '/dashboard/clerk',
        component: <Clerk />,
        name: 'Clerk'
    },
    {
        path: '/dashboard/clerk/create',
        component: <ClerkForm />,
        name: 'Create'
    },
    {
        path: '/dashboard/clerk/update/:id',
        component: <ClerkForm />,
        name: 'Update'
    },
    {
        path: '/dashboard/news-letter',
        component: <NewsLetter />,
        name: 'Newsletter'
    },
    {
        path: '/dashboard/news-letter/create',
        component: <NewsLetterForm />,
        name: 'Newsletter Form'
    },
    {
        path: '/dashboard/news-letter/update/:ID',
        component: <NewsLetterForm />,
        name: 'Newsletter Form'
    }

    // { path: '/dashboard/profile', component: <Profile />, name: 'Profile' }
]

const sanctionRoutes = [
    {
        path: '/dashboard/sanction-id-configuration',
        component: <SanctionIdConfiguration />,
        name: ' ID Configuration'
    },
    {
        path: '/dashboard/show-classification',
        component: <ShowClassificationList />,
        name: 'Show Classification'
    },
    {
        // /dashboard/show_classification/create
        path: '/dashboard/show-classification/create',
        component: <ShowClassificationForm />,
        name: 'Form'
    },
    {
        path: '/dashboard/show-classification/update/:ID',
        component: <ShowClassificationForm />,
        name: 'Form'
    },
    {
        path: '/dashboard/sanction-letter-template',
        component: <SanctionLetterTemplate />,
        name: 'Sanction Template'
    },
    {
        path: '/dashboard/sanction-card-template',
        component: <SanctionCardTemplate />,
        name: 'Sanction Card Template'
    },
    {
        path: '/dashboard/sanction_processing_fees',
        component: <SanctionProcessingFess />,
        name: 'Sanction Processing Fees'
    },
    // Sanction Reminder

    {
        path: '/dashboard/sanction-reminder-setup',
        component: <SanctionReminderSetup />,
        name: 'Reminder Setup'
    },
    {
        path: '/dashboard/sanction-reminder-setup/create',
        component: <SanctionReminderSetupForm />,
        name: 'Reminder Setup Form'
    },
    {
        path: '/dashboard/sanction-reminder-setup/update/:ID',
        component: <SanctionReminderSetupForm />,
        name: 'Reminder Setup Form'
    },
    {
        path: '/dashboard/sanction-reminder-template',
        component: <SanctionReminderTemplate />,
        name: 'Reminder Template'
    },
    {
        path: '/dashboard/sanction-reminder-log',
        component: <SanctionReminderListLog />,
        name: 'Reminder Log'
    },
    {
        path: '/dashboard/sanction-reminder-log/:ID',
        component: <SanctionReminderForUserLog />,
        name: 'Reminder Log'
    },

    // Sanction Plans
    {
        path: '/dashboard/sanction-plans',
        component: <SanctionPlans />,
        name: 'Sanction Plan'
    },
    {
        path: '/dashboard/sanction-plans/create',
        component: <SanctionPlanForm />,
        name: 'Sanction Plan Form'
    },
    {
        path: '/dashboard/sanction-plans/update/:ID',
        component: <SanctionPlanForm />,
        name: 'Sanction Plan Form'
    },
    // Sanction List
    {
        path: '/dashboard/sanction-list',
        component: <SanctionList />,
        name: 'Sanction List'
    },
    {
        path: '/dashboard/sanction-list/create',
        component: <SanctionFrom />,
        name: 'Create'
    },
    {
        path: '/dashboard/sanction-list/create/plan',
        component: <SanctionPlan />,
        name: 'Plan'
    },
    {
        path: '/dashboard/sanction-list/create/plan/checkout',
        component: <SanctionCheckout />,
        name: 'Checkout'
    },
    {
        path: '/dashboard/sanction-list/create/plan/checkout/process-payment',
        component: <SanctionPaymentProcess />,
        name: 'Payment Process'
    }
]

// const PublicRoute = [{ path: '/login', component: <Login />, id: '2', name: 'Login' }]

const PublicRoute = [{ path: '*', component: <Navigate to='/dashboard' />, id: '100' }]

export { PublicRoute, clubRoutes, membershipRoutes, sanctionRoutes }
