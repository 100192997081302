import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const ClubSubUserListApi = (data) => API.get(`${URL.CLUB_SUB_MEMBER_LIST}`, data)

export const ClubSubUserByIdApi = (ID) => API.get(`${URL.CLUB_SUB_MEMBER_ONE}/${ID}`)

export const ClubSubUserCreateApi = (data) => API.post(`${URL.CLUB_SUB_MEMBER_CREATE}`, data)

export const ClubSubUserUpdateApi = (ID, data) =>
    API.patch(`${URL.CLUB_SUB_MEMBER_UPDATE}/${ID}`, data)

export const ClubSubUserDeleteApi = (ID) => API.del(`${URL.CLUB_SUB_MEMBER_DELETE}/${ID}`)

export const ClubSubUserListForMemberCreateApi = (data) =>
    API.get(`${URL.CLUB_SUB_MEMBER_LIST_FOR_MEMBER}`, data)
