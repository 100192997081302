import { createSlice } from '@reduxjs/toolkit'
import { AddPlanPayment, ClubPlansList, PlanDetail } from './thunk'

export const initialState = {
    Plans: [],

    planDetail: {},
    paymentDetail: {},

    loading: true
}
const SetupFee = createSlice({
    name: 'clubPlanes',
    initialState,
    reducers: {
        clearPaymentIntent: (state) => {
            state.paymentDetail = {}
        }
    },

    extraReducers: (builder) => {
        // Listing
        builder.addCase(ClubPlansList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(ClubPlansList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.Plans = data
            state.loading = false
        })
        builder.addCase(ClubPlansList.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(PlanDetail.pending, (state) => {
            state.loading = true
        })
        builder.addCase(PlanDetail.fulfilled, (state, action) => {
            const { data } = action.payload
            state.planDetail = data
            state.loading = false
        })
        builder.addCase(PlanDetail.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(AddPlanPayment.pending, (state) => {
            state.loading = false
        })
        builder.addCase(AddPlanPayment.fulfilled, (state, action) => {
            state.paymentDetail = action?.payload || {}
            state.loading = false
        })
        builder.addCase(AddPlanPayment.rejected, (state) => {
            state.loading = false
        })
    }
})

export const { clearPaymentIntent } = SetupFee.actions
export default SetupFee.reducer
