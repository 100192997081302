import { createSlice } from '@reduxjs/toolkit'
import { ClerkById, ClerkList } from './thunk'
import _ from 'lodash'
import { CLERK_MENULIST } from 'src/data/data'

export const clerkForm = {
    club_clerks_id: '',
    club_id: '',
    last_name: '',
    first_name: '',
    expired_on_date: '',
    expired_on_time: '',
    expire_on: '',
    menu_access: CLERK_MENULIST,
    email: '',
    login_code: ''
}

export const initialState = {
    data: [],
    loading: true,
    form: clerkForm
}
const MemberShipPlan = createSlice({
    name: 'Clerk',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(ClerkList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(ClerkList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.form = clerkForm
            state.data = data
            state.loading = false
        })
        builder.addCase(ClerkList.rejected, (state) => {
            state.loading = false
        })

        builder.addCase(ClerkById.pending, (state) => {
            state.loading = false
        })
        builder.addCase(ClerkById.fulfilled, (state, action) => {
            const { payload } = action
            const data = _.pick(payload?.data, Object.keys(clerkForm))
            const menuList = _.map(_.split(payload?.data?.menu_access, ','), (o) => Number(o))

            const obj = {
                ...data,
                expired_on_date: data.expire_on,
                expired_on_time: data.expire_on,
                menu_access: _.map(CLERK_MENULIST, (menu) => {
                    return {
                        ...menu,
                        childrenMenuItems: menu.childrenMenuItems.map((childMenu) => {
                            return { ...childMenu, selected: menuList.includes(childMenu.id) }
                        })
                    }
                })
            }
            state.form = obj
            state.loading = false
        })
        builder.addCase(ClerkById.rejected, (state) => {
            state.loading = false
        })
    }
})

// export const {} = SetupFee.actions
export default MemberShipPlan.reducer
