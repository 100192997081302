import { createAsyncThunk } from '@reduxjs/toolkit'
import * as apiService from './StateControlSheet.services'
import _ from 'lodash'
import { paginationData } from '../Pagination/reducers'

export const controlSheetBreedDetail = createAsyncThunk(
    '@Sweepstake/ShowSetup/controlSheet/StateReport/BreedDetail',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.controlSheetBreedDetailApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const ControlsheetBreedAllList = createAsyncThunk(
    '@Sweepstake/ShowSetup/controlSheet/StateReport/club/breed/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.BreedAllListApi(data)
            const list = _.map(_.orderBy(res || [], ['breed_name'], ['asc']), (d, index) => {
                return {
                    ...d,
                    isActive: index === 0 ? true : false,
                    uniqueId: `breed-${d.breed_id}`
                }
            })
            return list
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const BreedListForDetailByBreed = createAsyncThunk(
    '@Sweepstake/ShowSetup/controlSheet/StateReport/club/breed/listFor',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.BreedAllListApi(data)

            let list = _.map(_.orderBy(res || [], ['breed_name'], ['asc']), (d, index) => {
                return {
                    ...d,
                    isActive: index === 0 ? true : false,
                    placingType: 'breed',
                    uniqueId: `breed-${d.breed_id}`
                }
            })
            if (Array.isArray(list) && _.size(list)) {
                list = list
                    .concat({
                        placingType: 'show',
                        title: 'Top Awards',
                        breed_id: 'show',
                        uniqueId: `show`
                    })
                    .concat({
                        placingType: 'report',
                        title: 'Top Report',
                        breed_id: 'report',
                        uniqueId: `report`
                    })
            }

            return list
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const sweepStakePlacingList = createAsyncThunk(
    '@Sweepstake/ShowSetup/controlSheet/StateReport/placing/list',
    async (data, { rejectWithValue }) => {
        try {
            const finalData = {}
            const result = await apiService.SweepstakePlacingListApi(data)
            if (Array.isArray(result?.data)) {
                const list = _.filter(result.data, (q) => ['1', '2-N'].includes(q.placing))
                finalData.class = _.filter(
                    _.map(
                        _.filter(list, (q) => q.step.split(',').includes('class')),
                        (q) => q.placing
                    ).concat(
                        data.category === 'national'
                            ? ['2', '3', '4', '5', '6', '7', '8', '9', '10']
                            : ['2', '3', '4', '5']
                    ),
                    (q) => q !== '2-N'
                )
                finalData.fur = _.filter(
                    _.map(
                        _.filter(list, (q) => q.step.split(',').includes('fur')),
                        (q) => q.placing
                    ).concat(
                        data.category === 'national'
                            ? ['2', '3', '4', '5', '6', '7', '8', '9', '10']
                            : ['2', '3', '4', '5']
                    ),
                    (q) => q !== '2-N'
                )
                finalData.best_fur = _.map(
                    _.filter(result.data, (q) => q.step.split(',').includes('best_fur')),
                    (q) => q.placing
                )
                finalData.group = _.map(
                    _.filter(result.data, (q) => q.step.split(',').includes('group')),
                    (q) => q.placing
                )
                finalData.variety = _.map(
                    _.filter(result.data, (q) => q.step.split(',').includes('variety')),
                    (q) => q.placing
                )
                finalData.breed = _.map(
                    _.filter(result.data, (q) => q.step.split(',').includes('breed')),
                    (q) => q.placing
                )

                finalData.rabbit = _.map(
                    _.filter(result.data, (q) => q.step.includes('breed')),
                    (q) => q.placing
                )
                finalData.cavy = _.map(
                    _.filter(result.data, (q) => q.step.includes('breed')),
                    (q) => q.placing
                )
                finalData.exhibition = _.map(
                    _.filter(result.data, (q) => q.step.includes('breed')),
                    (q) => q.placing
                )
            }

            return finalData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const StateReportExhibitorList = createAsyncThunk(
    '@Sweepstake/ShowSetup/controlSheet/StateReport/Exhibitor/list',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await apiService.StateReportExhibitorListApi(data.showId, data)
            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }
            return res

            // return res?.data || null
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const ExhListForFurResultsAndPoints = createAsyncThunk(
    'Sweepstake/ShowSetup/controlSheet/StateReport/FurResultsAndPoints/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.StateReportExhibitorListApi(data.showId, data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const QualityResultsSweepstakePlacingList = createAsyncThunk(
    'Sweepstake/ShowSetup/controlSheet/StateReport/QualityResultsSweepstakePlacing/list',
    async (data, { rejectWithValue }) => {
        try {
            let list = []
            const res = await apiService.QualityResultsSweepstakePlacingApi(data.type, data.params)

            if (res?.data) {
                list = _.get(res?.data, 'placings')
                list = _.filter(list, (f) => !data?.filter?.includes(f))
            }

            return list
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
