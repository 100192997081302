import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    ClubUserListApi,
    ClubUserByIdApi,
    ClubUserCreateApi,
    ClubUserDeleteApi,
    ClubUserUpdateApi,
    ClubUserListForMemberCreateApi,
    AliasUserCreateApi
} from './ClubUser.services'
import { paginationData } from '../Pagination/reducers'
import { stateListApi } from '../State/state.services'

//services
export const ClubUserList = createAsyncThunk(
    'ClubUser/MembershipList',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await ClubUserListApi(data)

            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const ClubUserCreate = createAsyncThunk(
    'ClubUser/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ClubUserCreateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const ClubUserUpdate = createAsyncThunk(
    'ClubUser/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ClubUserUpdateApi(data.id, data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const ClubUserById = createAsyncThunk('ClubUser/one', async (ID, { rejectWithValue }) => {
    try {
        let res = await ClubUserByIdApi(ID)
        const params = {
            // state_id: res.data.state_id,
            _order: 'asc',
            _sort: 'state_code'
        }
        const { data: StateList } = await stateListApi(params)
        res = {
            data: {
                ...res.data,
                country_id: StateList.find((f) => f.state_id === res.data.state_id)?.country_id
            }
        }
        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

//
export const ClubUserDelete = createAsyncThunk(
    'ClubUser/delete',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await ClubUserDeleteApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const ClubUserListForMemberCreate = createAsyncThunk(
    'ClubUser/MembershipList/For/Member/Create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ClubUserListForMemberCreateApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const AliasUserCreate = createAsyncThunk(
    '@AliasUser/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await AliasUserCreateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
