import { createSlice } from '@reduxjs/toolkit'
import { AgeClassificationById, AgeClassificationCreate, AgeClassificationList } from './thunk'
import _ from 'lodash'

export const ageClassificationForm = {
    age_type_name: '',
    start_age: '',
    end_age: '',
    is_minor: '',
    age_classification_id: '',
    club_id: ''
}

export const initialState = {
    data: [],
    loading: true,
    form: ageClassificationForm
}
const MemberShipPlan = createSlice({
    name: 'ageClassification',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(AgeClassificationList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(AgeClassificationList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.form = ageClassificationForm
            state.data = data
            state.loading = false
        })
        builder.addCase(AgeClassificationList.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(AgeClassificationCreate.pending, (state) => {
            state.loading = false
        })
        builder.addCase(AgeClassificationCreate.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(AgeClassificationCreate.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(AgeClassificationById.pending, (state) => {
            state.loading = false
        })
        builder.addCase(AgeClassificationById.fulfilled, (state, action) => {
            const { payload } = action

            const data = _.pick(payload?.data, Object.keys(ageClassificationForm))
            const finalData = {
                ...data,
                is_minor: data?.is_minor === 'Y' ? true : false
            }

            state.form = finalData
            // state.form.is_minor = state.form.is_minor === 'Y' ? true : false
            // state.loading = false
        })
        builder.addCase(AgeClassificationById.rejected, (state) => {
            state.loading = false
        })
    }
})

export default MemberShipPlan.reducer
