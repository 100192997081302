import { createAsyncThunk } from '@reduxjs/toolkit'
import { paginationData } from '../Pagination/reducers'
import {
    ClerkByIdApi,
    ClerkCreateApi,
    ClerkDeleteApi,
    ClerkListApi,
    ClerkUpdateApi
} from './Clerk.services'

//services
export const ClerkList = createAsyncThunk(
    'Clerk/List',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await ClerkListApi(data)

            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const ClerkById = createAsyncThunk('Clerk/getById', async (data, { rejectWithValue }) => {
    try {
        const res = await ClerkByIdApi(data.id, data.params)

        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})
export const ClerkDelete = createAsyncThunk('Clerk/delete', async (data, { rejectWithValue }) => {
    try {
        const res = await ClerkDeleteApi(data.id, data.params)

        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

export const ClerkCreate = createAsyncThunk('Clerk/create', async (data, { rejectWithValue }) => {
    try {
        const res = await ClerkCreateApi(data)
        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})
export const ClerkUpdate = createAsyncThunk('Clerk/update', async (data, { rejectWithValue }) => {
    try {
        const res = await ClerkUpdateApi(data.id, data)
        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})
