import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//


export const ShowClassificationListApi = (data) => API.get(`${URL.SHOW_CLASSIFICATION_LIST_URL}`, data)

export const ShowClassificationByIdApi = (ID) => API.get(`${URL.SHOW_CLASSIFICATION_ONE_URL}/${ID}`)

export const ShowClassificationCreateApi = (data) => API.post(`${URL.SHOW_CLASSIFICATION_CREATE_URL}`,data)

export const ShowClassificationUpdateApi = (ID,data) => API.put(`${URL.SHOW_CLASSIFICATION_UPDATE_URL}/${ID}`,data)

export const ShowClassificationDeleteApi = (ID) =>API.del(`${URL.SHOW_CLASSIFICATION_DELETE_URL}/${ID}`)