import { createSlice } from '@reduxjs/toolkit'
import {
    SweepStakeJudgeList,
    SweepStakeShowSetupList,
    SweepstakeMemberAlreadyAddedGet,
    createSweepStakeShowSetup,
    getOneSweepStakeJudge,
    getOneSweepStakeShowSetup
} from './thunk'
import _ from 'lodash'

// import _ from 'lodash'

export const Form = {
    club_sanction_no: '',
    arba_sanction_no: '',
    show_date: '',
    host_club_name: '',
    secretary_name: '',
    secretary_email: '',
    secretary_address: '',
    show_name: '',
    event_type: 'national',
    show_type: 'open',
    show_type_sp: 'allbreed',
    spl_point_show: '1',
    show_char: null,
    address: '',
    country_id: '',
    state: '',
    city: '',
    zipcode: '',
    report_type: ''
}

export const JudgeForm = {
    judge_id: '',
    judge_name: ''
}

export const initialState = {
    form: Form,
    JudgeForm,
    formLoading: true,
    loading: true,
    showList: [],
    addedMemberList: [], // already added member list in array format [1,2,3]
    judgeList: []
}
const SweepstakeShowSetup = createSlice({
    name: '@Sweepstake/ShowSetup',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // show setup list
        builder.addCase(SweepStakeShowSetupList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(SweepStakeShowSetupList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.form = Form
            state.showList = data
            state.loading = false
        })
        builder.addCase(SweepStakeShowSetupList.rejected, (state) => {
            state.formLoading = false
        })
        builder.addCase(createSweepStakeShowSetup.rejected, (state) => {
            state.formLoading = false
        })
        // GetByID
        builder.addCase(getOneSweepStakeShowSetup.pending, (state) => {
            state.formLoading = true
        })
        builder.addCase(getOneSweepStakeShowSetup.fulfilled, (state, action) => {
            const { payload } = action
            const data = {
                ...payload.data,
                show_date: payload.data.show_date
                    ? new Date(payload.data.show_date).toISOString()
                    : ''
            }
            const finalData = _.pick(data, Object.keys(Form))
            state.form = finalData
            state.formLoading = false
        })
        builder.addCase(getOneSweepStakeShowSetup.rejected, (state) => {
            state.formLoading = false
        })

        // Judge list
        builder.addCase(SweepStakeJudgeList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(SweepStakeJudgeList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.JudgeForm = JudgeForm
            state.judgeList = data
            state.loading = false
        })
        builder.addCase(SweepStakeJudgeList.rejected, (state) => {
            state.formLoading = false
        })
        // GetByID
        builder.addCase(getOneSweepStakeJudge.pending, (state) => {
            state.formLoading = true
        })
        builder.addCase(getOneSweepStakeJudge.fulfilled, (state, action) => {
            // const { data } = action.payload
            const { payload } = action

            const data = _.pick(payload?.data, Object.keys(JudgeForm))
            const finalData = data

            state.JudgeForm = finalData
            state.formLoading = false
        })
        builder.addCase(getOneSweepStakeJudge.rejected, (state) => {
            state.formLoading = false
        })
        builder.addCase(SweepstakeMemberAlreadyAddedGet.pending, (state) => {
            state.loading = false
            state.addedMemberList = []
        })
        builder.addCase(SweepstakeMemberAlreadyAddedGet.fulfilled, (state, action) => {
            // const { data } = action.payload
            const { payload } = action
            let data = []
            if (Array.isArray(payload)) {
                data = _.map(_.split(_.get(_.first(payload), 'existed_users'), ','), (q) =>
                    Number(q)
                )
            }

            state.addedMemberList = data
            state.formLoading = false
        })
        builder.addCase(SweepstakeMemberAlreadyAddedGet.rejected, (state) => {
            state.loading = false
        })
    }
})

export default SweepstakeShowSetup.reducer
