import { createSlice } from '@reduxjs/toolkit'
import { SanctionCardTemplateList } from './thunk'
import _ from 'lodash'

export const from = {
    sanction_card_template_id: '',
    club_id: '',
    info_1: '',
    logo_file: '',
    logoFile: ''
}

export const initialState = {
    loading: true,
    form: from
}
const SanctionCardTemplate = createSlice({
    name: 'Sanction/card/template',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(SanctionCardTemplateList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(SanctionCardTemplateList.fulfilled, (state, action) => {
            const { data } = action.payload
            let formData = _.first(data)
            if (formData) {
                formData = {
                    sanction_card_template_id: formData?.sanction_card_template_id || '',
                    club_id: formData?.club_id || '',
                    info_1: formData?.info_1 || '',
                    logo_file: formData?.document_file || '',
                    logoFile: ''
                }
            }

            state.form = formData ? formData : from
            state.loading = false
        })
        builder.addCase(SanctionCardTemplateList.rejected, (state) => {
            state.loading = false
        })
    }
})

// export const {} = SetupFee.actions
export default SanctionCardTemplate.reducer
