import { createAsyncThunk } from '@reduxjs/toolkit'
import * as apiService from './NationalControlSheet.services'
import _ from 'lodash'
import { SweepstakeListApi } from '../Sweepstake/sweepstake.services'

export const controlSheetBreedDetailForNational = createAsyncThunk(
    '@Sweepstake/Control/BreedDetail',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.controlSheetBreedDetailApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const settingDetailForNational = createAsyncThunk(
    '@National/ControlSheet/club/detail',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.settingDetailForNationalApi(data)

            let list = _.map(_.orderBy(res || [], ['breed_name'], ['asc']), (d, index) => {
                return {
                    ...d,
                    isActive: index === 0 ? true : false,
                    placingType: 'breed',
                    uniqueId: `breed-${d.breed_id}`
                }
            })
            if (Array.isArray(list) && _.size(list)) {
                list = list.concat({
                    placingType: 'show',
                    title: 'Top Awards',
                    breed_id: 'show',
                    uniqueId: `show`
                })
            }

            return list
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const settingBreedListForNational = createAsyncThunk(
    '@National/ControlSheet/club/exhListByPoints',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.settingDetailForNationalApi(data)

            const list = _.map(_.orderBy(res || [], ['breed_name'], ['asc']), (d, index) => {
                return {
                    ...d,
                    isActive: index === 0 ? true : false,
                    uniqueId: `breed-${d.breed_id}`
                }
            })

            return list
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const sweepStakePlacingListForNational = createAsyncThunk(
    '@National/ControlSheet/placing/list',
    async (data, { rejectWithValue }) => {
        try {
            const finalData = {}
            const result = await apiService.SweepstakePlacingListApi(data)
            if (Array.isArray(result?.data)) {
                const list = _.filter(result.data, (q) => ['1', '2-N'].includes(q.placing))
                finalData.class = _.filter(
                    _.map(
                        _.filter(list, (q) => q.step.split(',').includes('class')),
                        (q) => q.placing
                    ).concat(
                        data.category === 'national'
                            ? ['2', '3', '4', '5', '6', '7', '8', '9', '10']
                            : ['2', '3', '4', '5']
                    ),
                    (q) => q !== '2-N'
                )
                finalData.fur = _.filter(
                    _.map(
                        _.filter(list, (q) => q.step.split(',').includes('fur')),
                        (q) => q.placing
                    ).concat(
                        data.category === 'national'
                            ? ['2', '3', '4', '5', '6', '7', '8', '9', '10']
                            : ['2', '3', '4', '5']
                    ),
                    (q) => q !== '2-N'
                )
                finalData.best_fur = _.map(
                    _.filter(result.data, (q) => q.step.split(',').includes('best_fur')),
                    (q) => q.placing
                )
                finalData.group = _.map(
                    _.filter(result.data, (q) => q.step.split(',').includes('group')),
                    (q) => q.placing
                )
                finalData.variety = _.map(
                    _.filter(result.data, (q) => q.step.split(',').includes('variety')),
                    (q) => q.placing
                )
                finalData.breed = _.map(
                    _.filter(result.data, (q) => q.step.split(',').includes('breed')),
                    (q) => q.placing
                )
                finalData.show = _.map(
                    _.filter(
                        result.data,
                        (q) =>
                            q.step.split(',').includes('best_class_type') ||
                            q.step.split(',').includes('show') ||
                            q.step.split(',').includes('best_group') ||
                            q.step.split(',').includes('show_cavy') ||
                            q.step.split(',').includes('show_cavy_runner_up') //||
                        // q.step.split(',').includes('best_group')
                    ),
                    (q) => ({ placing: q.placing, placing_type: q.step })
                )
            }

            return finalData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const NationalReportExhibitorList = createAsyncThunk(
    '@National/ControlSheet/Exhibitor/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.NationalReportExhibitorListApi(data.showId, data)
            const list = _.orderBy(res?.data, [(q) => q.exh_name.toLowerCase()], ['asc'])

            return list
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SweepstakeAllPointList = createAsyncThunk(
    '@Sweepstake/point/all/List',
    async (data, { rejectWithValue }) => {
        try {
            const res = data.map((q) => SweepstakeListApi(q))
            let list = await Promise.all(res)
            list = _.groupBy(
                _.map(list, (q) => _.first(q.data)),
                'placing_type'
            )
            return list
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SaveControlSheetPlacing = createAsyncThunk(
    '@ControlSheet/Save/Placing',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.SaveControlSheetPlacingApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const GetControlSheetPlacing = createAsyncThunk(
    '@ControlSheet/get/Placing',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.GetControlSheetPlacingApi(data)
            if (data.with_exh_count_data) {
                return {
                    data: res?.data || [],
                    with_exh_count_data: data.with_exh_count_data
                }
            }
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const BreedDetailForTopAwards = createAsyncThunk(
    '@Sweepstake/Control/TopAwards/BreedDetail',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.controlSheetBreedDetailApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const GetControlSheetExhListByPoints = createAsyncThunk(
    '@National/Exh/listBy/Points',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.GetControlSheetExhListByPointsApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const ResetControlSheetDetailByBreed = createAsyncThunk(
    '@National/Reset/DetailByBreed',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.ResetControlSheetDetailByBreedApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SaveControlSheetExhByPointsPlacing = createAsyncThunk(
    '@ControlSheet/Save/ExhListByPoints/Placing',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.SaveControlSheetExhByPointsPlacingApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const GetControlSheetExhByPointsPlacing = createAsyncThunk(
    '@ControlSheet/Save/ExhListByPoints/Placing',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.GetControlSheetExhByPointsPlacingApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SaveControlSheetPlacingForQualityPoints = createAsyncThunk(
    '@ControlSheet/Save/Quality-Points/Placing',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.SaveControlSheetPlacingForQualityPointsApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const GetControlSheetPlacingForQualityPoints = createAsyncThunk(
    '@ControlSheet/get/Quality-Points/Placing',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.GetControlSheetPlacingForQualityPointsApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SaveControlSheetFurResults = createAsyncThunk(
    '@ControlSheet/Save/FurResults',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.SaveControlSheetFurResultsApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const GetControlSheetFurResult = createAsyncThunk(
    '@ControlSheet/get/FurResult',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.GetControlSheetFurResultApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SaveCompleteExhListWithPoint = createAsyncThunk(
    '@ControlSheet/Complete/ExhWithPoint/save',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.SaveCompleteExhListWithPointApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const GetCompleteExhListWithPoint = createAsyncThunk(
    '@ControlSheet/Complete/ExhWithPoint/get',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.GetCompleteExhListWithPointApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const GetDetailByBreedCompleteExhListWithPoint = createAsyncThunk(
    '@ControlSheet/detailByBreed/Complete/ExhWithPoint/get',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.GetDetailByBreedCompleteExhListWithPointApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const saveReportType = createAsyncThunk(
    '@ControlSheet/save/report/type',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.saveReportTypeApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const GetNewsLetterControlSheetPlacing = createAsyncThunk(
    '@ControlSheet/get/newsletter/Placing',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.GetControlSheetPlacingApi(data)
            if (data.with_exh_count_data) {
                return {
                    data: res?.data || [],
                    with_exh_count_data: data.with_exh_count_data
                }
            }
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const SaveNewsLetterPlacing = createAsyncThunk(
    '@ControlSheet/save/newsletter',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.saveNationalReportApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const getNewsLetterPlacing = createAsyncThunk(
    '@ControlSheet/get/newsletter',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.getNationalReportApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
