import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const SanctionPurchaseListApi = (data) =>
    API.post(
        `${URL.CLUB_SANCTION_ALL_LIST}?_limit=${data?.params?._limit || ''}&_page=${data?.params?._page || ''}&_sort=${data?.params?._sort || ''}&_order=${data?.params?._order || ''}`,
        data.bodyData
    )

export const SanctionGrandTotalApi = (data) =>
    API.post(`${URL.SANCTION_LIST_GRAND_TOTAL_URL}`, data)
