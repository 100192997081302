import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const LetterTemplateListApi = (data) =>
    API.get(`${URL.LETTER_TEMPLATE_LIST}`, data)
export const LetterTemplateCreateApi = (data) =>
    API.post(`${URL.LETTER_TEMPLATE_CREATE}`, data)
export const LetterTemplateUpdateApi = (ID, data) =>
    API.patch(`${URL.LETTER_TEMPLATE_UPDATE}/${ID}`, data)
    export const LetterTemplateDeleteApi = (ID) =>
    API.del(`${URL.LETTER_TEMPLATE_DELETE}/${ID}`)