import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    LetterTemplateCreateApi,
    LetterTemplateDeleteApi,
    LetterTemplateListApi,
    LetterTemplateUpdateApi
} from './LetterTemplate.services'

export const LetterTemplateList = createAsyncThunk('lettertemplate/list', async (data, { rejectWithValue }) => {
    try {
        const res = await LetterTemplateListApi(data)

        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

export const LetterTemplateCreate = createAsyncThunk(
    'lettertemplate/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await LetterTemplateCreateApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const LetterTemplateUpdate = createAsyncThunk(
    'lettertemplate/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await LetterTemplateUpdateApi(data?.membership_letter_template_id, data.formData)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const LetterTemplateDelete = createAsyncThunk(
    '/lettertemplate/delete',
    async (data, { rejectWithValue }) => {
        try {
            const res = await LetterTemplateDeleteApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)