import { createSlice } from '@reduxjs/toolkit'
import {
    controlSheetBreedDetail,
    ExhListForFurResultsAndPoints,
    ControlsheetBreedAllList,
    StateReportExhibitorList,
    sweepStakePlacingList,
    BreedListForDetailByBreed,
    QualityResultsSweepstakePlacingList
} from './thunk'
import _ from 'lodash'

export const Form = {
    show_id: '',
    breed_list: []
}

export const initialState = {
    exhibitorList: [],
    loading: true,
    breed_list: [],
    breedListLoading: true,
    breedDetail: {},
    breedDetailLoading: true,
    placingData: {},
    placingLoading: true,
    topAwards: [],
    topAwardsLoading: true
}
const StateReport = createSlice({
    name: '@Sweepstake/ShowSetup/controlSheet/StateReport',
    initialState,
    reducers: {
        changeActiveBreed: (state, action) => {
            const { payload } = action
            state.breed_list = state.breed_list.map((b) => {
                if (b.uniqueId === payload.uniqueId) {
                    return {
                        ...b,
                        isActive: true
                    }
                }
                return {
                    ...b,
                    isActive: false
                }
            })
            state.breedDetail = {}
            state.breedDetailForOpt = {
                group: [],
                variety: [],
                class: []
            }
        },
        setExhibitorLoading: (state, action) => {
            const { payload } = action
            state.loading = payload.loading
        }
    },
    extraReducers: (builder) => {
        builder.addCase(controlSheetBreedDetail.pending, (state) => {
            state.breedDetailLoading = true
            state.breedDetail = {}
        })
        builder.addCase(controlSheetBreedDetail.fulfilled, (state, action) => {
            const { payload } = action
            state.breedDetail = _.first(payload?.data) || {}
            state.breedDetailLoading = false
        })
        builder.addCase(controlSheetBreedDetail.rejected, (state) => {
            state.breedDetailLoading = false
            state.breedDetail = {}
        })
        builder.addCase(ControlsheetBreedAllList.pending, (state) => {
            state.breedListLoading = true
            state.breed_list = []
        })
        builder.addCase(ControlsheetBreedAllList.fulfilled, (state, action) => {
            const { payload } = action
            state.breed_list = payload
            state.breedListLoading = false
        })
        builder.addCase(ControlsheetBreedAllList.rejected, (state) => {
            state.breedListLoading = false
            state.breed_list = []
        })
        builder.addCase(BreedListForDetailByBreed.pending, (state) => {
            state.breedListLoading = true
            state.breed_list = []
        })
        builder.addCase(BreedListForDetailByBreed.fulfilled, (state, action) => {
            const { payload } = action
            state.breed_list = payload
            state.breedListLoading = false
        })
        builder.addCase(BreedListForDetailByBreed.rejected, (state) => {
            state.breedListLoading = false
            state.breed_list = []
        })
        builder.addCase(sweepStakePlacingList.pending, (state) => {
            state.placingData = {}
            state.placingLoading = true
        })
        builder.addCase(sweepStakePlacingList.fulfilled, (state, action) => {
            const { payload } = action
            state.placingData = payload
            state.placingLoading = false
        })
        builder.addCase(sweepStakePlacingList.rejected, (state) => {
            state.placingData = {}
            state.placingLoading = false
        })
        builder.addCase(QualityResultsSweepstakePlacingList.pending, (state) => {
            state.topAwards = []
            state.topAwardsLoading = true
        })
        builder.addCase(QualityResultsSweepstakePlacingList.fulfilled, (state, action) => {
            const { payload } = action
            state.topAwards = payload
            state.topAwardsLoading = false
        })
        builder.addCase(QualityResultsSweepstakePlacingList.rejected, (state) => {
            state.topAwards = []
            state.topAwardsLoading = false
        })
        builder.addCase(StateReportExhibitorList.pending, (state) => {
            state.loading = true
            state.exhibitorList = []
        })
        builder.addCase(StateReportExhibitorList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.exhibitorList = _.orderBy(data, [(q) => q.exh_name.toLowerCase()], ['asc']) || []
            state.loading = false
        })
        builder.addCase(StateReportExhibitorList.rejected, (state) => {
            state.loading = false
            state.exhibitorList = []
        })
        builder.addCase(ExhListForFurResultsAndPoints.pending, (state) => {
            state.loading = true
            state.exhibitorList = []
        })
        builder.addCase(ExhListForFurResultsAndPoints.fulfilled, (state, action) => {
            const { data } = action.payload

            const finalData = Array.isArray(data)
                ? _.orderBy(
                      data.map((exh) => ({
                          ...exh,
                          label: `${exh?.exh_name || ''} (${exh?.user_email || 'No Email'})`,
                          value: exh.user_id,

                          highlight: exh.user_role === 'alias' ? true : false
                      })),
                      [(q) => q.exh_name.toLowerCase()],
                      ['asc']
                  ) || []
                : []
            state.exhibitorList = finalData
            state.loading = false
        })
        builder.addCase(ExhListForFurResultsAndPoints.rejected, (state) => {
            state.loading = false
            state.exhibitorList = []
        })
    }
})

export const { changeActiveBreed, setExhibitorLoading } = StateReport.actions

export default StateReport.reducer
