import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

export const controlSheetBreedDetailApi = (data) =>
    API.post(`${URL.GET_CONTROL_SHEET_BREED_DETAIL}`, data)

export const settingDetailApi = (data) => API.get(`${URL.SETTING_GET_ALL_CLUB}`, data)
export const breedListApi = (data) => API.get(`${URL.GET_ALL_BREED}`, data)
export const SweepstakePlacingListApi = (data) =>
    API.get(`${URL.GET_ALL_SWEEPSTAKE_PACING_FOR_CONTROL_SHEET_URL}`, data)

export const StateReportExhibitorListApi = (ID, data) =>
    API.get(`${URL.GET_SWEEPSTAKE_MEMBER_LIST}/${ID}`, data)

export const BreedAllListApi = (data) => API.post(`${URL.GET_ALL_BREED_CONTROL_SHEET}`, data)
export const QualityResultsSweepstakePlacingApi = (type, data) =>
    API.get(`${URL.GET_ALL_SWEEPSTAKE_PACING_URL}/${type}`, data)
