import { createAsyncThunk } from '@reduxjs/toolkit'
import * as service from './MemberShipReminder.services'
import { paginationData } from '../Pagination/reducers'
import _ from 'lodash'

export const MembershipReminderListLogs = createAsyncThunk(
    '@Membership/Reminder/allList/log/list',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await service.MembershipReminderListLogsApi(data)

            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data?._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const MembershipReminderLogs = createAsyncThunk(
    '@Membership/Reminder/log/list/username',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await service.MembershipReminderLogsApi(data.ID, data.params)

            if (Number(data?.params?._limit || 0) > 1) {
                // Pagination Section
                const limit = data?.params?._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?.params?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (Number(data?.params?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const MembershipReminderTemplateList = createAsyncThunk(
    '@Membership/Reminder/Template/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await service.MembershipReminderTemplateListApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const MembershipReminderTemplateCreate = createAsyncThunk(
    '@Membership/Reminder/Template/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await service.MembershipReminderTemplateCreateApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const MembershipReminderTemplateUpdate = createAsyncThunk(
    '@Membership/Reminder/Template/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await service.MembershipReminderTemplateUpdateApi(
                data?.membership_reminder_template_id,
                data.formData
            )

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const MembershipReminderTemplateDelete = createAsyncThunk(
    '@Membership/Reminder/Template/delete',
    async (data, { rejectWithValue }) => {
        try {
            const res = await service.MembershipReminderTemplateDeleteApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const MembershipReminderSetupList = createAsyncThunk(
    '@Membership/Reminder/setup/list',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await service.MembershipReminderSetupListApi(data)

            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data?._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const MembershipReminderSetupCreate = createAsyncThunk(
    '@Membership/Reminder/Setup/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await service.MembershipReminderSetupCreateApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const MembershipReminderSetupUpdate = createAsyncThunk(
    '@Membership/Reminder/Setup/update',
    async (data, { rejectWithValue }) => {
        try {
            const finalData = _.omit(data, ['membership_reminder_id'])
            const res = await service.MembershipReminderSetupUpdateApi(
                data?.membership_reminder_id,
                finalData
            )

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const MembershipReminderSetupDelete = createAsyncThunk(
    '@Membership/Reminder/Setup/delete',
    async (data, { rejectWithValue }) => {
        try {
            const res = await service.MembershipReminderSetupDeleteApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const MembershipReminderSetupOne = createAsyncThunk(
    '@Membership/Reminder/Setup/One',
    async (data, { rejectWithValue }) => {
        try {
            const res = await service.MembershipReminderSetupOneApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MembershipReminderLogsUserList = createAsyncThunk(
    '@Membership/Reminder/Setup/User/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await service.MembershipReminderLogsUserListApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
