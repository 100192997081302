import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'
import { Button } from 'src/Atoms'

const StripAccess = (props) => {
    const navigate = useNavigate()
    const { admin } = useSelector((state) => state.Login)
    const [showModal, setShowModal] = useState({
        showModal: false,
        modalContact: ''
    })

    useEffect(() => {
        if (!admin.connectedAcctData) {
            const modalObj = {
                showModal: true,
                modalContact: 'Please create stripe account first to complete account details.'
            }
            setShowModal(modalObj)
        }
    }, [props])

    const toggle = () => {
        const modalObj = {
            showModal: false,
            modalContact: ''
        }
        setShowModal(modalObj)
        navigate('/dashboard')
    }

    return (
        <>
            {props.children}
            <Modal isOpen={showModal.showModal} centered toggle={toggle}>
                <Row>
                    <Col className='text-end m-2'>
                        <Button
                            className='btn btn-danger'
                            onClick={toggle}
                            title='close'
                            color='primary'
                        >
                            X
                        </Button>
                    </Col>
                </Row>
                <ModalBody className=''>
                    <Row className='mt-2 py-2'>
                        <Col md={12} className='text-center mt-3'>
                            <h4>{showModal.modalContact}</h4>
                        </Col>
                    </Row>
                </ModalBody>{' '}
                <ModalFooter className='justify-content-center'>
                    <Row>
                        <Col md={12} className='mt-3'>
                            <Button
                                className='btn btn-primary w-lg waves-effect waves-light ms-2'
                                title='Connect Strip Accountt'
                                color='primary'
                                onClick={() => {
                                    window.open(
                                        process.env.REACT_APP_STRIPE_CONNECTED_ACCOUNT,
                                        '_self'
                                    )
                                }}
                            >
                                CONNECT STRIPE ACCOUNT
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default StripAccess
