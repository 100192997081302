import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const ClubListApi = (data) => API.get(`${URL.CLUB_LOAD_URL}`, data)

export const MembershipTypeListApi = (data) => API.get(`${URL.MEMBERSHIP_LIST_TYPE_LIST}`, data)

export const MemberUserListApi = (data) => API.get(`${URL.MEMBER_USER_LIST}`, data)

export const MembershipPlansByIdForMemberApi = (Id) =>
    API.get(`${URL.MEMBERSHIP_PLANS_ONE_MEMBER}/${Id}`)

export const MemberShipListApi = (data) =>
    API.post(
        `${URL.MEMBERSHIP_LIST}?_limit=${data?.params?._limit}&_page=${data?.params?._page}&_sort=${data?.params?._sort}&_order=${data?.params?._order}`,
        data.bodyData
    )
