
import { Container, Row, Col } from "reactstrap"

const Footer = (props) => (
  props.unAuth?  <footer className='footer-page'>
  <Row>
      <div className='col-md-12'>
          <div className='text-center d-none d-sm-block'>
              © {new Date().getFullYear()} Club Management. All Rights Reserved. 
          </div>
      </div>
  </Row>
  </footer>:
    <footer className="footer">
      <Container fluid>
        <Row>
          <Col sm={12}>
            <div className="text-sm-center d-sm-block foot-title">
              Copyright © {new Date().getFullYear()} Club Management. All rights reserved.
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )

export default Footer
