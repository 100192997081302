import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'
// Show Setup
export const createSweepStakeShowSetupApi = (data) =>
    API.post(`${URL.CREATE_SWEEPSTAKE_SHOW_SETUP_URL}`, data)
export const updateSweepStakeShowSetupApi = (ID, data) =>
    API.put(`${URL.UPDATE_SWEEPSTAKE_SHOW_SETUP_URL}/${ID}`, data)
export const getOneSweepStakeShowSetupApi = (ID) =>
    API.get(`${URL.GET_SWEEPSTAKE_SHOW_SETUP_SINGLE_URL}/${ID}`)
export const SweepStakeShowSetupListApi = (data) =>
    API.get(`${URL.GET_SWEEPSTAKE_SHOW_SETUP_LIST_URL}`, data)
export const deleteSweepStakeShowSetupApi = (ID) =>
    API.del(`${URL.DELETE_SWEEPSTAKE_SHOW_SETUP_URL}/${ID}`)

// Judge
export const createSweepStakeJudgeApi = (data) =>
    API.post(`${URL.CREATE_SWEEPSTAKE_JUDGE_URL}`, data)
export const updateSweepStakeJudgeApi = (ID, data) =>
    API.put(`${URL.UPDATE_SWEEPSTAKE_JUDGE_URL}/${ID}`, data)
export const getOneSweepStakeJudgeApi = (ID) =>
    API.get(`${URL.GET_SWEEPSTAKE_JUDGE_SINGLE_URL}/${ID}`)
export const SweepStakeJudgeListApi = (ID, data) =>
    API.get(`${URL.GET_SWEEPSTAKE_JUDGE_LIST_URL}/${ID}`, data)
export const deleteSweepStakeJudgeApi = (ID) => API.del(`${URL.DELETE_SWEEPSTAKE_JUDGE_URL}/${ID}`)
export const SweepstakeMemberAlreadyAddedGetApi = (ID) =>
    API.get(`${URL.GET_ALL_SWEEPSTAKE_ALREADY_ADDED_MEMBER}/${ID}`)

export const deleteSweepStakeExhibitorApi = (ID) =>
    API.del(`${URL.DELETE_SWEEPSTAKE_EXHIBITOR_URL}/${ID}`)
