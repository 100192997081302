import { createAsyncThunk } from '@reduxjs/toolkit'
import * as MemberShipIDConfig from './MemberIDConfiguration.services'

export const MemberShipIDConfigurationList = createAsyncThunk(
    '/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MemberShipIDConfig.MemberShipIDConfigurationListApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const MemberShipIDConfigurationCreate = createAsyncThunk(
    '/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MemberShipIDConfig.MemberShipIDConfigurationCreateApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const MemberShipIDConfigurationUpdate = createAsyncThunk(
    '/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MemberShipIDConfig.MemberShipIDConfigurationUpdateApi(
                data?.membership_id_config_id,
                data
            )

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
