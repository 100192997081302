import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const SanctionIDConfigurationListApi = (data) =>
    API.get(`${URL.SANCTION_ID_CONFIGURATION_LIST}`, data)
export const SanctionIDConfigurationCreateApi = (data) =>
    API.post(`${URL.SANCTION_ID_CONFIGURATION_CREATE}`, data)
export const SanctionIDConfigurationUpdateApi = (ID, data) =>
    API.put(`${URL.SANCTION_ID_CONFIGURATION_UPDATE}/${ID}`, data)
