import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const ClerkListApi = (data) => API.get(`${URL.CLERK_LIST_URL}`, data)
export const ClerkCreateApi = (data) =>
    API.post(`${URL.CLERK_CREATE_URL}`, data.body, {
        params: data.params
    })
export const ClerkByIdApi = (Id, params) => API.get(`${URL.CLERK_ONE_URL}/${Id}`, params)
export const ClerkUpdateApi = (Id, data) =>
    API.put(`${URL.CLERK_UPDATE_URL}/${Id}`, data.body, { params: data.params })
export const ClerkDeleteApi = (Id, params) => API.del(`${URL.CLERK_DELETE_URL}/${Id}`, { params })
