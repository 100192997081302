import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    createClubApi,
    deleteClubApi,
    getOneClubApi,
    getAllBreedCategoryApi,
    getAllBreedApi,
    saveClubTypeApi,
    getClubTypeApi
} from './sweepstake.services'

export const createClub = createAsyncThunk(
    '@setting/club/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await createClubApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const getOneClub = createAsyncThunk(
    '@setting/club/getOne',
    async (data, { rejectWithValue }) => {
        try {
            const res = await getOneClubApi(data)

            return res?.data || null
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const getOneClubForShowSetup = createAsyncThunk(
    '@setting/club/getOne/showSetup',
    async (data, { rejectWithValue }) => {
        try {
            const res = await getOneClubApi(data)

            return res?.data || null
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const getAllBreedCategory = createAsyncThunk(
    '@setting/club/allBreedCategory',
    async (data, { rejectWithValue }) => {
        try {
            const res = await getAllBreedCategoryApi(data)

            return res?.data || null
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const getAllBreed = createAsyncThunk(
    '@setting/club/allBreed',
    async (data, { rejectWithValue }) => {
        try {
            const res = await getAllBreedApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const deleteClub = createAsyncThunk(
    '@setting/club/delete',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await deleteClubApi(ID)
            return res.data
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const saveClubType = createAsyncThunk(
    '@setting/clubType/save',
    async (data, { rejectWithValue }) => {
        try {
            const res = await saveClubTypeApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const getClubType = createAsyncThunk(
    '@setting/clubType/get',
    async (data, { rejectWithValue }) => {
        try {
            const res = await getClubTypeApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
