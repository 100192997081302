import { createSlice } from '@reduxjs/toolkit'
import {
    ClubSubUserById,
    ClubSubUserCreate,
    ClubSubUserList,
    ClubSubUserListForMemberCreate
} from './thunk'
import _ from 'lodash'
import { getDateWithFormat } from 'src/helpers/dateTimeHelper'

export const clubSubUserForm = {
    parent_name: '',
    first_name: '',
    last_name: '',
    arba_number: '',
    is_minor: false,
    date_of_birth: '',
    active: true
}

export const initialState = {
    list: [],
    form: clubSubUserForm,
    loading: true
}
const ClubSubUser = createSlice({
    name: 'clubSubUser',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(ClubSubUserList.pending, (state) => {
            state.loading = true
            state.list = []
        })
        builder.addCase(ClubSubUserList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.form = clubSubUserForm
            state.list = data
            state.loading = false
        })
        builder.addCase(ClubSubUserList.rejected, (state) => {
            state.loading = false
            state.list = []
        })
        builder.addCase(ClubSubUserListForMemberCreate.pending, (state) => {
            state.loading = true
            state.list = []
        })
        builder.addCase(ClubSubUserListForMemberCreate.fulfilled, (state, action) => {
            const { data } = action.payload

            const finalData = Array.isArray(data)
                ? data.map((user) => ({
                      ...user,
                      value: user?.id || '',
                      label: `${user?.last_name} ${user?.first_name}`
                  }))
                : []
            state.list = finalData
            state.loading = false
        })
        builder.addCase(ClubSubUserListForMemberCreate.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(ClubSubUserCreate.pending, (state) => {
            state.loading = false
        })
        builder.addCase(ClubSubUserCreate.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(ClubSubUserCreate.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(ClubSubUserById.pending, (state) => {
            state.loading = false
        })
        builder.addCase(ClubSubUserById.fulfilled, (state, action) => {
            const { payload } = action
            const data = {
                ...payload.data,
                active: payload.data.active ? true : false,
                date_of_birth: payload.data.date_of_birth
                    ? new Date(getDateWithFormat(payload.data.date_of_birth))
                    : '',
                is_minor: payload.data.is_minor === 'Y' ? true : false
            }

            const finalData = _.pick(data, Object.keys(clubSubUserForm))

            state.form = finalData
            state.loading = false
        })
        builder.addCase(ClubSubUserById.rejected, (state) => {
            state.loading = false
        })
    }
})

// export const {} = SetupFee.actions
export default ClubSubUser.reducer
