export const MEMBER_ID_CONFIGURATION_NUMERIC_WITH_ALIPHATIC = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
    { label: 'D', value: 'D' },
    { label: 'E', value: 'E' },
    { label: 'F', value: 'F' },
    { label: 'G', value: 'G' },
    { label: 'H', value: 'H' },
    { label: 'I', value: 'I' },
    { label: 'J', value: 'J' },
    { label: 'K', value: 'K' },
    { label: 'L', value: 'L' },
    { label: 'M', value: 'M' },
    { label: 'N', value: 'N' },
    { label: 'O', value: 'O' },
    { label: 'P', value: 'P' },
    { label: 'Q', value: 'Q' },
    { label: 'R', value: 'R' },
    { label: 'S', value: 'S' },
    { label: 'T', value: 'T' },
    { label: 'U', value: 'U' },
    { label: 'V', value: 'V' },
    { label: 'W', value: 'W' },
    { label: 'X', value: 'X' },
    { label: 'Y', value: 'Y' },
    { label: 'Z', value: 'Z' }
]

export const MEMBER_ID_CONFIGURATION_ONLY_NUMERIC = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' }
]

export const MEMBERSHIP_AGE_OPTIONS = [
    { value: 0, label: 0 },
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 13, label: 13 },
    { value: 14, label: 14 },
    { value: 15, label: 15 },
    { value: 16, label: 16 },
    { value: 17, label: 17 },
    { value: 18, label: 18 },
    { value: 19, label: 19 },
    { value: 20, label: 20 },
    { value: 21, label: 21 },
    { value: 22, label: 22 },
    { value: 23, label: 23 },
    { value: 24, label: 24 },
    { value: 25, label: 25 },
    { value: 26, label: 26 },
    { value: 27, label: 27 },
    { value: 28, label: 28 },
    { value: 29, label: 29 },
    { value: 30, label: 30 },
    { value: 31, label: 31 },
    { value: 32, label: 32 },
    { value: 33, label: 33 },
    { value: 34, label: 34 },
    { value: 35, label: 35 },
    { value: 36, label: 36 },
    { value: 37, label: 37 },
    { value: 38, label: 38 },
    { value: 39, label: 39 },
    { value: 40, label: 40 },
    { value: 41, label: 41 },
    { value: 42, label: 42 },
    { value: 43, label: 43 },
    { value: 44, label: 44 },
    { value: 45, label: 45 },
    { value: 46, label: 46 },
    { value: 47, label: 47 },
    { value: 48, label: 48 },
    { value: 49, label: 49 },
    { value: 50, label: 50 },
    { value: 51, label: 51 },
    { value: 52, label: 52 },
    { value: 53, label: 53 },
    { value: 54, label: 54 },
    { value: 55, label: 55 },
    { value: 56, label: 56 },
    { value: 57, label: 57 },
    { value: 58, label: 58 },
    { value: 59, label: 59 },
    { value: 60, label: 60 },
    { value: 61, label: 61 },
    { value: 62, label: 62 },
    { value: 63, label: 63 },
    { value: 64, label: 64 },
    { value: 65, label: 65 },
    { value: 66, label: 66 },
    { value: 67, label: 67 },
    { value: 68, label: 68 },
    { value: 69, label: 69 },
    { value: 70, label: 70 },
    { value: 71, label: 71 },
    { value: 72, label: 72 },
    { value: 73, label: 73 },
    { value: 74, label: 74 },
    { value: 75, label: 75 },
    { value: 76, label: 76 },
    { value: 77, label: 77 },
    { value: 78, label: 78 },
    { value: 79, label: 79 },
    { value: 80, label: 80 },
    { value: 81, label: 81 },
    { value: 82, label: 82 },
    { value: 83, label: 83 },
    { value: 84, label: 84 },
    { value: 85, label: 85 },
    { value: 86, label: 86 },
    { value: 87, label: 87 },
    { value: 88, label: 88 },
    { value: 89, label: 89 },
    { value: 90, label: 90 },
    { value: 91, label: 91 },
    { value: 92, label: 92 },
    { value: 93, label: 93 },
    { value: 94, label: 94 },
    { value: 95, label: 95 },
    { value: 96, label: 96 },
    { value: 97, label: 97 },
    { value: 98, label: 98 },
    { value: 99, label: 99 },
    { value: 100, label: 100 }
]

export const MEMBERSHIP_ADDITIONAL_CHARACTERS = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
    { value: 'E', label: 'E' },
    { value: 'F', label: 'F' },
    { value: 'G', label: 'G' },
    { value: 'H', label: 'H' },
    { value: 'I', label: 'I' },
    { value: 'J', label: 'J' },
    { value: 'K', label: 'K' },
    { value: 'L', label: 'L' },
    { value: 'M', label: 'M' },
    { value: 'N', label: 'N' },
    { value: 'O', label: 'O' },
    { value: 'P', label: 'P' },
    { value: 'Q', label: 'Q' },
    { value: 'R', label: 'R' },
    { value: 'S', label: 'S' },
    { value: 'T', label: 'T' },
    { value: 'U', label: 'U' },
    { value: 'V', label: 'V' },
    { value: 'W', label: 'W' },
    { value: 'X', label: 'X' },
    { value: 'Y', label: 'Y' },
    { value: 'Z', label: 'Z' }
]

export const SWEEPSTAKE_POINT_TYPES = {
    class: [
        {
            value: 'none',
            label: 'None'
        },
        {
            value: 'set_amt',
            label: 'Set Amount'
        },
        {
            value: 'all',
            label: 'Total Animals'
        }
    ],
    fur: [
        {
            value: 'none',
            label: 'None'
        },
        {
            value: 'set_amt',
            label: 'Set Amount'
        },
        {
            value: 'all',
            label: 'Total Animals'
        }
    ],
    variety: {
        BOV: [
            {
                value: 'none',
                label: 'None'
            },
            {
                value: 'set_amt',
                label: 'Set Amount'
            },
            {
                value: 'all',
                label: 'Total Animals'
            }
        ],
        BOSV: [
            {
                value: 'none',
                label: 'None'
            },
            {
                value: 'set_amt',
                label: 'Set Amount'
            },
            {
                value: 'all',
                label: 'Total Animals'
            },
            {
                value: 'same_sex',
                label: 'Animals with Same Sex'
            }
        ]
    },
    group: {
        BOG: [
            {
                value: 'none',
                label: 'None'
            },
            {
                value: 'set_amt',
                label: 'Set Amount'
            },
            {
                value: 'all',
                label: 'Total Animals'
            }
        ],
        BOSG: [
            {
                value: 'none',
                label: 'None'
            },
            {
                value: 'set_amt',
                label: 'Set Amount'
            },
            {
                value: 'all',
                label: 'Total Animals'
            },
            {
                value: 'same_sex',
                label: 'Animals with Same Sex'
            }
        ]
    },
    breed: {
        BOB: [
            {
                value: 'none',
                label: 'None'
            },
            {
                value: 'set_amt',
                label: 'Set Amount'
            },
            {
                value: 'all',
                label: 'Total Animals'
            }
        ],
        BOSB: [
            {
                value: 'none',
                label: 'None'
            },
            {
                value: 'set_amt',
                label: 'Set Amount'
            },
            {
                value: 'all',
                label: 'Total Animals'
            },
            {
                value: 'same_sex',
                label: 'Animals with Same Sex'
            }
        ]
    },
    other: [
        {
            value: 'none',
            label: 'None'
        },
        {
            value: 'set_amt',
            label: 'Set Amount'
        },
        {
            value: 'all',
            label: 'Total Animals'
        }
    ]
}

export const NO_OF_PLACEMENTS = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 13, label: 13 },
    { value: 14, label: 14 },
    { value: 15, label: 15 },
    { value: 16, label: 16 },
    { value: 17, label: 17 },
    { value: 18, label: 18 },
    { value: 19, label: 19 },
    { value: 20, label: 20 },
    { value: 21, label: 21 },
    { value: 22, label: 22 },
    { value: 23, label: 23 },
    { value: 24, label: 24 },
    { value: 25, label: 25 },
    { value: 26, label: 26 },
    { value: 27, label: 27 },
    { value: 28, label: 28 },
    { value: 29, label: 29 },
    { value: 30, label: 30 },
    { value: 31, label: 31 },
    { value: 32, label: 32 },
    { value: 33, label: 33 },
    { value: 34, label: 34 },
    { value: 35, label: 35 },
    { value: 36, label: 36 },
    { value: 37, label: 37 },
    { value: 38, label: 38 },
    { value: 39, label: 39 },
    { value: 40, label: 40 },
    { value: 41, label: 41 },
    { value: 42, label: 42 },
    { value: 43, label: 43 },
    { value: 44, label: 44 },
    { value: 45, label: 45 },
    { value: 46, label: 46 },
    { value: 47, label: 47 },
    { value: 48, label: 48 },
    { value: 49, label: 49 },
    { value: 50, label: 50 },
    { value: 51, label: 51 },
    { value: 52, label: 52 },
    { value: 53, label: 53 },
    { value: 54, label: 54 },
    { value: 55, label: 55 },
    { value: 56, label: 56 },
    { value: 57, label: 57 },
    { value: 58, label: 58 },
    { value: 59, label: 59 },
    { value: 60, label: 60 },
    { value: 61, label: 61 },
    { value: 62, label: 62 },
    { value: 63, label: 63 },
    { value: 64, label: 64 },
    { value: 65, label: 65 },
    { value: 66, label: 66 },
    { value: 67, label: 67 },
    { value: 68, label: 68 },
    { value: 69, label: 69 },
    { value: 70, label: 70 },
    { value: 71, label: 71 },
    { value: 72, label: 72 },
    { value: 73, label: 73 },
    { value: 74, label: 74 },
    { value: 75, label: 75 },
    { value: 76, label: 76 },
    { value: 77, label: 77 },
    { value: 78, label: 78 },
    { value: 79, label: 79 },
    { value: 80, label: 80 },
    { value: 81, label: 81 },
    { value: 82, label: 82 },
    { value: 83, label: 83 },
    { value: 84, label: 84 },
    { value: 85, label: 85 },
    { value: 86, label: 86 },
    { value: 87, label: 87 },
    { value: 88, label: 88 },
    { value: 89, label: 89 },
    { value: 90, label: 90 },
    { value: 91, label: 91 },
    { value: 92, label: 92 },
    { value: 93, label: 93 },
    { value: 94, label: 94 },
    { value: 95, label: 95 },
    { value: 96, label: 96 },
    { value: 97, label: 97 },
    { value: 98, label: 98 },
    { value: 99, label: 99 },
    { value: 100, label: 100 }
]

export const BREED_REPORT_HEAD_SEQ = [
    { displayOrder: 1, key: 'CLASS', value: 'CLASS', label: 'CLASS', header: 'Class' },

    { displayOrder: 2, key: 'BIS', value: 'BIS', label: 'BIS', header: 'BIS' },
    { displayOrder: 3, key: 'BRIS', value: 'BRIS', label: 'BRIS', header: 'BRIS' },
    { displayOrder: 4, key: 'BIS_CAVY', value: 'BIS-CAVY', label: 'BIS-CAVY', header: 'BIS-CAVY' },
    {
        displayOrder: 5,
        key: 'BRIS_CAVY',
        value: 'BRIS-CAVY',
        label: 'BRIS-CAVY',
        header: 'BRIS-CAVY'
    },
    { displayOrder: 6, key: 'B4C', value: 'B4C', label: 'B4C', header: 'B4C' },
    { displayOrder: 7, key: 'B6C', value: 'B6C', label: 'B6C', header: 'B6C' },
    { displayOrder: 8, key: 'BGIS', value: 'BGIS', label: 'BGIS', header: 'BGIS' },
    { displayOrder: 9, key: 'BOB', value: 'BOB', label: 'BOB', header: 'BOB' },
    { displayOrder: 10, key: 'BOSB', value: 'BOSB', label: 'BOSB', header: 'BOSB' },
    { displayOrder: 11, key: 'BOG', value: 'BOG', label: 'BOG', header: 'BOG' },
    { displayOrder: 12, key: 'BOSG', value: 'BOSG', label: 'BOSG', header: 'BOSG' },
    { displayOrder: 13, key: 'BOV', value: 'BOV', label: 'BOV', header: 'BOV' },
    { displayOrder: 14, key: 'BOSV', value: 'BOSV', label: 'BOSV', header: 'BOSV' },

    { displayOrder: 15, key: 'BREED', value: 'BREED', label: 'breed', header: 'Breed' },
    { displayOrder: 16, key: 'GROUP', value: 'GROUP', label: 'group', header: 'Group' },
    { displayOrder: 17, key: 'VARIETY', value: 'VARIETY', label: 'variety', header: 'Variety' },
    { displayOrder: 18, key: 'FUR', value: 'FUR', label: 'fur', header: 'Fur' },
    { displayOrder: 19, key: 'OTHER', value: 'OTHER', label: 'other', header: 'Other' },
    {
        displayOrder: 20,
        key: '2nd_RUNNER_UP',
        value: '2nd-RUNNER-UP',
        label: '2nd-RUNNER-UP',
        header: '2nd-RUNNER-UP'
    },

    { displayOrder: 21, key: 'BFOC', value: 'BFOC', label: 'BFOC', header: 'BFOC' },
    { displayOrder: 22, key: 'BFOW', value: 'BFOW', label: 'BFOW', header: 'BFOW' }
]

export const EXH_REPORT_HEAD_SEQ = [
    { displayOrder: 1, key: 'class', value: 'class', label: 'Class', header: 'Class' },

    {
        displayOrder: 2,
        key: 'show',
        value: 'show',
        label: 'Best Show (BIS/BRIS)',
        header: 'BIS/BRIS'
    },
    {
        displayOrder: 3,
        key: 'show_cavy',
        value: 'show_cavy',
        label: 'Best Show Cavy (BIS-CAVY/BRIS-CAVY)',
        header: 'BIS-CAVY/BRIS-CAVY'
    },
    {
        displayOrder: 4,
        key: 'best_class_type',
        value: 'best_class_type',
        label: 'Best Class (B4C/B6C)',
        header: 'B4C/B6C'
    },

    {
        displayOrder: 5,
        key: 'breed',
        value: 'breed',
        label: 'Breed (BOB/BOSB)',
        header: 'BOB/BOSB'
    },
    {
        displayOrder: 6,
        key: 'group',
        value: 'group',
        label: 'Group (BOG/BOSG)',
        header: 'BOG/BOSG'
    },
    {
        displayOrder: 7,
        key: 'variety',
        value: 'variety',
        label: 'Variety (BOV/BOSV)',
        header: 'BOV/BOSV'
    },
    {
        displayOrder: 8,
        key: 'fur',
        value: 'fur',
        label: 'Fur/Wool (BFOC/BFOW)',
        header: 'BFOC/BFOW'
    },

    {
        displayOrder: 9,
        key: 'show_cavy_runner_up',
        value: 'show_cavy_runner_up',
        label: '',
        header: ''
    }
]

export const NewsLetterBreed = [
    {
        label: 'Cavy Breed List',
        value: 'cavy' // breed name and id
    },
    {
        label: 'Rabbit Breed List',
        value: 'rabbit' // breed name and id
    }
]

export const MEMBERSHIP_REMINDER_SETUP_OPTIONS = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
    { label: 11, value: 11 },
    { label: 12, value: 12 },
    { label: 13, value: 13 },
    { label: 14, value: 14 },
    { label: 15, value: 15 },
    { label: 16, value: 16 },
    { label: 17, value: 17 },
    { label: 18, value: 18 },
    { label: 19, value: 19 },
    { label: 20, value: 20 },
    { label: 21, value: 21 },
    { label: 22, value: 22 },
    { label: 23, value: 23 },
    { label: 24, value: 24 },
    { label: 25, value: 25 },
    { label: 26, value: 26 },
    { label: 27, value: 27 },
    { label: 28, value: 28 },
    { label: 29, value: 29 },
    { label: 30, value: 30 },
    { label: 31, value: 31 },
    { label: 32, value: 32 },
    { label: 33, value: 33 },
    { label: 34, value: 34 },
    { label: 35, value: 35 },
    { label: 36, value: 36 },
    { label: 37, value: 37 },
    { label: 38, value: 38 },
    { label: 39, value: 39 },
    { label: 40, value: 40 },
    { label: 41, value: 41 },
    { label: 42, value: 42 },
    { label: 43, value: 43 },
    { label: 44, value: 44 },
    { label: 45, value: 45 },
    { label: 46, value: 46 },
    { label: 47, value: 47 },
    { label: 48, value: 48 },
    { label: 49, value: 49 },
    { label: 50, value: 50 },
    { label: 51, value: 51 },
    { label: 52, value: 52 },
    { label: 53, value: 53 },
    { label: 54, value: 54 },
    { label: 55, value: 55 },
    { label: 56, value: 56 },
    { label: 57, value: 57 },
    { label: 58, value: 58 },
    { label: 59, value: 59 },
    { label: 60, value: 60 },
    { label: 61, value: 61 },
    { label: 62, value: 62 },
    { label: 63, value: 63 },
    { label: 64, value: 64 },
    { label: 65, value: 65 },
    { label: 66, value: 66 },
    { label: 67, value: 67 },
    { label: 68, value: 68 },
    { label: 69, value: 69 },
    { label: 70, value: 70 },
    { label: 71, value: 71 },
    { label: 72, value: 72 },
    { label: 73, value: 73 },
    { label: 74, value: 74 },
    { label: 75, value: 75 },
    { label: 76, value: 76 },
    { label: 77, value: 77 },
    { label: 78, value: 78 },
    { label: 79, value: 79 },
    { label: 80, value: 80 },
    { label: 81, value: 81 },
    { label: 82, value: 82 },
    { label: 83, value: 83 },
    { label: 84, value: 84 },
    { label: 85, value: 85 },
    { label: 86, value: 86 },
    { label: 87, value: 87 },
    { label: 88, value: 88 },
    { label: 89, value: 89 },
    { label: 90, value: 90 },
    { label: 91, value: 91 },
    { label: 92, value: 92 },
    { label: 93, value: 93 },
    { label: 94, value: 94 },
    { label: 95, value: 95 },
    { label: 96, value: 96 },
    { label: 97, value: 97 },
    { label: 98, value: 98 },
    { label: 99, value: 99 },
    { label: 100, value: 100 },
    { label: 101, value: 101 },
    { label: 102, value: 102 },
    { label: 103, value: 103 },
    { label: 104, value: 104 },
    { label: 105, value: 105 },
    { label: 106, value: 106 },
    { label: 107, value: 107 },
    { label: 108, value: 108 },
    { label: 109, value: 109 },
    { label: 110, value: 110 },
    { label: 111, value: 111 },
    { label: 112, value: 112 },
    { label: 113, value: 113 },
    { label: 114, value: 114 },
    { label: 115, value: 115 },
    { label: 116, value: 116 },
    { label: 117, value: 117 },
    { label: 118, value: 118 },
    { label: 119, value: 119 },
    { label: 120, value: 120 },
    { label: 121, value: 121 },
    { label: 122, value: 122 },
    { label: 123, value: 123 },
    { label: 124, value: 124 },
    { label: 125, value: 125 },
    { label: 126, value: 126 },
    { label: 127, value: 127 },
    { label: 128, value: 128 },
    { label: 129, value: 129 },
    { label: 130, value: 130 },
    { label: 131, value: 131 },
    { label: 132, value: 132 },
    { label: 133, value: 133 },
    { label: 134, value: 134 },
    { label: 135, value: 135 },
    { label: 136, value: 136 },
    { label: 137, value: 137 },
    { label: 138, value: 138 },
    { label: 139, value: 139 },
    { label: 140, value: 140 },
    { label: 141, value: 141 },
    { label: 142, value: 142 },
    { label: 143, value: 143 },
    { label: 144, value: 144 },
    { label: 145, value: 145 },
    { label: 146, value: 146 },
    { label: 147, value: 147 },
    { label: 148, value: 148 },
    { label: 149, value: 149 },
    { label: 150, value: 150 }
]

export const SANCTION_DEADLINE = [
    { value: '', label: 'Select Deadline' },
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 13, label: 13 },
    { value: 14, label: 14 },
    { value: 15, label: 15 },
    { value: 16, label: 16 },
    { value: 17, label: 17 },
    { value: 18, label: 18 },
    { value: 19, label: 19 },
    { value: 20, label: 20 },
    { value: 21, label: 21 },
    { value: 22, label: 22 },
    { value: 23, label: 23 },
    { value: 24, label: 24 },
    { value: 25, label: 25 },
    { value: 26, label: 26 },
    { value: 27, label: 27 },
    { value: 28, label: 28 },
    { value: 29, label: 29 },
    { value: 30, label: 30 },
    { value: 31, label: 31 },
    { value: 32, label: 32 },
    { value: 33, label: 33 },
    { value: 34, label: 34 },
    { value: 35, label: 35 },
    { value: 36, label: 36 },
    { value: 37, label: 37 },
    { value: 38, label: 38 },
    { value: 39, label: 39 },
    { value: 40, label: 40 },
    { value: 41, label: 41 },
    { value: 42, label: 42 },
    { value: 43, label: 43 },
    { value: 44, label: 44 },
    { value: 45, label: 45 },
    { value: 46, label: 46 },
    { value: 47, label: 47 },
    { value: 48, label: 48 },
    { value: 49, label: 49 },
    { value: 50, label: 50 },
    { value: 51, label: 51 },
    { value: 52, label: 52 },
    { value: 53, label: 53 },
    { value: 54, label: 54 },
    { value: 55, label: 55 },
    { value: 56, label: 56 },
    { value: 57, label: 57 },
    { value: 58, label: 58 },
    { value: 59, label: 59 },
    { value: 60, label: 60 },
    { value: 61, label: 61 },
    { value: 62, label: 62 },
    { value: 63, label: 63 },
    { value: 64, label: 64 },
    { value: 65, label: 65 },
    { value: 66, label: 66 },
    { value: 67, label: 67 },
    { value: 68, label: 68 },
    { value: 69, label: 69 },
    { value: 70, label: 70 },
    { value: 71, label: 71 },
    { value: 72, label: 72 },
    { value: 73, label: 73 },
    { value: 74, label: 74 },
    { value: 75, label: 75 },
    { value: 76, label: 76 },
    { value: 77, label: 77 },
    { value: 78, label: 78 },
    { value: 79, label: 79 },
    { value: 80, label: 80 },
    { value: 81, label: 81 },
    { value: 82, label: 82 },
    { value: 83, label: 83 },
    { value: 84, label: 84 },
    { value: 85, label: 85 },
    { value: 86, label: 86 },
    { value: 87, label: 87 },
    { value: 88, label: 88 },
    { value: 89, label: 89 },
    { value: 90, label: 90 },
    { value: 91, label: 91 },
    { value: 92, label: 92 },
    { value: 93, label: 93 },
    { value: 94, label: 94 },
    { value: 95, label: 95 },
    { value: 96, label: 96 },
    { value: 97, label: 97 },
    { value: 98, label: 98 },
    { value: 99, label: 99 },
    { value: 100, label: 100 }
]

export const SHOW_TYPE_OPTIONS = [
    {
        value: '',
        label: 'Select show type name'
    },
    {
        value: 'open',
        label: 'Open'
    },
    {
        value: 'youth',
        label: 'Youth'
    }
]

export const SANCTION_NUMBER_OF_SHOW_PURCHASE = [
    { value: '', label: 0 },
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 13, label: 13 },
    { value: 14, label: 14 },
    { value: 15, label: 15 },
    { value: 16, label: 16 },
    { value: 17, label: 17 },
    { value: 18, label: 18 },
    { value: 19, label: 19 },
    { value: 20, label: 20 }
]

export const CLERK_MENULIST = [
    {
        label: 'Setting',
        value: 'setting',
        selected: false,
        childrenMenuItems: [
            {
                label: 'Club Selection',
                value: 'club-selection',
                id: 1,
                selected: false
            },
            {
                label: 'Report Type',
                value: 'report-type',
                id: 2,
                selected: false
            },
            {
                label: `Contest's`,
                value: 'contest',
                id: 3,
                selected: false
            }
        ]
    },
    {
        label: 'Membership',
        value: 'membership',
        selected: false,
        childrenMenuItems: [
            {
                label: 'ID Configuration',
                value: 'membership-id-configuration',
                id: 4,
                selected: false
            },
            {
                label: 'Age Classification',
                value: 'age_classification',
                id: 5,
                selected: false
            },
            {
                label: 'Membership Plans',
                value: 'membership_plans',
                id: 6,
                selected: false
            },
            {
                label: `Membership List`,
                value: 'membership-list',
                id: 7,
                selected: false
            },
            {
                label: `Letter Template`,
                value: 'letter_template',
                id: 8,
                selected: false
            },
            {
                label: `Renew Letter Template`,
                value: 'renew-letter-template',
                id: 9,
                selected: false
            },
            {
                label: `Membership Card Template`,
                value: 'membership_card_template',
                id: 10,
                selected: false
            },
            {
                label: `Newsletter`,
                value: 'news-letter',
                id: 11,
                selected: false
            },
            {
                label: `Processing fees`,
                value: 'processing_fees',
                id: 12,
                selected: false
            },
            {
                label: `Member / Alias List`,
                value: 'member_list',
                id: 13,
                selected: false
            },
            {
                label: `Membership Reminder`,
                value: 'membership-reminder',
                id: 14,
                selected: false
            }
        ]
    },
    {
        label: 'Sweepstakes',
        value: 'sweepstakes',
        selected: false,
        childrenMenuItems: [
            {
                label: 'Sweepstakes',
                value: 'sweepstake',
                id: 15,
                selected: false
            },
            {
                label: 'Show Setup',
                value: 'show-setup',
                id: 16,
                selected: false
            }
        ]
    },
    {
        label: 'Sanctions',
        value: 'sanctions',
        selected: false,
        childrenMenuItems: [
            {
                label: 'ID Configuration',
                value: 'sanction-id-configuration',
                id: 17,
                selected: false
            },
            {
                label: 'Show Classification',
                value: 'show-classification',
                id: 18,
                selected: false
            },
            {
                label: 'Sanction Plan',
                value: 'sanction-plans',
                id: 19,
                selected: false
            },
            {
                label: `Sanction List`,
                value: 'sanction-list',
                id: 20,
                selected: false
            },
            {
                label: `Sanction Template`,
                value: 'sanction-letter-template',
                id: 21,
                selected: false
            },

            {
                label: `Sanction Processing Fees`,
                value: 'sanction_processing_fees',
                id: 22,
                selected: false
            },
            {
                label: `Sanction Reminder`,
                value: 'sanction-reminder',
                id: 23,
                selected: false
            }
        ]
    }
]

export const CLERK_PATH_ASSESS_LIST = {
    1: {
        paths: ['/dashboard/setting']
    },
    2: {
        paths: ['/dashboard/report-type']
    },
    3: {
        paths: [
            '/dashboard/contest',
            '/dashboard/contest/create',
            '/dashboard/contest/update/:ID',
            '/dashboard/contest/:ID/reports/:abbr',
            '/dashboard/contest/:ID/reports',
            '/dashboard/contest/:ID/reports/pdf/variety-report'
        ]
    },
    4: {
        paths: ['/dashboard/membership-id-configuration']
    },
    5: {
        paths: [
            '/dashboard/age_classification',
            '/dashboard/age_classification/:ACID',
            '/dashboard/age_classification/create'
        ]
    },
    6: {
        paths: [
            '/dashboard/membership_plans',
            '/dashboard/membership_plans/create',
            '/dashboard/membership_plans/:ID'
        ]
    },
    7: {
        paths: [
            '/dashboard/membership-list',
            '/dashboard/membership-list/create',
            '/dashboard/membership-list/checkout/',
            '/dashboard/membership-list/checkout/process-payment'
        ]
    },
    8: {
        paths: ['/dashboard/letter_template']
    },
    9: {
        paths: ['/dashboard/renew-letter-template']
    },
    10: {
        paths: ['/dashboard/membership_card_template']
    },
    11: {
        paths: [
            '/dashboard/news-letter',
            '/dashboard/news-letter/create',
            '/dashboard/news-letter/update/:ID'
        ]
    },
    12: {
        paths: ['/dashboard/processing_fees']
    },
    13: {
        paths: [
            '/dashboard/member_list/create',
            '/dashboard/member_list/alias/create',
            '/dashboard/member_list/:ID',
            '/dashboard/member_list/:UID/sub_member_list',
            '/dashboard/member_list/:UID/sub_member_list/create',
            '/dashboard/member_list/:UID/sub_member_list/update/:ID'
        ]
    },
    14: {
        paths: [
            '/dashboard/membership-reminder-setup',
            '/dashboard/membership-reminder-setup/create',
            '/dashboard/membership-reminder-setup/update/:ID',
            '/dashboard/membership-reminder-template',
            '/dashboard/membership-reminder-log',
            '/dashboard/membership-reminder-log/:ID'
        ]
    },
    15: {
        paths: [
            '/dashboard/sweepstake-class-national',
            '/dashboard/sweepstake-class-national/create',
            '/dashboard/sweepstake-class-national/update/:ID',
            '/dashboard/sweepstake-class-non-national',
            '/dashboard/sweepstake-class-non-national/create',
            '/dashboard/sweepstake-class-non-national/update/:ID',
            '/dashboard/sweepstake-fur-national',
            '/dashboard/sweepstake-fur-national/create',
            '/dashboard/sweepstake-fur-national/update/:ID',
            '/dashboard/sweepstake-fur-non-national',
            '/dashboard/sweepstake-fur-non-national/create',
            '/dashboard/sweepstake-fur-non-national/update/:ID',
            '/dashboard/sweepstake-variety-national',
            '/dashboard/sweepstake-variety-national/create',
            '/dashboard/sweepstake-variety-national/update/:ID',
            '/dashboard/sweepstake-group-national',
            '/dashboard/sweepstake-group-national/create',
            '/dashboard/sweepstake-group-national/update/:ID',
            '/dashboard/sweepstake-breed-national',
            '/dashboard/sweepstake-breed-national/create',
            '/dashboard/sweepstake-breed-national/update/:ID',
            '/dashboard/sweepstake-other-national',
            '/dashboard/sweepstake-other-national/create',
            '/dashboard/sweepstake-other-national/update/:ID',
            '/dashboard/sweepstake-variety-non-national',
            '/dashboard/sweepstake-variety-non-national/create',
            '/dashboard/sweepstake-variety-non-national/update/:ID',
            '/dashboard/sweepstake-group-non-national',
            '/dashboard/sweepstake-group-non-national/create',
            '/dashboard/sweepstake-group-non-national/update/:ID',
            '/dashboard/sweepstake-breed-non-national',
            '/dashboard/sweepstake-breed-non-national/create',
            '/dashboard/sweepstake-breed-non-national/update/:ID',
            '/dashboard/sweepstake-other-non-national',
            '/dashboard/sweepstake-other-non-national/create',
            '/dashboard/sweepstake-other-non-national/update/:ID'
        ]
    },
    16: {
        paths: [
            '/dashboard/show-setup',
            '/dashboard/show-setup/create',
            '/dashboard/show-setup/update/:ID',
            '/dashboard/show-setup/:SID/judge',
            '/dashboard/show-setup/:SID/judge/create',
            '/dashboard/show-setup/:SID/judge/update/:JID',
            '/dashboard/show-setup/exhibitor/:showID',
            '/dashboard/show-setup/exhibitor/:showID/create',
            '/dashboard/show-setup/exhibitor/:showID/update/:ID',
            '/dashboard/show-setup/:SID/national_controlsheet',
            '/dashboard/show-setup/:SID/national_controlsheet/:reportType',
            '/dashboard/show-setup/:SID/state_controlsheet',
            '/dashboard/show-setup/:SID/state_controlsheet/:abbr'
        ]
    },

    17: { paths: ['/dashboard/sanction-id-configuration'] },
    18: {
        paths: [
            '/dashboard/show-classification',
            '/dashboard/show-classification/create',
            '/dashboard/show-classification/update/:ID'
        ]
    },
    19: {
        paths: [
            '/dashboard/sanction-plans',
            '/dashboard/sanction-plans/create',
            '/dashboard/sanction-plans/update/:ID'
        ]
    },
    20: {
        paths: [
            '/dashboard/sanction-list',
            '/dashboard/sanction-list/create',
            '/dashboard/sanction-list/create/plan',
            '/dashboard/sanction-list/create/plan/checkout',
            '/dashboard/sanction-list/create/plan/checkout/process-payment'
        ]
    },
    21: {
        paths: ['/dashboard/sanction-letter-template']
    },

    22: {
        paths: ['/dashboard/sanction_processing_fees']
    },
    23: {
        paths: [
            '/dashboard/sanction-reminder-setup',
            '/dashboard/sanction-reminder-setup/create',
            '/dashboard/sanction-reminder-setup/update/:ID',
            '/dashboard/sanction-reminder-template',
            '/dashboard/sanction-reminder-log',
            '/dashboard/sanction-reminder-log/:ID'
        ]
    }
}

export const GRACE_PERIOD_DAYS = [
    { value: '', label: 'Select Grace period' },
    { value: 30, label: '30' },
    { value: 60, label: '60' },
    { value: 90, label: '90' }
]