import { createAsyncThunk } from '@reduxjs/toolkit'
import { ClubGrandTotalApi, PlanPaymentHistoryMemberApi } from './MemberPlanHistory.services'
import { paginationData } from '../Pagination/reducers'

export const PlanPaymentHistoryMember = createAsyncThunk(
    '/Plans/Payment/Member/history',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await PlanPaymentHistoryMemberApi(data)
            if (data._limit) {
                // Pagination Section
                if (Number(data._limit) > 1) {
                    const limit = data._limit || 10
                    const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                    const paginationObj = {
                        page: data?._page || 1,
                        totalPage: TotalPage || 1,
                        totalRecords: res?.totalRecords || 1
                    }
                    dispatch(paginationData(paginationObj))
                }
            }
            return res
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const ClubGrandTotal = createAsyncThunk(
    'Club/GrandTotal',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await ClubGrandTotalApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
