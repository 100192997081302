import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const SanctionPlanListApi = (data) => API.get(`${URL.SANCTION_PLAN_LIST_URL}`, data)

export const SanctionPlanByIdApi = (ID) => API.get(`${URL.SANCTION_PLAN_ONE_URL}/${ID}`)

export const SanctionPlanCreateApi = (data) => API.post(`${URL.SANCTION_PLAN_CREATE_URL}`, data)

export const SanctionPlanUpdateApi = (ID, data) =>
    API.put(`${URL.SANCTION_PLAN_UPDATE_URL}/${ID}`, data)

export const SanctionPlanDeleteApi = (ID) => API.del(`${URL.SANCTION_PLAN_DELETE_URL}/${ID}`)
export const SanctionPlanStatusApi = (Id, data) =>
    API.put(`${URL.SANCTION_PLANS_PUBLISH_STATUS}/${Id}`, data)
