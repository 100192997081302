import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

export const controlSheetBreedDetailApi = (data) =>
    API.post(`${URL.GET_CONTROL_SHEET_BREED_DETAIL}`, data)

export const settingDetailApi = (data) => API.get(`${URL.SETTING_GET_ALL_CLUB}`, data)
export const breedListApi = (data) => API.get(`${URL.GET_ALL_BREED}`, data)
export const SweepstakePlacingListApi = (type, data) =>
    API.get(`${URL.GET_ALL_SWEEPSTAKE_PACING_FOR_CONTROL_SHEET_URL}`, data)

export const NationalReportExhibitorListApi = (ID, data) =>
    API.get(`${URL.GET_SWEEPSTAKE_MEMBER_LIST}/${ID}`, data)

export const settingDetailForNationalApi = (data) =>
    API.post(`${URL.GET_ALL_BREED_CONTROL_SHEET}`, data)

export const SaveControlSheetPlacingApi = (data) =>
    API.post(`${URL.SAVE_CONTROL_SHEET_PLACING}`, data)
export const SaveControlSheetExhByPointsPlacingApi = (data) =>
    API.post(`${URL.SAVE_CONTROL_SHEET_EXH_LIST_BY_POINTS_PLACING}`, data)
export const GetControlSheetExhByPointsPlacingApi = (data) =>
    API.post(`${URL.GET_CONTROL_SHEET_EXH_LIST_BY_POINTS_PLACING}`, data)

export const GetControlSheetPlacingApi = (data) =>
    API.post(`${URL.GET_CONTROL_SHEET_PLACING}`, data)

export const GetControlSheetExhListByPointsApi = (data) =>
    API.post(`${URL.GET_CONTROL_SHEET_EXH_LIST_BY_POINTS}`, data)

export const ResetControlSheetDetailByBreedApi = (data) =>
    API.post(`${URL.RESET_CONTROL_SHEET_DETAIL_BY_BREED}`, data)

export const SaveControlSheetPlacingForQualityPointsApi = (data) =>
    API.post(`${URL.SAVE_CONTROL_SHEET_PLACING_QUALITY_POINTS}`, data)

export const GetControlSheetPlacingForQualityPointsApi = (data) =>
    API.post(`${URL.GET_CONTROL_SHEET_PLACING_QUALITY_POINTS}`, data)

export const SaveControlSheetFurResultsApi = (data) =>
    API.post(`${URL.SAVE_CONTROL_SHEET_FUR_RESULT}`, data)

export const GetControlSheetFurResultApi = (data) =>
    API.post(`${URL.GET_CONTROL_SHEET_FUR_RESULT}`, data)

export const SaveCompleteExhListWithPointApi = (data) =>
    API.post(`${URL.SAVE_CONTROL_SHEET_COMPLETE_EXH_LIST_WITH_RESULT}`, data)
export const GetCompleteExhListWithPointApi = (data) =>
    API.post(`${URL.GET_CONTROL_SHEET_COMPLETE_EXH_LIST_WITH_RESULT}`, data)
export const GetDetailByBreedCompleteExhListWithPointApi = (data) =>
    API.post(`${URL.GET_DETAILS_BY_BREED_COMPLETE_EXHIBITOR_LIST_POINTS}`, data)

export const saveReportTypeApi = (data) => API.patch(`${URL.SAVE_REPORT_TYPE}`, data)

// News Letter
export const getNationalReportApi = (data) => API.post(`${URL.GET_NEWS_LETTER_URL}`, data)
export const saveNationalReportApi = (data) => API.post(`${URL.SAVE_NEWS_LETTER_URL}`, data)
