import { createAsyncThunk } from '@reduxjs/toolkit'
import { paginationData } from '../Pagination/reducers'
import {
    AgeClassificationByIdApi,
    AgeClassificationCreateApi,
    AgeClassificationDeleteApi,
    AgeClassificationListApi,
    AgeClassificationUpdateApi
} from './AgeClassification.services'

//services
export const AgeClassificationList = createAsyncThunk(
    'AgeClassification/List',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await AgeClassificationListApi(data)

            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const AgeClassificationCreate = createAsyncThunk(
    'AgeClassification/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await AgeClassificationCreateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const AgeClassificationUpdate = createAsyncThunk(
    'AgeClassification/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await AgeClassificationUpdateApi(data.age_classification_id, data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const AgeClassificationById = createAsyncThunk(
    'AgeClassification/one',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await AgeClassificationByIdApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const AgeClassificationDelete = createAsyncThunk(
    'AgeClassification/delete',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await AgeClassificationDeleteApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
