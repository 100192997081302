import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    SweepStakeMemberForFormListApi,
    SweepStakeMemberListApi,
    createSweepStakeMemberApi,
    getOneSweepStakeMemberApi,
    updateSweepStakeMemberApi
} from './sweepstakeMember.services'
import _ from 'lodash'
import { paginationData } from '../Pagination/reducers'

export const createSweepStakeMember = createAsyncThunk(
    '@Sweepstake/Member/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await createSweepStakeMemberApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const updateSweepStakeMember = createAsyncThunk(
    '@Sweepstake/Member/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await updateSweepStakeMemberApi(data.ID, _.omit(data, 'ID'))

            return res.data
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const getOneSweepStakeMember = createAsyncThunk(
    '@Sweepstake/Member/getOne',
    async (data, { rejectWithValue }) => {
        try {
            const res = await getOneSweepStakeMemberApi(data)

            return res?.data || null
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const SweepStakeMemberForFormList = createAsyncThunk(
    '@Sweepstake/Member/list/for/form',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SweepStakeMemberForFormListApi(data)

            return res?.data || null
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SweepStakeMemberList = createAsyncThunk(
    '@Sweepstake/Member/list',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await SweepStakeMemberListApi(data.showId, data)
            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res?.data || null
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
