import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    NewsLetterCreateApi,
    NewsLetterGetOneApi,
    NewsLetterListApi,
    NewsLetterUpdateApi
} from './NewsLetter.services'
import { paginationData } from '../Pagination/reducers'

export const NewsLetterList = createAsyncThunk(
    '/newsletter/list',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await NewsLetterListApi(data.club_id, data.params)
            if (Number(data?.params?._limit || 0) > 1) {
                // Pagination Section
                const limit = data?.params._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?.params?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }
            return res
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const NewsLetterCreate = createAsyncThunk(
    '/newsletter/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await NewsLetterCreateApi(data.club_id, data.data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const NewsLetterUpdate = createAsyncThunk(
    '/newsletter/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await NewsLetterUpdateApi(data.id, data.data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const NewsLetterGetOne = createAsyncThunk(
    '/newsletter/get/one',
    async (data, { rejectWithValue }) => {
        try {
            const res = await NewsLetterGetOneApi(data.club_id, data.params)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
