import classNames from 'classnames'
import { getIn } from 'formik'
import React from 'react'
import { Input, Label } from 'reactstrap'

const ERadioGroup = ({ option, disabled, TagName = React.Fragment, ...props }) => {
    const errorMessage = getIn(props?.form?.errors, props?.field?.name)

    const isTouch = getIn(props?.form?.touched, props?.field?.name)

    const onChange = (e) => {
        if (Array.isArray(props.resetvalue)) {
            // eslint-disable-next-line no-restricted-syntax
            for (const name of props.resetvalue) {
                props.form.setFieldValue(name, '', false)
            }
        }
        if (props.resetform) {
            props.form.resetForm()
        }

        props.form.setFieldValue(props.field.name, e.target.value)
    }
    return (
        <>
            {Array.isArray(option) &&
                option.map((opt, index) => {
                    return (
                        <TagName key={index} className={props?.tagClassNames || ''}>
                            <Input
                                type='radio'
                                value={opt.value}
                                checked={opt.value === props.field.value}
                                onChange={onChange}
                                className={classNames(`me-2 ${props?.className || ''}`, {
                                    'is-invalid': errorMessage && isTouch
                                })}
                                disabled={disabled}
                                id={`${props.field.name}-${opt.value}`}
                            />
                            <Label
                                className='me-4 mb-0'
                                htmlFor={`${props.field.name}-${opt.value}`}
                            >
                                {opt?.label}
                            </Label>
                        </TagName>
                    )
                })}
            {errorMessage ? <div className='text-danger mt-1'>{errorMessage}</div> : null}
            {/* <EInvalidFeedback {...errorMessage} />
            <ErrorMessage name={props?.field?.name} render={EInvalidFeedback} /> */}
        </>
    )
}

export default ERadioGroup
