import { createAsyncThunk } from '@reduxjs/toolkit'
import { paginationData } from '../Pagination/reducers'
import {
    ShowClassificationByIdApi,
    ShowClassificationCreateApi,
    ShowClassificationDeleteApi,
    ShowClassificationListApi,
    ShowClassificationUpdateApi
} from './SanctionShowClassification.services'

//services
export const ShowClassificationList = createAsyncThunk(
    'Show/Classification/List',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await ShowClassificationListApi(data)

            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const ShowClassificationCreate = createAsyncThunk(
    'Show/Classification/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ShowClassificationCreateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const ShowClassificationUpdate = createAsyncThunk(
    'Show/Classification/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ShowClassificationUpdateApi(
                data.sanction_show_classification_id,
                data
            )
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const ShowClassificationById = createAsyncThunk(
    'Show/Classification/one',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await ShowClassificationByIdApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const ShowClassificationDelete = createAsyncThunk(
    'Show/Classification/delete',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await ShowClassificationDeleteApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
