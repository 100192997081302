import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    MembershipRenewTemplateCreateApi,
    MembershipRenewTemplateDeleteApi,
    MembershipRenewTemplateListApi,
    MembershipRenewTemplateUpdateApi
} from './MemberShipRenewLetterTemplate.services'

export const MembershipRenewTemplateCreate = createAsyncThunk(
    '/membership/renew/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipRenewTemplateCreateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MembershipRenewTemplateUpdate = createAsyncThunk(
    '/membership/renew/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipRenewTemplateUpdateApi(
                data.membership_letter_template_renew_id,
                data.formData
            )
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MembershipRenewTemplateList = createAsyncThunk(
    '/membership/renew/template/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipRenewTemplateListApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MembershipRenewTemplateDelete = createAsyncThunk(
    '/membership/renew/template/delete',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipRenewTemplateDeleteApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
