import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

export const MembershipRenewTemplateCreateApi = (data) =>
    API.post(`${URL.MEMBERSHIP_RENEW_LETTER_TEMPLATE_CREATE_URL}`, data)
export const MembershipRenewTemplateUpdateApi = (ID, data) =>
    API.patch(`${URL.MEMBERSHIP_RENEW_LETTER_TEMPLATE_UPDATE_URL}/${ID}`, data)

//
export const MembershipRenewTemplateListApi = (data) =>
    API.get(`${URL.MEMBERSHIP_RENEW_LETTER_TEMPLATE_LIST_URL}`, data)
//
export const MembershipRenewTemplateDeleteApi = (ID) =>
    API.del(`${URL.MEMBERSHIP_RENEW_LETTER_TEMPLATE_DELETE_URL}/${ID}`)
