import { createSlice } from '@reduxjs/toolkit'
import { SweepStakePlacingList, SweepstakeList, getOneSweepStake, deleteSweepStake } from './thunk'

export const Form = {
    title: '',
    category: '',
    placing_type: '',
    club_id: '',
    multiplied_by: null,
    is_deactivated: 0,
    sweepstake_point_id: null,
    points_details: []
}

export const initialState = {
    form: Form,
    placingLoading: true,
    detailLoading: true,
    SweepStakeList: [],
    NoSweepStakeDataPresent: true,
    calculationType: [],
    loading: true
}

const Sweepstake = createSlice({
    name: '@Sweepstake',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(SweepstakeList.pending, (state) => {
            state.NoSweepStakeDataPresent = true
            state.loading = true
        })
        builder.addCase(SweepstakeList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.NoSweepStakeDataPresent = Array.isArray(data) && data.length ? true : false
            state.SweepStakeList = data || []
            state.loading = false
        })
        builder.addCase(SweepstakeList.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(SweepStakePlacingList.pending, (state) => {
            state.loading = true
            state.placingLoading = true
            state.detailLoading = true
        })
        builder.addCase(SweepStakePlacingList.fulfilled, (state, action) => {
            const { data } = action.payload
            if (Array.isArray(data?.placings)) {
                state.form.title = ''
                state.form.points_details = data?.placings.map((_p) => {
                    return {
                        placing: _p,
                        points: '',
                        calculation_type: '',
                        sweepstake_point_details_id: null,
                        sweepstake_point_id: null,
                        is_deactivated: 0
                    }
                })
            } else {
                state.form.points_details = []
            }
            state.calculationType = Array.isArray(data?.calculationType)
                ? data?.calculationType.map((c) => ({
                      label: c?.lbl,
                      value: c?.val
                  }))
                : []
            state.loading = false
            state.placingLoading = false
        })
        builder.addCase(SweepStakePlacingList.rejected, (state) => {
            state.loading = false
            state.placingLoading = false
        })
        builder.addCase(getOneSweepStake.pending, (state) => {
            state.detailLoading = false
        })
        builder.addCase(getOneSweepStake.fulfilled, (state, action) => {
            const { payload } = action
            state.form = payload
            state.detailLoading = false
        })
        builder.addCase(getOneSweepStake.rejected, (state) => {
            state.detailLoading = false
        })
        builder.addCase(deleteSweepStake.pending, (state) => {
            state.loading = false
        })
        builder.addCase(deleteSweepStake.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(deleteSweepStake.rejected, (state) => {
            state.loading = false
        })
    }
})

export default Sweepstake.reducer
