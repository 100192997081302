import { createAsyncThunk } from '@reduxjs/toolkit'
import { paginationData } from '../Pagination/reducers'
import {
    MemberShipPlanListApi,
    MemberShipPlanListForMemberCreateApi,
    MembershipPlanCreateApi,
    MembershipPlanDeleteApi,
    MembershipPlanStatusApi,
    MembershipPlanUpdateApi,
    MembershipPlansByIdApi
} from './MembershipPlans.services'
import { AgeClassificationListApi } from '../AgeClassification/AgeClassification.services'

//services
export const MemberShipPlansList = createAsyncThunk(
    'MemberShipPlan/List',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await MemberShipPlanListApi(data)

            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//

export const MemberShipAgeClassificationList = createAsyncThunk(
    'MemberShipAgeClassification/List',
    async (data, { rejectWithValue }) => {
        try {
            const res = await AgeClassificationListApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const MembershipPlansById = createAsyncThunk(
    'MemberShipPlan/getById',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await MembershipPlansByIdApi(data)

            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MembershipPlanDelete = createAsyncThunk(
    'MemberShipPlan/delete',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipPlanDeleteApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const MembershipPlanCreate = createAsyncThunk(
    'MembershipPlan/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipPlanCreateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MembershipPlanUpdate = createAsyncThunk(
    '/MembershipPlan/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipPlanUpdateApi(data.club_membership_plans_id, data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MembershipPlanStatus = createAsyncThunk(
    '/MembershipPlan/changes/status',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipPlanStatusApi(data.club_membership_plans_id, data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const MemberShipPlanListForMemberCreate = createAsyncThunk(
    '/membership/plan/list/for/member',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MemberShipPlanListForMemberCreateApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
