import { createAsyncThunk } from '@reduxjs/toolkit'
import { SanctionGrandTotalApi, SanctionPurchaseListApi } from './SanctionPurchaseList.services'
import { paginationData } from '../Pagination/reducers'

export const SanctionPurchaseList = createAsyncThunk(
    'Sanction/list/club',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await SanctionPurchaseListApi(data)

            if (Number(data?.params?._limit || 0) > 1) {
                // Pagination Section
                const limit = data.params._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data.params?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res.data
        } catch (err) {
            if (Number(data?.params?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }

            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SanctionGrandTotal = createAsyncThunk(
    'Sanction/GrandTotal',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionGrandTotalApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
