import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const MemberShipIDConfigurationListApi = (data) =>
    API.get(`${URL.MEMBERSHIP_ID_CONFIGURATION_LIST}`, data)
export const MemberShipIDConfigurationCreateApi = (data) =>
    API.post(`${URL.MEMBERSHIP_ID_CONFIGURATION_CREATE}`, data)
export const MemberShipIDConfigurationUpdateApi = (ID, data) =>
    API.put(`${URL.MEMBERSHIP_ID_CONFIGURATION_UPDATE}/${ID}`, data)
