import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    SweepstakeListApi,
    SweepstakePlacingListApi,
    createSweepStakeApi,
    getOneSweepStakeApi,
    deleteSweepStakeApi,
    updateSweepStakeApi
} from './sweepstake.services'
import { paginationData } from '../Pagination/reducers'
import _ from 'lodash'

//
export const SweepstakeList = createAsyncThunk(
    '@Sweepstake/List',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await SweepstakeListApi(data)

            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }
            return res
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//

export const SweepStakePlacingList = createAsyncThunk(
    '@Sweepstake/placing/List',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SweepstakePlacingListApi(data.type, data.params)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const createSweepStake = createAsyncThunk(
    '@Sweepstake/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await createSweepStakeApi(data)

            return res.data
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const updateSweepStake = createAsyncThunk(
    '@Sweepstake/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await updateSweepStakeApi(data.ID, _.omit(data, 'ID'))

            return res.data
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const getOneSweepStake = createAsyncThunk(
    '@Sweepstake/getOne',
    async (data, { rejectWithValue }) => {
        try {
            const res = await getOneSweepStakeApi(data)

            return res?.data || null
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const deleteSweepStake = createAsyncThunk(
    '@Sweepstake/delete',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await deleteSweepStakeApi(ID)
            return res.data
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
