import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    ClubSubUserListApi,
    ClubSubUserByIdApi,
    ClubSubUserCreateApi,
    ClubSubUserDeleteApi,
    ClubSubUserUpdateApi,
    ClubSubUserListForMemberCreateApi
} from './ClubSubUser.services'
import { paginationData } from '../Pagination/reducers'

//services
export const ClubSubUserList = createAsyncThunk(
    '/ClubSubUser/MembershipList',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await ClubSubUserListApi(data)

            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const ClubSubUserCreate = createAsyncThunk(
    '/ClubSubUser/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ClubSubUserCreateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const ClubSubUserUpdate = createAsyncThunk(
    '/ClubSubUser/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ClubSubUserUpdateApi(data.ID, data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const ClubSubUserById = createAsyncThunk(
    '/ClubSubUser/one',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await ClubSubUserByIdApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const ClubSubUserDelete = createAsyncThunk(
    '/ClubSubUser/delete',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await ClubSubUserDeleteApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const ClubSubUserListForMemberCreate = createAsyncThunk(
    '/ClubSubUser/MembershipList/For/Member/Create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ClubSubUserListForMemberCreateApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
