import { createSlice } from '@reduxjs/toolkit'
import {
    SanctionPlanById,
    SanctionPlanCreate,
    SanctionPlanList,
    SanctionPlanListForOption
} from './thunk'
import _ from 'lodash'
import { toast } from 'react-toastify'

export const SanctionPlanForm = {
    sanction_plan_id: '',
    title: '',
    open_price: null,
    youth_price: null,
    price: null,
    is_open_purchased_youth: false,
    if_open_amount_type: 'zero',
    open_purchased_youth: '',
    is_youth_purchased_open: false,
    if_youth_amount_type: 'zero',
    youth_purchased_open: '',
    report_deadline: null,
    publish: true
}

export const initialState = {
    list: [],
    loading: false,
    form: SanctionPlanForm
}
const SanctionPlans = createSlice({
    name: '@Sanction/SanctionPlans',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(SanctionPlanList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(SanctionPlanList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.form = SanctionPlanForm
            state.list = data
            state.loading = false
        })
        builder.addCase(SanctionPlanList.rejected, (state, action) => {
            const { data } = action.payload
            if (data) {
                toast.error(data)
            }
            state.loading = false
        })

        // option
        builder.addCase(SanctionPlanListForOption.pending, (state) => {
            state.loading = true
        })
        builder.addCase(SanctionPlanListForOption.fulfilled, (state, action) => {
            const { data } = action.payload

            state.form = SanctionPlanForm
            state.list =
                _.map(
                    _.filter(data, (q) => q.publish === 'Y'),
                    (p) => ({
                        ...p,
                        label: p.title,
                        value: p.sanction_plan_id
                    })
                ) || []
            state.loading = false
        })
        builder.addCase(SanctionPlanListForOption.rejected, (state, action) => {
            const { data } = action.payload
            if (data) {
                toast.error(data)
            }
            state.loading = false
        })
        builder.addCase(SanctionPlanCreate.pending, (state) => {
            state.loading = false
        })
        builder.addCase(SanctionPlanCreate.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(SanctionPlanCreate.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(SanctionPlanById.pending, (state) => {
            state.loading = true
        })
        builder.addCase(SanctionPlanById.fulfilled, (state, action) => {
            const { payload } = action

            const finalData = {
                sanction_plan_id: payload?.data?.sanction_plan_id,
                title: payload?.data?.title,
                open_price: Number(payload?.data?.open_price || null) || null,
                youth_price: Number(payload?.data?.youth_price || null) || null,
                is_open_purchased_youth: payload?.data?.is_open_purchased_youth ? true : false,
                if_open_amount_type: payload?.data?.is_open_purchased_youth
                    ? _.isNaN(Number(payload.data.open_purchased_youth))
                        ? payload?.data?.open_purchased_youth
                        : 'amount'
                    : 'zero',
                open_purchased_youth: payload?.data?.open_purchased_youth,
                is_youth_purchased_open: payload?.data?.is_youth_purchased_open ? true : false,
                if_youth_amount_type: payload?.data?.is_youth_purchased_open
                    ? _.isNaN(Number(payload.data.youth_purchased_open))
                        ? payload?.data?.youth_purchased_open
                        : 'amount'
                    : 'zero',
                youth_purchased_open: payload?.data?.youth_purchased_open,
                publish: payload?.data?.publish === 'Y' ? true : false,
                report_deadline: payload?.data?.report_deadline || null
            }
            state.form = finalData
            state.loading = false
        })
        builder.addCase(SanctionPlanById.rejected, (state) => {
            state.loading = false
        })
    }
})

export default SanctionPlans.reducer
