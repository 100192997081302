import { createAsyncThunk } from '@reduxjs/toolkit'
import { AdminSanctionProcessFeesListApi } from './AdminSanctionProcessingFees.services'

export const AdminSanctionProcessFeeList = createAsyncThunk(
    '/Sanction/admin/process/fees/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await AdminSanctionProcessFeesListApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
