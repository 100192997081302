import { createAsyncThunk } from '@reduxjs/toolkit'
import * as apiService from './contest.services'
import _ from 'lodash'
import { paginationData } from '../Pagination/reducers'

export const contestDetailForNational = createAsyncThunk(
    '@setting/club/contest/breed/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.contestDetailForNationalApi(data)

            const list = _.map(_.orderBy(res || [], ['breed_name'], ['asc']), (d) => {
                // return { ...d }
                return { ...d, isSelected: false }
            })

            const category = _.unionBy(list, 'breed_category_id')

            const breedList = _.map(category, (b) => {
                const cat = _.find(category, (f) => f.breed_category_id === b.breed_category_id)
                return {
                    breed_category_abbre: cat.breed_category_abbre,
                    breed_category_id: cat.breed_category_id,
                    category_name: cat.category_name,
                    // isSelected: true,
                    breed: _.filter(
                        list,
                        (f) => f.breed_category_id === b.breed_category_id && { ...f }
                    )
                }
            })

            return breedList
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const saveContest = createAsyncThunk(
    '@setting/clubs/contest/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.saveContestApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const getAllContest = createAsyncThunk(
    '@setting/clubs/contest/all',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await apiService.getAllContestApi(data)
            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const deleteContest = createAsyncThunk(
    '@setting/clubs/contest/delete',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await apiService.deleteContestApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const ContestBreedList = createAsyncThunk(
    '@setting/club/contest/breed/list/view',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.contestDetailForNationalApi()
            let list = []
            if (Array.isArray(res) && Array.isArray(data?.breed_list)) {
                list = _.map(
                    _.orderBy(
                        _.filter(
                            _.filter(
                                res,
                                (f) => f.breed_category_id !== 4 && (f.group_seq || f.variety_seq)
                            ),
                            (f) =>
                                _.find(
                                    data?.breed_list,
                                    (_f) => Number(_f.breed_id) === Number(f.breed_id)
                                )
                        ),
                        ['breed_name'],
                        ['asc']
                    ),
                    (d, index) => {
                        return {
                            ...d,
                            isActive: index === 0 ? true : false
                        }
                    }
                )
            }

            return list
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const ContestBreedListContestDetail = createAsyncThunk(
    '@setting/club/contest/breed/list/view/contestDetail',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.contestDetailForNationalApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const ContestBreedDetail = createAsyncThunk(
    '@contest/BreedDetail',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.ContestBreedDetailApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const ContestVarietyReportPdf = createAsyncThunk(
    '@contest/VarietyReport/pdf',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.ContestBreedDetailApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const GetOverallReportList = createAsyncThunk(
    '@setting/clubs/contest/report/overall-contest-report',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.getOverallReportListApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const ContestPdfDownload = createAsyncThunk(
    '@setting/club/contest/download/pdf',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.getOverallReportListApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

// export const SweepStakePlacingListForContest = createAsyncThunk(
//     '@setting/clubs/contest/sweepstake/placing/List',
//     async (data, { rejectWithValue }) => {
//         try {
//             const res = await apiService.SweepstakePlacingListApi(data.type, data.params)

//             return res
//         } catch (err) {
//             if (!err.response) {
//                 throw err
//             }
//             return rejectWithValue(err.response.data)
//         }
//     }
// )

export const SweepStakePlacingListForContest = createAsyncThunk(
    '@setting/clubs/contest/sweepstake/placing/List',
    async (data, { rejectWithValue }) => {
        try {
            const finalData = {}
            const result = await apiService.SweepstakePlacingListApi(data)
            if (Array.isArray(result?.data)) {
                finalData.reportType = data.reportType
                finalData.best_fur = _.map(
                    _.filter(result.data, (q) => q.step.split(',').includes('best_fur')),
                    (q) => q.placing
                )
                finalData.group = _.map(
                    _.filter(result.data, (q) => q.step.split(',').includes('group')),
                    (q) => q.placing
                )
                finalData.variety = _.map(
                    _.filter(result.data, (q) => q.step.split(',').includes('variety')),
                    (q) => q.placing
                )
                finalData.breed = _.map(
                    _.filter(result.data, (q) => q.step.split(',').includes('breed')),
                    (q) => q.placing
                )
                finalData.other = _.map(
                    data.reportType === 'exhibitor_list_by_point'
                        ? _.filter(
                              result.data,
                              (q) =>
                                  q.step.split(',').includes('best_class_type') ||
                                  q.step.split(',').includes('show') ||
                                  (q.step.split(',').includes('best_group') &&
                                      !q.placing.includes('BGIS')) ||
                                  q.step.split(',').includes('show_cavy')
                              // q.step.split(',').includes('show_cavy_runner_up') //||
                              // q.step.split(',').includes('best_group')
                          )
                        : _.filter(
                              result.data,
                              (q) =>
                                  q.step.split(',').includes('best_class_type') ||
                                  q.step.split(',').includes('show') ||
                                  q.step.split(',').includes('best_group') ||
                                  q.step.split(',').includes('show_cavy') ||
                                  q.step.split(',').includes('show_cavy_runner_up') //||
                              // q.step.split(',').includes('best_group')
                          ),
                    (q) => ({ ...q, isSelected: false })
                )
            }

            return finalData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const getOneContest = createAsyncThunk(
    '@setting/clubs/contest/findsingle/contest',
    async (data, { rejectWithValue }) => {
        try {
            const res = await apiService.getOneContestApi(data?.contest_id)

            return res || null
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
