import { createSlice } from '@reduxjs/toolkit'
import { MembershipCardTemplateList } from './thunk'
import _ from 'lodash'

export const from = {
    membership_card_template_id: '',
    club_id: '',
    info_1: '',
    logo_file: '',
    logoFile: ''
}

export const initialState = {
    loading: true,
    form: from
}
const MemberShipCardTemplate = createSlice({
    name: 'Membership/card/template',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(MembershipCardTemplateList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(MembershipCardTemplateList.fulfilled, (state, action) => {
            const { data } = action.payload
            let formData = _.first(data)
            if (formData) {
                formData = {
                    membership_card_template_id: formData?.membership_card_template_id || '',
                    club_id: formData?.club_id || '',
                    info_1: formData?.info_1 || '',
                    logo_file: formData?.document_file || '',
                    logoFile: ''
                    // ...formData
                }
            }


            state.form = formData ? formData : from
            state.loading = false
        })
        builder.addCase(MembershipCardTemplateList.rejected, (state) => {
            state.loading = false
        })
    }
})

// export const {} = SetupFee.actions
export default MemberShipCardTemplate.reducer
