import { createSlice } from '@reduxjs/toolkit'
import { SweepStakeMemberForFormList, SweepStakeMemberList } from './thunk'
import _ from 'lodash'

export const Form = {
    show_id: '',
    user_list: []
}

export const initialState = {
    form: Form,
    list: [],
    loading: false,
    formMemberLoading: true
}
const SweepstakeMember = createSlice({
    name: '@Sweepstake/Member',
    initialState,
    reducers: {
        setMemberLoading: (state) => {
            state.formMemberLoading = true
        }
    },
    extraReducers: (builder) => {
        builder.addCase(SweepStakeMemberForFormList.pending, (state) => {
            state.formMemberLoading = true
        })
        builder.addCase(SweepStakeMemberForFormList.fulfilled, (state, action) => {
            const data = action.payload

            state.form.user_list = data || []
            state.formMemberLoading = false
        })
        builder.addCase(SweepStakeMemberForFormList.rejected, (state) => {
            state.formMemberLoading = false
        })
        builder.addCase(SweepStakeMemberList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(SweepStakeMemberList.fulfilled, (state, action) => {
            const data = _.map(action.payload, (q) => {
                return {
                    ...q,
                    label: `${q?.exh_name || ''} (${q?.user_email || 'No Email'})`,
                    value: q.user_id,

                    highlight: q.user_role === 'alias' ? true : false
                }
            })

            state.list = data
            state.formMemberLoading = false
        })
        builder.addCase(SweepStakeMemberList.rejected, (state) => {
            state.formMemberLoading = false
        })
    }
})
export const { setMemberLoading } = SweepstakeMember.actions

export default SweepstakeMember.reducer
