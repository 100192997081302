import { createSlice } from '@reduxjs/toolkit'
import { PlanPaymentHistoryMember, ClubGrandTotal } from './thunk'
import _ from 'lodash'

export const initialState = {
    MemberPlanHistory: [],
    grandTotal: 0,
    loading: true
}
const SetupFee = createSlice({
    name: 'ClubMemberHistory',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        // Listing
        builder.addCase(PlanPaymentHistoryMember.pending, (state) => {
            state.loading = true
        })
        builder.addCase(PlanPaymentHistoryMember.fulfilled, (state, action) => {
            const { data } = action.payload
            state.MemberPlanHistory = data
            state.loading = false
        })
        builder.addCase(PlanPaymentHistoryMember.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(ClubGrandTotal.pending, (state) => {
            state.loading = false
        })
        builder.addCase(ClubGrandTotal.fulfilled, (state, action) => {
            const { data } = action.payload
            state.grandTotal = _.first(data)
            state.loading = false
        })
        builder.addCase(ClubGrandTotal.rejected, (state) => {
            state.loading = false
        })
    }
})

// export const {} = SetupFee.actions
export default SetupFee.reducer
