import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    SanctionCardTemplateCreateApi,
    SanctionCardTemplateDeleteApi,
    SanctionCardTemplateListApi,
    SanctionCardTemplateUpdateApi
} from './SanctionCardTemplate.services'

export const SanctionCardTemplateCreate = createAsyncThunk(
    '/Sanction/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionCardTemplateCreateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const SanctionCardTemplateUpdate = createAsyncThunk(
    '/Sanction/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionCardTemplateUpdateApi(
                data.sanction_card_template_id,
                data.formData
            )
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const SanctionCardTemplateList = createAsyncThunk(
    '/Sanction/card/template/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionCardTemplateListApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const SanctionCardTemplateDelete = createAsyncThunk(
    '/Sanction/card/template/delete',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionCardTemplateDeleteApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
