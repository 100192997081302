import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const MemberShipPlanListApi = (data) => API.get(`${URL.MEMBERSHIP_PLANS_LIST}`, data)
export const MembershipPlanCreateApi = (data) => API.post(`${URL.MEMBERSHIP_PLANS_CREATE}`, data)
export const MembershipPlansByIdApi = (Id) => API.get(`${URL.MEMBERSHIP_PLANS_ONE}/${Id}`)
export const MembershipPlanUpdateApi = (Id, data) =>
    API.put(`${URL.MEMBERSHIP_PLANS_UPDATE}/${Id}`, data)
export const MembershipPlanDeleteApi = (Id) => API.del(`${URL.MEMBERSHIP_PLANS_DELETE}/${Id}`)
export const MembershipPlanStatusApi = (Id, data) =>
    API.put(`${URL.MEMBERSHIP_PLANS_PUBLISH_STATUS}/${Id}`, data)

//MEMBERSHIP_PLANS_LIST_FOR_MEMBER_CREATE
export const MemberShipPlanListForMemberCreateApi = (data) =>
    API.get(`${URL.MEMBERSHIP_PLANS_LIST_FOR_MEMBER_CREATE}`, data)
