import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

export const createSweepStakeMemberApi = (data) =>
    API.post(`${URL.CREATE_SWEEPSTAKE_MEMBER_URL}`, data)
export const updateSweepStakeMemberApi = (ID, data) =>
    API.put(`${URL.UPDATE_SWEEPSTAKE_MEMBER_URL}/${ID}`, data)
export const getOneSweepStakeMemberApi = (ID) =>
    API.get(`${URL.GET_SWEEPSTAKE_MEMBER_SINGLE_URL}/${ID}`)
export const SweepStakeMemberForFormListApi = (data) =>
    API.get(`${URL.GET_SWEEPSTAKE_MEMBER_LIST_FOR_FORM}`, data)

export const SweepStakeMemberListApi = (ID, data) =>
    API.get(`${URL.GET_SWEEPSTAKE_MEMBER_LIST}/${ID}`, data)
