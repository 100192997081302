import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    SanctionLetterTemplateCreateApi,
    SanctionLetterTemplateDeleteApi,
    SanctionLetterTemplateListApi,
    SanctionLetterTemplateUpdateApi
} from './SanctionLetterTemplate.services'

export const SanctionLetterTemplateList = createAsyncThunk(
    'lettertemplate/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionLetterTemplateListApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SanctionLetterTemplateCreate = createAsyncThunk(
    'lettertemplate/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionLetterTemplateCreateApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SanctionLetterTemplateUpdate = createAsyncThunk(
    'lettertemplate/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionLetterTemplateUpdateApi(
                data?.sanction_letter_template_id,
                data.formData
            )

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SanctionLetterTemplateDelete = createAsyncThunk(
    '/lettertemplate/delete',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionLetterTemplateDeleteApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
