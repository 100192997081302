import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const ClubUserListApi = (data) => API.get(`${URL.CLUB_MEMBERS_LIST}`, data)

export const ClubUserByIdApi = (ID) => API.get(`${URL.CLUB_MEMBERS_ONE}/${ID}`)

export const ClubUserCreateApi = (data) => API.post(`${URL.CLUB_MEMBERS_CREATE}`, data)
export const AliasUserCreateApi = (data) => API.post(`${URL.CLUB_ALIAS_MEMBERS_CREATE}`, data)

export const ClubUserUpdateApi = (ID, data) => API.patch(`${URL.CLUB_MEMBERS_UPDATE}/${ID}`, data)

export const ClubUserDeleteApi = (ID) => API.del(`${URL.CLUB_MEMBERS_DELETE}/${ID}`)

// CLUB_MEMBERS_LIST_FOR_MEMBER_CREATE
export const ClubUserListForMemberCreateApi = (data) =>
    API.get(`${URL.CLUB_MEMBERS_LIST_FOR_MEMBER_CREATE}`, data)
