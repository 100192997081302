import { createSlice } from '@reduxjs/toolkit'
import { NewsLetterGetOne, NewsLetterList } from './thunk'
import _ from 'lodash'

export const Form = {
    doc_id: '',
    doc_name: '',
    file: '',
    doc_file: '',
    file1: '',
    display_order: '',
    published: true
}

export const initialState = {
    list: [],
    loading: true,
    form: Form
}
const NewsLetter = createSlice({
    name: 'Membership/NewsLetter',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(NewsLetterList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(NewsLetterList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.list = data
            // state.form = formData ? formData : from
            state.form = Form
            state.loading = false
        })
        builder.addCase(NewsLetterList.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(NewsLetterGetOne.fulfilled, (state, action) => {
            const { data } = action.payload
            const formData = _.pick(_.first(data), Object.keys(Form))
            const finalData = {
                ...formData,
                file1: '',
                file: '',
                published: formData.published ? true : false
            }
            state.form = finalData
            state.loading = false
        })
    }
})

// export const {} = SetupFee.actions
export default NewsLetter.reducer
