import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    SanctionProcessFeesCreateApi,
    SanctionProcessFeesListApi,
    SanctionProcessFeesUpdateApi
} from './SanctionProcessingFees.services'

export const SanctionProcessFeeCreate = createAsyncThunk(
    '/Sanction/process/fees/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionProcessFeesCreateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const SanctionProcessFeeUpdate = createAsyncThunk(
    '/Sanction/process/fees/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionProcessFeesUpdateApi(data.sanction_percentage_id, data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const SanctionProcessFeeList = createAsyncThunk(
    '/Sanction/process/fees/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionProcessFeesListApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
