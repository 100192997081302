import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    SweepStakeShowSetupListApi,
    createSweepStakeShowSetupApi,
    getOneSweepStakeShowSetupApi,
    updateSweepStakeShowSetupApi,
    createSweepStakeJudgeApi,
    getOneSweepStakeJudgeApi,
    updateSweepStakeJudgeApi,
    SweepStakeJudgeListApi,
    deleteSweepStakeShowSetupApi,
    deleteSweepStakeJudgeApi,
    SweepstakeMemberAlreadyAddedGetApi,
    deleteSweepStakeExhibitorApi
} from './SweepstakeShowSetup.services'
import _ from 'lodash'
import { paginationData } from '../Pagination/reducers'
//
export const createSweepStakeShowSetup = createAsyncThunk(
    '@Sweepstake/ShowSetup/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await createSweepStakeShowSetupApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const updateSweepStakeShowSetup = createAsyncThunk(
    '@Sweepstake/ShowSetup/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await updateSweepStakeShowSetupApi(data.ID, _.omit(data, 'ID'))

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const deleteSweepStakeShowSetup = createAsyncThunk(
    'AgeClassification/delete',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await deleteSweepStakeShowSetupApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const getOneSweepStakeShowSetup = createAsyncThunk(
    '@Sweepstake/ShowSetup/getOne',
    async (data, { rejectWithValue }) => {
        try {
            const res = await getOneSweepStakeShowSetupApi(data)

            return res || null
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const SweepStakeShowSetupList = createAsyncThunk(
    '@Sweepstake/ShowSetup/list',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await SweepStakeShowSetupListApi(data)
            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const createSweepStakeJudge = createAsyncThunk(
    '@Sweepstake/Judge/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await createSweepStakeJudgeApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const updateSweepStakeJudge = createAsyncThunk(
    '@Sweepstake/Judge/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await updateSweepStakeJudgeApi(data.ID, _.omit(data, 'ID'))

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const deleteSweepStakeJudge = createAsyncThunk(
    'AgeClassification/delete',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await deleteSweepStakeJudgeApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const getOneSweepStakeJudge = createAsyncThunk(
    '@Sweepstake/Judge/getOne',
    async (data, { rejectWithValue }) => {
        try {
            const res = await getOneSweepStakeJudgeApi(data)

            return res || null
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const SweepStakeJudgeList = createAsyncThunk(
    '@Sweepstake/Judge/list',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await SweepStakeJudgeListApi(data.ID, data)

            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SweepstakeMemberAlreadyAddedGet = createAsyncThunk(
    '@Sweepstake/Member/already/get',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SweepstakeMemberAlreadyAddedGetApi(data)

            return res.data || null
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const deleteSweepStakeExhibitor = createAsyncThunk(
    'Sweepstake/Exhibitor/delete',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await deleteSweepStakeExhibitorApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)


