import { createSlice } from '@reduxjs/toolkit'

import { MembershipPlansByIdForMember, MemberShipLists } from './thunk'

// export const memberListForm = {
//     member_id: '',
//     arba_number: '',
//     is_minor: false,
//     date_of_birth: ''
// }

export const membershipForm = {
    member_id: '',
    club_id: '',
    membership_id: '',
    membership_plan_id: '',
    member_list: [],
    amount: ''
}

export const initialState = {
    loading: true,
    memberListLoading: false,
    form: membershipForm,
    list: []
}
const MemberShipList = createSlice({
    name: 'Membership/List',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(MembershipPlansByIdForMember.pending, (state) => {
            state.memberListLoading = true
        })
        builder.addCase(MembershipPlansByIdForMember.fulfilled, (state) => {
            state.memberListLoading = false
        })
        builder.addCase(MembershipPlansByIdForMember.rejected, (state) => {
            state.memberListLoading = false
        })
        builder.addCase(MemberShipLists.pending, (state) => {
            state.loading = true
        })
        builder.addCase(MemberShipLists.fulfilled, (state, action) => {
            const { payload } = action
            state.list = payload || []
            state.loading = false
        })
        builder.addCase(MemberShipLists.rejected, (state) => {
            state.loading = false
        })
    }
})

// export const {} = SetupFee.actions
export default MemberShipList.reducer
