import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    MembershipCardTemplateCreateApi,
    MembershipCardTemplateDeleteApi,
    MembershipCardTemplateListApi,
    MembershipCardTemplateUpdateApi
} from './MemberShipCardTemplate.services'

export const MembershipCardTemplateCreate = createAsyncThunk(
    '/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipCardTemplateCreateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MembershipCardTemplateUpdate = createAsyncThunk(
    '/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipCardTemplateUpdateApi(
                data.membership_card_template_id,
                data.formData
            )
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MembershipCardTemplateList = createAsyncThunk(
    '/membership/card/template/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipCardTemplateListApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MembershipCardTemplateDelete = createAsyncThunk(
    '/membership/card/template/delete',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipCardTemplateDeleteApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
