import { createSlice } from '@reduxjs/toolkit'
import {
    MembershipAllStatus,
    MembershipProcessFeesListAdmin,
    MembershipPurchasePaymentIntent
} from './thunk'
import _ from 'lodash'

export const paymentForm = {
    payment_type: 'cash'
}
export const initialState = {
    cartData: {
        club_id: '',
        user_id: '',
        club_membership_plans_id: '',
        total_number_of_memberbship: '',
        total_amt: 0,
        membership_data: [],
        payment_type: '',
        hasPayment: false
    },
    form: paymentForm,
    processionFees: [],
    paymentIntent: {},
    MembershipStatus: {},
    loading: false
}
const MemberShipShoppingCart = createSlice({
    name: 'membershipShoppingCart',
    initialState,
    reducers: {
        addToShoppingCart: (state, action) => {
            const { payload } = action
            state.cartData = payload
        }
    },

    // MemberShipProcessFeeList
    extraReducers: (builder) => {
        // Listing
        builder.addCase(MembershipProcessFeesListAdmin.pending, (state) => {
            state.loading = true
        })
        builder.addCase(MembershipProcessFeesListAdmin.fulfilled, (state, action) => {
            const { data } = action.payload

            state.processionFees = data || []
            state.loading = false
        })
        builder.addCase(MembershipProcessFeesListAdmin.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(MembershipPurchasePaymentIntent.pending, (state) => {
            state.loading = true
        })
        builder.addCase(MembershipPurchasePaymentIntent.fulfilled, (state, action) => {
            state.paymentIntent = action?.payload ? action?.payload : {}
            state.loading = false
        })
        builder.addCase(MembershipPurchasePaymentIntent.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(MembershipAllStatus.pending, (state) => {
            state.loading = false
        })
        builder.addCase(MembershipAllStatus.fulfilled, (state, action) => {
            const { data } = action.payload
            state.MembershipStatus = _.size(data) ? data : {}
            state.loading = false
        })
        builder.addCase(MembershipAllStatus.rejected, (state) => {
            state.loading = false
        })
    }
})

export const { addToShoppingCart } = MemberShipShoppingCart.actions

export default MemberShipShoppingCart.reducer
