import { createSlice } from '@reduxjs/toolkit'
import { SanctionGrandTotal, SanctionPurchaseList } from './thunk'
import _ from 'lodash'

export const initialState = {
    list: [],
    grandTotal: 0,
    loading: true
}
const ClubUser = createSlice({
    name: 'clubUser',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(SanctionPurchaseList.pending, (state) => {
            state.list = []
            state.loading = true
        })
        builder.addCase(SanctionPurchaseList.fulfilled, (state, action) => {
            state.list = action?.payload || []
            state.loading = false
        })
        builder.addCase(SanctionPurchaseList.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(SanctionGrandTotal.pending, (state) => {
            state.grandTotal = '0.00'
        })
        builder.addCase(SanctionGrandTotal.fulfilled, (state, action) => {
            const { data } = action.payload
            state.grandTotal = _.get(_.first(data), 'grand_total')
        })
        builder.addCase(SanctionGrandTotal.rejected, (state) => {
            state.grandTotal = '0.00'
        })
    }
})

// export const {} = SetupFee.actions
export default ClubUser.reducer
