import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    MembershipAllStatusApi,
    MembershipProcessFeesListAdminApi,
    MembershipPurchasePaymentIntentApi,
    SuccessMembershipPaymentApi
} from './MemberShipShoppingCart.services'

export const MembershipProcessFeesListAdmin = createAsyncThunk(
    '/process/fees/admin/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipProcessFeesListAdminApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MembershipPurchasePaymentIntent = createAsyncThunk(
    '/membership/purchase/payment/intent',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipPurchasePaymentIntentApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SuccessMembershipPayment = createAsyncThunk(
    '/membership/Payment/Success',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SuccessMembershipPaymentApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MembershipAllStatus = createAsyncThunk(
    '/membership/status',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipAllStatusApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
